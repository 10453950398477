import LocalizedStrings from 'react-localization';
// import { ServerAPIError } from '../utils/request';
// import { parseDomain } from '../utils/app';

export const errorLang = new LocalizedStrings({
	'zh-hk': {
		'general': {
			'ERROR_WITHOUT_STATUS': '發生錯誤，請稍後再嘗試',
			'ERROR_WITH_STATUS': '發生錯誤，請稍後再嘗試 ({0})',
		},
		'catch': {
			'Network Error': "Network Error" // will have this error when calling ajax without network
		},
		'public': {
			"empty_required_input": "所有欄位必須填寫",
			"classno_too_long": "班號只可填寫兩位數",
			"empty_login_input": "Please enter username and password",
		},
		'common': {
			"error_load_video": "無法載入影片，請重新載入頁面再試。",
			"file_size_too_large": "檔案太大，請上傳不大於 {0} MB 的檔案",
			"file_type_invalid": "檔案格式錯誤，請選擇 pdf, doc 或 docx 檔案格式", // temp - hardcode file types
			"file_exceed_limit": "請上傳不多於 {0} 個檔案",
		},
		'orgadmin': {
			"name_not_empty": "必須填寫名稱",
		},
		'teacher': {
			"class_not_empty": "必須至少一個班別",
			"title_not_empty": "必須填寫標題",
			"upload_file_fail": "上載影片發生錯誤，請重新嘗試。",
			"upload_attachment_fail": "上載附件發生錯誤，請重新嘗試。",
			"invalid_link": "連結不正確，請重新輸入。",

		},
		'student': {
			"upload_homework_fail": "上載檔案發生錯誤，請重新嘗試。",
		},
		'response': {
			'ACCESS_DENY': '你沒有權限',
			'MISSING_INPUT': '提供的資料不正確',
			'NOT_FOUND': '找不到相關內容',
			'INCORRECT_LOGIN': '登入資料不正確，請重新輸入',
			'DISABLED_LOGIN': '帳戶已停用',
		},
	},
});

export const parseErrorFromCatch = (error) => {
	return parseErrorStatus(error.message)
}

export const parseErrorFromAPI = (error) => {
	return parseErrorStatus(error.status)
}

const parseErrorStatus = (status) => {
	console.log("parseErrorStatus: ", status)
	if (status === null || status === undefined) {
		return errorLang.general.ERROR_WITHOUT_STATUS;
	}
	switch (status) {
		case 'ACCESS_DENY':
			return errorLang.response.ACCESS_DENY;
		case 'MISSING_INPUT':
			return errorLang.response.MISSING_INPUT;
		case 'NOT_FOUND':
			return errorLang.response.NOT_FOUND;
		case 'INCORRECT_LOGIN':
			return errorLang.response.INCORRECT_LOGIN;
		case 'DISABLED_LOGIN':
			return errorLang.response.DISABLED_LOGIN;
		default:
			return errorLang.formatString(errorLang.general.ERROR_WITH_STATUS, status)
	}

}

export default { errorLang, parseErrorFromCatch, parseErrorFromAPI };
