import React, { useEffect } from 'react';
import { Modal } from 'antd';
import { commonLang } from '../../languages/common.language';
import { getLocalApi } from '../../utils/api';
import moment from 'moment';
import { CHECK_SYSTEM_MESSAGE_INTERVAL } from '../../constants/app.constant';
import { getItemFromStorage, storeItemToStorage } from "../../actions/dom.action";

let intervalId = 0;

function SystemMessage(props) {
	const STORAGE_KEY = 'app.systemMessages';
	const [values, setValues] = React.useState({
		init: false,
		show: false,
		title: '',
		content: '',
	});
	const getJson = () => {
		if(!values.init) setValues({ ...values, init: true });
		return new Promise(async (resolve) => {
			try{
				const response = await getLocalApi().get(`/api/system.json?rnd=${Math.random()}`);
				const messages = response.data.messages;
				const storageStr = getItemFromStorage(STORAGE_KEY);
				const systemMessages = storageStr ? JSON.parse(storageStr) : [];
				const now = moment();
				for(var i=0; i<messages.length; i++){ // only show the first matched message
					const message = messages[i];
					const id = message.id;
					const title = message.title;
					const content = message.content;
					const startDate = message.start_date;
					const endDate = message.end_date;
					const isBetween = now.isBetween(startDate, endDate);
					const isRead = systemMessages[id] ? true : false;
					if(isBetween && !isRead && title && content){
						readSystemMessage(id);
						setValues({
							...values,
							show: true,
							id: id,
							title: title['zh-hk'],
							content: content['zh-hk'],
						});
						
						resolve(true);
						return;
					}
				}
			}catch(error) {}
			setTimer();
		});
	}

	const setTimer = () => {
		if(intervalId>0)  clearTimeout(intervalId)
		intervalId = setTimeout(getJson, CHECK_SYSTEM_MESSAGE_INTERVAL);
	}

	const readSystemMessage = (id) =>{
		const str1 = getItemFromStorage(STORAGE_KEY);
		const systemMessages = str1 ? JSON.parse(str1) : [''];
		systemMessages[id] = (new Date()).valueOf();
		const str2 = JSON.stringify(systemMessages);
		storeItemToStorage(STORAGE_KEY, str2);
	}

	const handleOk = () => {
		setValues({ ...values, show: false });
		setTimer();
	};

	useEffect(() => {
		if(!values.init) getJson();
	});

	return (
		<div>
			<Modal
					title={ values.title }
					visible={ values.show ? true : false }
					onOk={handleOk}
					okText={commonLang.okay}
					cancelButtonProps={{ style: { 'display': `none`} }}
					onCancel={handleOk}
					>
				<p dangerouslySetInnerHTML={{ __html: values.content }} />
			</Modal>
		</div>
	);
}
export default SystemMessage;
