import {
  ACTION_START_APP_LOADING,
  ACTION_END_APP_LOADING,
  ACTION_LIGHTBOX,
  ACTION_VISIT_LESSON,
  ACTION_READ_NOTIFICATION,
} from '../constants/dom.constant';

import { message } from 'antd'

import {
  UPLOAD_TYPE_MAIN_RESOURCE,
  UPLOAD_TYPE_ATTACHMENT,
  UPLOAD_TYPE_HOMEWORK,
  ATTACHMENT_FILE_ACCEPT_TYPES,
  ATTACHMENT_FILE_MAX_SIZE_IN_MB,
  MAIN_RESOURCE_FILE_ACCEPT_TYPES,
  MAIN_RESOURCE_FILE_MAX_SIZE_IN_MB,
  HOMEWORK_FILE_ACCEPT_TYPES,
  HOMEWORK_FILE_MAX_SIZE_IN_MB,
  SELECTED_FILE_VALID
} from '../constants/upload.constant';

import { checkFileType, checkFileSize } from './app.action';
import { errorLang, parseErrorFromCatch } from '../languages/error.language';
import { getDownloadApi } from '../utils/api';
import { saveAs } from 'file-saver';
import { commonLang } from '../languages/common.language';

export const startAppLoading = () => ({
  key: ACTION_START_APP_LOADING,
  payload: true,
});

export const endAppLoading = () => ({
  key: ACTION_END_APP_LOADING,
  payload: false,
});

export const controlLightbox = (isOpened, imageSources, selectedSource) => ({
  key: ACTION_LIGHTBOX,
  payload: {
    isOpened: isOpened,
    imageSources: imageSources,
    selectedSource: selectedSource,
  },
});

export var showMessage = (msg) => {
  message.error(msg);
};

export const visitLessonPage = (visited) => ({
  key: ACTION_VISIT_LESSON,
  payload: visited,
});

export const readNotification = (lastRead) => ({
  key: ACTION_READ_NOTIFICATION,
  payload: lastRead,
});

// <----- Remeber me
export function storeRememberMe(value) {
  localStorage.setItem('auth.remeberMe', value);
}

export function getRememberMeValue() {
  return localStorage.getItem('auth.remeberMe');
}

function getRememberMe() {
  return getRememberMeValue() === 'true';
}

export function storeItemToStorage(key, value) {
  if (getRememberMe()) {
    return localStorage.setItem(key, value);
  } else {
    return sessionStorage.setItem(key, value);
  }
}

export function getItemFromStorage(key) {
  if (getRememberMe()) {
    return localStorage.getItem(key);
  } else {
    return sessionStorage.getItem(key);
  }
}

export function removeItemFromStorage(key) {
  if (getRememberMe()) {
    localStorage.removeItem(key);
  } else {
    sessionStorage.removeItem(key);
  }
}

export function clearStorage() {
  const remeberMe = getRememberMeValue()
  localStorage.clear();
  sessionStorage.clear();
  storeRememberMe(remeberMe)
}

// <----- scroll to top
export function scrollToTop() {
  if (window.scrollY > 0) {
    window.scrollTo(0, 0)
  }
}

// <----- attachment
export function getUploadProps(type, fileList, beforeUpload, onRemove, isSupportMultiple = false) {
  let acceptType = ""
  switch (type) {
    case UPLOAD_TYPE_MAIN_RESOURCE:
      acceptType = MAIN_RESOURCE_FILE_ACCEPT_TYPES
      break;

    case UPLOAD_TYPE_ATTACHMENT:
      acceptType = ATTACHMENT_FILE_ACCEPT_TYPES
      break;

    case UPLOAD_TYPE_HOMEWORK:
      acceptType = HOMEWORK_FILE_ACCEPT_TYPES
      break;

    default:
      break;
  }

  return {
    multiple: isSupportMultiple,
    accept: acceptType,
    showUploadList: false,
    fileList: fileList,
    beforeUpload: beforeUpload,
    onRemove: onRemove,
  };
}

export function getUploadFileCheckingResult(type, fileToCheck) {
  let acceptType = ""
  let maxSize = 0

  switch (type) {
    case UPLOAD_TYPE_MAIN_RESOURCE:
      acceptType = MAIN_RESOURCE_FILE_ACCEPT_TYPES
      maxSize = MAIN_RESOURCE_FILE_MAX_SIZE_IN_MB
      break;

    case UPLOAD_TYPE_ATTACHMENT:
      acceptType = ATTACHMENT_FILE_ACCEPT_TYPES
      maxSize = ATTACHMENT_FILE_MAX_SIZE_IN_MB

      break;

    case UPLOAD_TYPE_HOMEWORK:
      acceptType = HOMEWORK_FILE_ACCEPT_TYPES
      maxSize = HOMEWORK_FILE_MAX_SIZE_IN_MB
      break;

    default: // if type not match, default no checking
      return { isReady: true, error: null }
  }

  if (!checkFileType(fileToCheck.type, acceptType)) {
    return { isReady: false, error: errorLang.common.file_type_invalid }
  }
  if (!checkFileSize(fileToCheck.size, maxSize)) {
    return { isReady: false, error: errorLang.formatString(errorLang.common.file_size_too_large, maxSize) }
  }
  return { isReady: true, error: null }

}
export function convertAttachmentRequestData(uuid, file, res) {
  return {
    "material_uuid": uuid,
    "metadata": {
      "file_id": res.dentry_id,
      "file_path": res.path,
      "file_path_global": res.path_global,
      "file_size": file.size,
      "mime_type": file.type,
      "duration": null,
      "title": file.name
    }
  }
}

export function convertHomeworkRequestData(uuid, file, res) {
  return convertAttachmentRequestData(uuid, file, res)
}

export function convertResponseToFileItemObjList(response) {
  const files = []
  for (var unit of response) {
    if (!unit.uuid) {
      continue
    }

    let file = unit
    file.name = unit.title
    file.uid = unit.uuid
    file.status = SELECTED_FILE_VALID
    files.push(file)
  }
  return files
}

// download file
export function downloadFileFromUrl(url, filename, mime) {
  getDownloadApi().get(url)
    .then(({ data }) => {
      const blob = new Blob([data], { type: mime });
      saveAs(blob, filename);
    })
    .catch((error) => {
      showMessage(parseErrorFromCatch(error))
    })
}

export function downloadFileFromLocal(file) {
  const blob = new Blob([file], { type: file.type });
  saveAs(blob, file.name);
}

export function filterObject(object, keyword, filters) {
  if (!JSON.stringify(object).toLowerCase().includes(keyword)) // <--- speed up search
    return false;

  for (let i in filters) {
    const filter = filters[i].split('.');
    let string = object[filter[0]];
    if (filter.length > 1) {
      string = string[filter[1]];
    }
    if (string && JSON.stringify(string).toLowerCase().includes(keyword))
      return true;
  }
  return false;
}

export function formatDuration(duration) {
  if (!duration) return null

  if (duration < 3600) { // 1 to 59 mins
    const minutes = Math.ceil(duration / 60);
    return commonLang.formatString(commonLang.minutes, minutes)

  } else {
    const hours = Math.floor(duration / 60 / 60);
    const minutes = Math.ceil(duration / 60) - (hours * 60);
    if (minutes < 1) {
      return commonLang.formatString(commonLang.hours, hours)
    } else {
      return commonLang.formatString(commonLang.hours, hours) + " " + commonLang.formatString(commonLang.minutes, minutes)
    }
  }
}

export function checkIsUrlValid(link) {
  if (link === "") return true
  const expression =
    /(?:https?):\/\/([-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*))/gi;

  const pattern = new RegExp(expression);

  return pattern.test(link);

}

export default {
  startAppLoading,
  endAppLoading,
  controlLightbox,
  showMessage,
  visitLessonPage,
  readNotification,
  storeRememberMe,
  storeItemToStorage,
  getRememberMeValue,
  getItemFromStorage,
  removeItemFromStorage,
  clearStorage,
  scrollToTop,
  convertAttachmentRequestData,
  convertHomeworkRequestData,
  getUploadProps,
  getUploadFileCheckingResult,
  filterObject,
  downloadFileFromUrl,
  downloadFileFromLocal,
  convertResponseToFileItemObjList,
  formatDuration,
  checkIsUrlValid,
};
