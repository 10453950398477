// <----- actions
export const ACTION_ALL_RESOURCES = 'action_teacher_resources';

export const ACTION_VIEW_RESOURCE = 'action_teacher_resource';
export const ACTION_UPDATE_RESOURCE = 'action_teacher_update_resource';
export const ACTION_CREATE_RESOURCE = 'action_teacher_create_resource';
export const ACTION_DELETE_RESOURCE = 'action_teacher_delete_resource';

export const ACTION_CREATE_RESOURCE_ATTACHMENT = 'action_teacher_create_resource_attachment';
export const ACTION_DELETE_RESOURCE_ATTACHMENT = 'action_teacher_delete_resource_attachment';
export const ACTION_TEACHER_GET_STUDENTS = 'action_teacher_get_students';

export const ACTION_GET_RESOURCE_HOMEWORK = 'action_teacher_get_resource_homework';

export const ACTION_GET_TOKEN_FOR_UPLOAD = 'action_teacher_get_token_for_upload';


// <------ state in page
export const STATUS_UPLOAD_INITED = "state_upload_inited";
export const STATUS_UPLOAD_SELECTED = "state_upload_selected";
export const STATUS_UPLOAD_CANCELLED = "state_upload_cancelled";
export const STATUS_UPLOAD_ATTACHMENT_ERROR = "state_upload_attachment_error";

export const STATUS_UPLOAD_FILE_TYPE_INVALID = "state_upload_file_type_invalid";
export const STATUS_UPLOAD_FILE_TOO_LARGE = "state_upload_error_file_too_large";
export const STATUS_UPLOAD_UPLOADING = "state_upload_uploading";
export const STATUS_UPLOAD_DONE = "state_upload_done";

export const DEFAULT_CLASS_TAG = "ALL"

// <------ Date format
export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD'
export const DEFAULT_DATE_SUBMIT_FORMAT = 'YYYY-MM-DD 00:00:00'
export const DEFAULT_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm'

// <------ share limitation
export const SHARE_DESCRIPTION_MAX_LENGTH = 70;
