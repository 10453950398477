import React, { useEffect } from 'react';

import { teacherLang } from '../../../languages/teacher.language';

function Report(props) {

	useEffect(() => {
		
	}, []);

	return (
		<div style={{width: `100%`}}>
			<div>{teacherLang.report}</div>
			
		</div>
	);
}
export default Report;
