import React from 'react';
import { Transfer } from 'antd';

import { teacherLang } from '../../../../languages/teacher.language';
import { commonLang } from '../../../../languages/common.language';
import { text, colors } from '../../../styles';
import Button from '../../../Common/Button';

function AssignMode(props) {
	const styles = {
		content: {
			backgroundColor: `#fff`,
			padding: `32px 48px`,
		},
		transfer: {
			marginBottom: `16px`,
		},
		list: {
			width: `calc(50% - 20px)`,
			height: `300px`,
			borderRadius: `6px`,
		},
		title: {
			...text.h6,
			color: colors.gray1,
			paddingBottom: 16,
		},
	}

	return (
		<div style={styles.content}>
			<div style={styles.title}>{teacherLang.assignee}</div>
			<Transfer
				showSearch
				dataSource={props.data ? props.data : []}
				targetKeys={props.students}
				titles={[teacherLang.not_assigned, teacherLang.assigned]}
				locale={{
					itemUnit: teacherLang.student_number, 
					itemsUnit: teacherLang.student_number, 
					notFoundContent: teacherLang.no_student, 
					searchPlaceholder: teacherLang.search_student
				}}
				render={item => item.name}
				style={styles.transfer}
				listStyle={styles.list}
				rowKey={record => record.uuid}
				onChange={(e) => props.changeValue('students', e)}
			/>
			<p>
				<Button type="primary" inlinestyle={{marginRight: 8}} onClick={props.handleSubmit}>{commonLang.save}</Button>
				<Button type="secondary" onClick={props.handleCancel}>{commonLang.cancel}</Button>
			</p>
		</div>
	);
}
export default AssignMode;

