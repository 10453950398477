
// **************************************************************** page views
// <----- Auth
export const S_LOGIN = 's_login_page';

// <----- Orgadmin
export const S_ORGADMIN_TEACHERS = 's_orgadmin_teachers_page';
export const S_ORGADMIN_ADD_TEACHER = 's_orgadmin_add_teacher_page';
export const S_ORGADMIN_EDIT_TEACHER = 's_orgadmin_edit_teacher_page';
export const S_ORGADMIN_STUDENTS = 's_orgadmin_students_page';
export const S_ORGADMIN_ADD_STUDENT = 's_orgadmin_add_student_page';
export const S_ORGADMIN_EDIT_STUDENT = 's_orgadmin_edit_student_page';

// <----- Teacher
export const S_TEACHER = 's_teacher_resources_page';
export const S_TEACHER_UPLOAD = 's_teacher_upload_page';
export const S_TEACHER_RESOURCE = 's_teacher_resource_page';

// <----- Student
export const S_STUDENT = 's_student_resources_page';
export const S_STUDENT_RESOURCE = 's_student_resource_page';


// **************************************************************** events

// <----- Auth
export const CLICK_LOGIN = 'click_login';
export const CLICK_LOGOUT = 'click_logout';
export const CLICK_LOGIN_BUTTON = 'click_loginpage_login';
export const CLICK_TNC = 'click_tnc';
export const CLICK_FAQ = 'click_faq';

export const CLICK_AUTH_EVENTS = [
	CLICK_LOGIN,
	CLICK_LOGOUT,
	CLICK_LOGIN_BUTTON,
	CLICK_TNC,
	CLICK_FAQ,
];

// <----- Upload
export const UPLOAD_RESOURCE_SUCCESS_S3 = 'upload_resource_success_s3';
export const UPLOAD_RESOURCE_SUCCESS_SPD = 'upload_resource_success_spd';
export const UPLOAD_RESOURCE_FAIL_S3 = 'upload_resource_fail_s3';
export const UPLOAD_RESOURCE_FAIL_SPD = 'upload_resource_fail_spd';
export const UPLOAD_ATTACHMENT_SUCCESS_S3 = 'upload_resource_success_s3';
export const UPLOAD_ATTACHMENT_SUCCESS_SPD = 'upload_resource_success_spd';
export const UPLOAD_ATTACHMENT_FAIL_S3 = 'upload_attachment_fail_s3';
export const UPLOAD_ATTACHMENT_FAIL_SPD = 'upload_attachment_fail_spd';
export const UPLOAD_HOMEWORK_SUCCESS_S3 = 'upload_resource_success_s3';
export const UPLOAD_HOMEWORK_SUCCESS_SPD = 'upload_resource_success_spd';
export const UPLOAD_HOMEWORK_FAIL_S3 = 'upload_homework_fail_s3';
export const UPLOAD_HOMEWORK_FAIL_SPD = 'upload_homework_fail_spd';
export const UPLOAD_EVENTS = [
	UPLOAD_RESOURCE_SUCCESS_S3,
	UPLOAD_RESOURCE_SUCCESS_SPD,
	UPLOAD_RESOURCE_FAIL_S3,
	UPLOAD_RESOURCE_FAIL_SPD,
	UPLOAD_ATTACHMENT_SUCCESS_S3,
	UPLOAD_ATTACHMENT_SUCCESS_SPD,
	UPLOAD_ATTACHMENT_FAIL_S3,
	UPLOAD_ATTACHMENT_FAIL_SPD,
	UPLOAD_HOMEWORK_SUCCESS_S3,
	UPLOAD_HOMEWORK_SUCCESS_SPD,
	UPLOAD_HOMEWORK_FAIL_S3,
	UPLOAD_HOMEWORK_FAIL_SPD
];

// <----- Resource Details
export const RESOURCE_LOAD_SUCCESS = 'load_resource_success';
export const RESOURCE_LOAD_FAIL = 'load_resource_fail';
export const CLICK_SHARE_RESOURCE = 'click_share_resource';
export const CLICK_PLAY_VIDEO = 'click_play_video';
export const CLICK_DOWNLOAD_ATTACHMENT = 'click_download_attachment';
export const CLICK_DOWNLOAD_HOMEWORK = 'click_download_homework';
export const RESOURCE_LOAD_EVENTS = [
	RESOURCE_LOAD_SUCCESS,
	RESOURCE_LOAD_FAIL,
	CLICK_SHARE_RESOURCE,
	CLICK_PLAY_VIDEO,
	CLICK_DOWNLOAD_ATTACHMENT,
	CLICK_DOWNLOAD_HOMEWORK
];

// <----- Home
export const CLICK_ROADMAP = 'click_roadmap';
export const CLICK_HOME_EVENTS = [
	CLICK_ROADMAP,
];

// <----- categories
export const CATEGORY_AUTH = 'auth';
export const CATEGORY_UPLOAD = 'upload';
export const CATEGORY_RESOURCE = 'resource';
export const CATEGORY_HOME = 'home';

export const CATEGORY_OTHERS = 'others';
export const CATEGORIES = [];
CATEGORIES[CATEGORY_AUTH] = CLICK_AUTH_EVENTS;
CATEGORIES[CATEGORY_UPLOAD] = UPLOAD_EVENTS;
CATEGORIES[CATEGORY_RESOURCE] = RESOURCE_LOAD_EVENTS;
CATEGORIES[CATEGORY_HOME] = CLICK_HOME_EVENTS;

CATEGORIES[CATEGORY_OTHERS] = [];
