import {
	ACTION_ALL_RESOURCES,
	ACTION_VIEW_RESOURCE,
	ACTION_UPDATE_RESOURCE,
	ACTION_CREATE_RESOURCE,
	ACTION_DELETE_RESOURCE,
	ACTION_GET_TOKEN_FOR_UPLOAD,
	ACTION_CREATE_RESOURCE_ATTACHMENT,
	ACTION_DELETE_RESOURCE_ATTACHMENT,
	ACTION_TEACHER_GET_STUDENTS,
	ACTION_GET_RESOURCE_HOMEWORK,
} from '../constants/teacher.constant';
import {
  ACTION_AUTH_LOGOUT,
 } from '../constants/auth.constant';
import { storeItemToStorage } from '../actions/dom.action';
import { getSubject } from '../actions/app.action';

function teacherReducer(state, action) {
	const payload = action.payload;
	switch (action.key) {
		case ACTION_ALL_RESOURCES:
			const { resources, resource, tags } = parseResources(payload.files, payload.selectedResource);
			const size = payload.size;
			const maxSize = payload.max_size;
			storeItemToStorage('teacher.resources', JSON.stringify(resources));
			storeItemToStorage('teacher.resource', JSON.stringify(resource));
			storeItemToStorage('teacher.tags', JSON.stringify(tags));
			storeItemToStorage('teacher.size', size);
			storeItemToStorage('teacher.maxSize', maxSize);
			return Object.assign({}, state, {
				...state,
				resource: resource,
				resources: resources,
				tags: tags,
				size: size,
				maxSize: maxSize,
			});
		case ACTION_VIEW_RESOURCE:
			storeItemToStorage('teacher.resource', JSON.stringify(payload));
			return Object.assign({}, state, {
				...state,
				resource: payload
			});
		case ACTION_CREATE_RESOURCE:
			return Object.assign({}, state, {
				...state,
				upload: payload.data !== null
			});
		case ACTION_CREATE_RESOURCE_ATTACHMENT:
			const afterCreate = addResourceAttachment(payload, state.resource);
			storeItemToStorage('teacher.resource', JSON.stringify(afterCreate));
			return Object.assign({}, state, {
				...state,
				resource: afterCreate
			});

		case ACTION_DELETE_RESOURCE_ATTACHMENT:
			const afterDelete = deleteResourceAttachment(payload, state.resource);
			storeItemToStorage('teacher.resource', JSON.stringify(afterDelete));
			return Object.assign({}, state, {
				...state,
				resource: afterDelete
			});

		case ACTION_UPDATE_RESOURCE:
			const updatedResource = updateResource(payload, state.resource, state.resources);
			storeItemToStorage('teacher.resource', JSON.stringify(updatedResource.resource));
			storeItemToStorage('teacher.resources', JSON.stringify(updatedResource.resources));
			return Object.assign({}, state, {
				...state,
				resource: updatedResource.resource,
				resources: updatedResource.resources,
			});
		case ACTION_DELETE_RESOURCE:
			const updatedResources = deleteResource(payload, state.resources);
			storeItemToStorage('teacher.resources', JSON.stringify(updatedResources));
			return Object.assign({}, state, {
				...state,
				resources: updatedResources,
			});
		case ACTION_GET_TOKEN_FOR_UPLOAD:
			return Object.assign({}, state, {
				...state,
				tokenObj: payload.data.info
			});
		case ACTION_TEACHER_GET_STUDENTS:
			return Object.assign({}, state, {
				...state,
				students: payload
			});
		case ACTION_GET_RESOURCE_HOMEWORK:
			const resourceWithHW = updateResourceWithHomework(payload, state.resource)
			storeItemToStorage('teacher.resource', JSON.stringify(resourceWithHW));
			return Object.assign({}, state, {
				...state,
				resource: resourceWithHW
			});
		case ACTION_AUTH_LOGOUT:
			// clear students list when logout
			return Object.assign({}, state, {
				...state,
				students: null,
			});
		default:
			return state;
	}
}

function parseResources(payload, selectedResource) {
	let tags = [];
	let resource = {};
	const resources = payload.map((unit, index) => {
		let subject = null
		if (unit.tags.subject !== undefined)
			if (unit.tags.subject[0] !== undefined)
				subject = getSubject(unit.tags.subject[0])

		const item = {
			...unit,
			type: unit.mime_type,
			subject: subject
		};

		tags = tags === [] ? unit.tags.class : tags.concat(unit.tags.class);
		if (selectedResource && selectedResource === unit.uuid) {
			resource = item;
		}

		return item;
	});
	return {
		resource: resource,
		resources: resources,
		tags: tags.filter((v, i, a) => a.indexOf(v) === i).sort(),
	};
}

function addResourceAttachment(payload, resource) {
	if (resource.attachments === null || resource.attachments === undefined) {
		resource.attachments = []
	}
	resource.attachments.push(payload)
	return resource
}

function deleteResourceAttachment(payload, resource) {
	const attachmentList = resource.attachments
	resource.attachments = attachmentList.filter((unit) => unit.uuid !== payload);
	return resource
}

function updateResource(payload, resource, resources) {
	resource.start_at = payload.start_at;
	resource.title = payload.title;
	resource.description = payload.description;
	resource.remarks = payload.remarks;
	resource.is_active = payload.is_active ? 1 : 0;
	resource.tags = payload.tags;
	resource.duration = payload.duration;
	for (let i in resources) {
		if (resources[i].uuid === payload.uuid) {
			resources[i].start_at = payload.start_at;
			resources[i].is_active = payload.is_active;
			break;
		}
	}

	return {
		resource: resource,
		resources: resources
	};
}

function deleteResource(payload, resources) {
	return resources.filter((unit) => unit.uuid !== payload);
}

function updateResourceWithHomework(payload, resource) {
	let homeworks = {}
	for (var item of payload) {
		if (!homeworks[item.created_by]) {
			homeworks[item.created_by] = []
		}
		homeworks[item.created_by].push(item)
	}
	resource.homeworks = homeworks
	return resource
}

export default teacherReducer;
