import React, { useEffect } from 'react';
import { Col, Modal, Row, Input } from 'antd';
import moment from 'moment';

import { teacherLang } from '../../../languages/teacher.language';
import { commonLang } from '../../../languages/common.language';
import {
	ROUTE_TEACHER_RESOURCE,
} from '../../../constants/app.constant';
import { RESOURCE_SEARCH } from '../../../constants/dom.constant';
import { S_TEACHER } from '../../../constants/tracker.constant';
import { startAppLoading, endAppLoading, filterObject } from '../../../actions/dom.action';
import {
	getAllResources,
	getResourceDetail,
	deleteResource,
	updateResource,
	getResourcesHomework,
} from '../../../actions/teacher.action';
import { trackerWithPageView } from '../../../actions/app.action';
import { ContextStore } from '../../../store/ContextStore';
import { text, colors, shadows } from '../../styles';

import Button from '../../Common/Button';
import ItemTitle from "./List/ItemTitle";
import Item from "./List/Item";
import ShareModal from "./ShareModal";

function Resources(props) {
	const { dispatch, teacher, auth } = React.useContext(ContextStore);
	const topDom = React.createRef();
	const todayDom = React.createRef();
	const [values, setValue] = React.useState({
		init: false,
		share: false,
		resource: null,
		copied: false,
		search: "",
	});
	const resourceItemContainerHeight = 64 + 24 + 56 + 57 + 69;
	const styles = {
		root: {
			backgroundColor: colors.white,
			boxShadow: shadows.elevation5,
		},
		header: {
			padding: `4px 0 20px`,
			backgroundColor: colors.transparent,
			display: `flex`,
			flexDirection: `row`,
		},
		title: {
			...text.h5,
			color: colors.gray1,
		},
		school: {
			...text.body1,
			color: colors.gray3,
		},
		listHeader: {
			...text.body2bold,
			color: colors.gray1,
			padding: `8px 24px`,
			borderBottom: `1px solid ${colors.gray5}`,
		},
		item: {
			padding: `16px 24px`,
			cursor: `pointer`,
		},
		dateItem: {
			paddingTop: 0,
			marginTop: 0,
		},
		resourceItemContainer: {
			maxHeight: `calc( 100vh - ${resourceItemContainerHeight}px)`,
			overflow: `scroll`,
			marginBottom: -24
		}
	}

	async function viewResource(resource) {
		dispatch(startAppLoading());
		dispatch(await getResourceDetail(resource));
		dispatch(await getResourcesHomework(resource.uuid));
		props.history.push(ROUTE_TEACHER_RESOURCE.replace(/:id/g, resource.uuid));
		dispatch(endAppLoading());
	}

	async function getResources() {
		setValue({ init: true });
		dispatch(startAppLoading());
		trackerWithPageView(S_TEACHER);
		dispatch(await getAllResources());
		dispatch(endAppLoading());
	}

	async function handleDelete(resource) {
		Modal.confirm({
			title: teacherLang.delete_resource_title,
			content: teacherLang.delete_resource_content,
			okText: commonLang.delete,
			cancelText: commonLang.cancel,
			icon: null,
			okButtonProps: { type: "danger" },
			onOk: async () => {
				dispatch(startAppLoading());
				dispatch(await deleteResource(resource.uuid));
				dispatch(endAppLoading());
			}
		});
	}

	async function handleUpdate(resource, changes) {
		dispatch(startAppLoading());
		dispatch(await updateResource(resource, changes));
		dispatch(endAppLoading());
	}

	function shareResource(resource) {
		setValue({
			...values,
			share: true,
			resource: resource,
		});
	}

	function closeShareModal() {
		setValue({
			...values,
			share: false,
		});
	}

	function handleToday() {
		if (!todayDom.current) return;
		todayDom.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
	}

	function handleTop() {
		if (!topDom.current) return;
		topDom.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
	}

	function handleSearch(e) {
		setValue({
			...values,
			search: e.target.value,
		});
	}

	useEffect(() => {
		if (!values.init && auth.role === "teacher") {
			getResources();
		}
	})

	let resources = []
	const today = moment().format("YYYY-MM-DD");
	const resourceGroups = teacher.resources
		.sort((a, b) => moment(b.start_at).format('X') - moment(a.start_at).format('X'))
		.reduce((acc, resource) => {
			const date = moment(resource.start_at).format("YYYY-MM-DD")
			acc[date] = (acc[date] || []).concat(resource);
			return acc;
		}, {});

	let latest = true
	const refParse = (ref, date) => {
		if (latest) {
			topDom.current = ref
			latest = false
		}
		if (today === date) todayDom.current = ref
	}
	for (let date in resourceGroups) {
		// <----- search and filter
		if (values.search)
			resourceGroups[date] = resourceGroups[date].filter((each) => filterObject(each, values.search.toLowerCase(), RESOURCE_SEARCH));
		if (resourceGroups[date].length === 0)
			continue;

		// <----- parse list item
		const dateItem = <div key={date} style={styles.dateItem} ref={(ref) => { refParse(ref, date) }}><ItemTitle date={date} /></div>;
		const units = resourceGroups[date].map(
			(unit, index) => <Item key={unit.uuid} unit={unit} viewResource={viewResource} handleDelete={handleDelete} handleUpdate={handleUpdate} shareResource={shareResource} />
		);
		resources = resources.concat(dateItem).concat(units);
	}

	if (resources.length === 0) {
		const emptyDiv = {
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			alignItems: "center",
			height: `calc(100vh - ${resourceItemContainerHeight}px)`,
		}
		resources = (
			<div style={emptyDiv}>
				<img alt="empty" src="/images/resources_empty.png" style={{width: 270, height: 150}} />
				<div style={{ marginTop: 8 }}>{commonLang.empty_resource}</div>
			</div>
		)
	}

	return (
		<div>
			<div style={styles.header}>
				<div className="resource-list-header">
					<span style={styles.title}>{commonLang.learning_journal}</span>
					<span className="resource-list-header-school" style={styles.school}>{auth.school.name}</span>
				</div>
				<div className="resource-list-filter">
					<Button type="filter" inlinestyle={{ outline: `none` }} onClick={handleToday}>{commonLang.today}</Button>
					<Button type="filter" inlinestyle={{ marginLeft: 8, outline: `none` }} onClick={handleTop}>{commonLang.recent}</Button>
				</div>
			</div>
			<div style={styles.root}>
				<Row style={styles.listHeader} type="flex" align="middle">
					<Col xs={24} sm={12} className="list-search" style={{ display: `flex` }}>
						<Input.Search
							placeholder={commonLang.search_resources}
							onChange={handleSearch}
							value={values.search}
						/>
					</Col>
					<Col xs={0} sm={4}>{teacherLang.subject}</Col>
					<Col xs={0} sm={2}>{teacherLang.status}</Col>
					<Col xs={0} sm={2} align="right">{teacherLang.read_no}</Col>
				</Row>
				<div style={styles.resourceItemContainer}>
					{resources}
				</div>
			</div>
			<ShareModal
				visible={values.share}
				onCancel={closeShareModal}
				resource={values.resource}
			/>
		</div>
	);
}
export default Resources;
