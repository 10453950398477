import React, { useEffect } from 'react';
import { Card, Input, Row, Col, Checkbox, Form } from 'antd';

import { studentLogin } from '../../../actions/auth.action';
import { trackerWithPageView, trackerWithEvent, externalLink } from '../../../actions/app.action';
import {
	S_LOGIN,
	CLICK_LOGIN_BUTTON,
} from '../../../constants/tracker.constant';
import { ContextStore } from '../../../store/ContextStore';
import { parseErrorFromCatch, errorLang } from '../../../languages/error.language';
import { publicLang } from '../../../languages/public.language';
import {
	startAppLoading,
	endAppLoading,
	showMessage,
} from '../../../actions/dom.action';
import {
	ROUTE_STUDENT,
	ROUTE_STUDENT_LOGIN,
	TERMS_AND_CONDITIONS,
} from '../../../constants/app.constant';
import { colors, shadows, text } from '../../styles';
import Button from '../../Common/Button';

function Login(props) {
	const { dispatch } = React.useContext(ContextStore);
	const [values, setValue] = React.useState({
		school: "",
		className: "",
		classNo: "",
		remember: true,
	});

	const styles = {
		loginPanel: {
			borderRadius: `12px`,
			boxShadow: shadows.elevation2,
			textAlign: `center`,
			maxWidth: `420px`,
			// minWidth: `380px`,
			margin: `auto`,
		},
		input: {
			borderRadius: `6px`,
			marginTop: `16px`,
		},
		title: {
			...text.body2tight,
			color: colors.gray1,
			fontWeight: `600`,
			margin: `16px 0px`,
		},
		agreement: {
			...text.body2loose,
			fontSize: `14px`,
			lineHeight: `24px`,
			color: colors.gray3,
			marginTop: `24px`
		},
		href: {
			color: colors.ndBlue,
			fontWeight: `500`,
			cursor: `pointer`,
		},
	}

	async function handleLogin(e) {
		e.preventDefault();
		const { school, className, classNo, remember } = values;
		let parseClassNo = classNo
		// cannot use `===`, otherwise it will be false (e.g. 1=="1")
		if (parseClassNo == parseInt(parseClassNo) && parseClassNo > 0 && parseClassNo < 10)
			parseClassNo = `0${parseInt(parseClassNo)}`

		setValue({
			...values,
			classNo: parseClassNo,
		})

		if (school === "" || className === "" || classNo === "") {
			showMessage(errorLang.public.empty_required_input)
			return;
		}

		if (classNo.toString().length > 2) {
			showMessage(errorLang.public.classno_too_long)
			return;
		}

		dispatch(startAppLoading());
		try {
			dispatch(await studentLogin(school, className, parseClassNo, remember));
			trackerWithEvent(CLICK_LOGIN_BUTTON, '', 1); // login status
			if(props.history.location.pathname===ROUTE_STUDENT_LOGIN){
				props.history.push(ROUTE_STUDENT);
			}
			dispatch(endAppLoading());
		} catch (e) {
			dispatch(endAppLoading());
			showMessage(parseErrorFromCatch(e));
			trackerWithEvent(CLICK_LOGIN_BUTTON, '', 0); // login status
			return;
		}
	}

	function changeValue(name, data) {
		let value = {};
		if (name === 'school')
			data = data.toUpperCase()
		if (name === 'className' || name === 'classNo')
			data = data.toUpperCase().replace(/ /g, '') // remove space
		value[name] = data;
		setValue({
			...values,
			...value,
		});
	}

	useEffect(() => {
		trackerWithPageView(S_LOGIN);
	}, []);

	return (
		<Card id="login-panel" bordered={false} style={styles.loginPanel} bodyStyle={{ padding: `32px 32px 24px 32px` }}>
			<div style={styles.title}>{publicLang.student_login}</div>
			<Form onSubmit={handleLogin}>
				<Row gutter={16}>
					<Col lg={24}>
						<Input style={styles.input} placeholder={publicLang.school_code} onChange={(e) => changeValue('school', e.target.value)} value={values.school} />
					</Col>
					<Col md={12}>
						<Input style={styles.input} placeholder={publicLang.class_name} onChange={(e) => changeValue('className', e.target.value)} value={values.className} />
					</Col>
					<Col md={12}>
						<Input type="number" style={styles.input} placeholder={publicLang.class_number} onChange={(e) => changeValue('classNo', e.target.value)} value={values.classNo} />
					</Col>

				</Row>
				<div style={{ width: `100%`, textAlign: `left`, margin: `20px 0px` }}>
					<Checkbox defaultChecked={true} onChange={(e) => changeValue('remember', e.target.checked)}>{publicLang.remember_me}</Checkbox>
				</div>
				<Button type="primary" block htmlType="submit">{publicLang.login}</Button>
			</Form>

			<div style={styles.agreement}>
				{publicLang.formatString(
					publicLang.agreement,
					<span style={styles.href} onClick={() => externalLink(TERMS_AND_CONDITIONS)}>{publicLang.tnc}</span>
				)}
				<div style={{ marginTop: `8px` }}>{publicLang.suggested_platforms}</div>
			</div>
		</Card>
	);
}

export default Login;
