import LocalizedStrings from 'react-localization';

export const studentLang = new LocalizedStrings({
	'zh-hk': {
		"complete": "完成",
		"complete_status": "完成度",
		"today_duration": "今天課程約 {0}",
		"video_duration": "影片長度",
		"download_attachment": "下載附件",
		"upload_homeworks": "上載功課",
		"homeworksHint" : "（不多於 {0} 個檔案）",

	},
});

export default { studentLang };
