import React from 'react';
import { Input } from 'antd';
import 'braft-editor/dist/index.css';
import BraftEditor from 'braft-editor';

import { teacherLang } from '../../../../languages/teacher.language';
import { commonLang } from '../../../../languages/common.language';
import { colors } from '../../../styles';
import { RICH_TEXT_CONTROLS } from '../../../../constants/dom.constant';
import Button from '../../../Common/Button';
import { showMessage, checkIsUrlValid } from '../../../../actions/dom.action';
import { errorLang } from '../../../../languages/error.language';

function EditMode(props) {
	const styles = {
		content: {
			backgroundColor: `#fff`,
			padding: `32px 48px`,
		},
		editTitle: {
			color: colors.gray1,
			marginBottom: `8px`,
		}
	}
	return (
		<div style={styles.content}>
			<div style={styles.editTitle}>{teacherLang.title}</div>
			<Input
				style={{ marginBottom: 16 }}
				defaultValue={props.title}
				onChange={(e) => props.changeValue('title', e.target.value)}
			/>
			<div style={styles.editTitle}>{teacherLang.desc}</div>
			<BraftEditor
				style={{ border: "1px solid #d9d9d9", borderRadius: 8, marginBottom: 16 }}
				controls={RICH_TEXT_CONTROLS}
				language="zh-hant"
				defaultValue={props.description}
				hooks={{
					'toggle-link': ({ href, target }) => {
						const vaild = checkIsUrlValid(href)
						if (!vaild) showMessage(errorLang.teacher.invalid_link)
						href =  vaild? href: ""
						return { href, target }
					}
				}}
				onChange={(e) => props.changeValue('description', e)}
			/>
			<p>
				<Button type="primary" inlinestyle={{ marginRight: 8 }} onClick={props.handleSubmit}>{commonLang.save}</Button>
				<Button type="secondary" onClick={props.handleCancel}>{commonLang.cancel}</Button>
			</p>
		</div>
	);
}
export default EditMode;

