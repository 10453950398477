import React from 'react';
import { Layout, Menu, Dropdown, Badge, Icon, Tag, Popover } from 'antd';

import { ContextStore } from '../../store/ContextStore';
import { commonLang } from '../../languages/common.language';
import { CLICK_ROADMAP } from '../../constants/tracker.constant';
import { APP_NAME, APP_PLATFORM, SYSTEM_PLATFORM_CENTRE } from '../../constants/app.constant';
import { Z_INDEX_MAIN_HEADER, NOTIFICATION_CONTENT } from '../../constants/dom.constant';
import { trackerWithEvent } from '../../actions/app.action';
import { readNotification } from '../../actions/dom.action';
import { ReactComponent as BellSvg } from '../../assets/bell.svg';
import { isAuthorized } from '../../reducers/rules/auth.rules';
// import {
//   ROADMAP_URL,
// } from '../../constants/app.constant';
import { colors, text, shadows } from '../styles';

function MainHeader(props) {
  const { dispatch, dom, auth } = React.useContext(ContextStore);

  const styles = {
    root: {
      zIndex: Z_INDEX_MAIN_HEADER,
      position: `fixed`,
      width: `100%`,
      boxShadow: shadows.elevation5,
      padding: `0px`,
    },
    container: {
      maxWidth: `1178px`,
      margin: `auto`,
      padding: `0px 32px`,
      display: `flex`,
      width: `100%`,
      position: `relative`,
    },
    menu: {
      marginLeft: `24px`,
      lineHeight: `64px`,
      height: `64px`,
      borderBottom: `none`
    },
    mobileMenu: {
      borderRight: `none`,
      width: `50vw`,
    },
    rightMenu: {
      display: `flex`,
      alignItems: `center`,
      justifyContent: `right`,
      position: `absolute`,
      right: `32px`,
      height: `100%`,
    },
    featureDescription: {
      ...text.body2tight,
      whiteSpace: `pre-wrap`,
      color: colors.gray2,
      marginTop: `6px`,
    },
    viewAllUpdates: {
      ...text.body2tight,
      color: colors.ndBlue,
    },
    featureTitle: {
      ...text.body2bold,
      color: colors.black,
    },
    updatesTitle: {
      ...text.body2bold,
      color: colors.black,
      lineHeight: `40px`,
      textAlign: `center`,
      cursor: `default`,
    },
    notificationBadge: {
      display: `flex`,
      margin: `0px 8px`,
    },
    newsItem: {
      cursor: `default`,
      borderTop: `1px solid ${colors.gray5}`,
      padding: `14px 16px`,
    },
    appName: {
      fontWeight: `500`,
      fontSize: `18px`,
      color: colors.gray2,
    }
  }

  // function toRoadmap() {
  //   window.open(ROADMAP_URL, '_blank');
  // }

  function handleNotification(visible) {
    if (visible) {
      trackerWithEvent(CLICK_ROADMAP);
      dispatch(readNotification(Date.now()));
    }
  }

  const news = NOTIFICATION_CONTENT.map((unit, index) => (
    <Menu.Item key={index} style={styles.newsItem}>
      <div>
        {
          unit.type==="new"
          ? <Tag style={{borderRadius: 3}} color={colors.success2}>{commonLang.new_feature}</Tag>
          : <Tag style={{borderRadius: 3}} color={colors.gray3}>{commonLang.enhancement}</Tag>
        }
        <span style={styles.featureTitle}>{unit.title}</span>
      </div>
      <div style={styles.featureDescription}>{unit.description}</div>
    </Menu.Item>
  ));
  const rightMenu = (
    <Menu style={{ width: `264px` }}>
      <Menu.Item key="header" style={styles.updatesTitle}>{commonLang.new_updates}</Menu.Item>
      {news}
      {/* <Menu.Divider />
      <Menu.Item key="footer" style={styles.viewAllUpdates} onClick={toRoadmap}>{commonLang.view_all_updates}</Menu.Item> */}
    </Menu>
  );
  const desktopMenu = <Menu
    id="top-menu"
    mode="horizontal"
    selectedKeys={[props.pathname]}
    style={styles.menu}
    className="top-menu"
  >
    {props.children}
  </Menu>

  const mobileMenu = <Menu
    mode="inline"
    selectedKeys={[props.pathname]}
    style={styles.mobileMenu}
    inlineIndent={8}
  >
    {props.children}
  </Menu>

  return (
    <Layout.Header style={styles.root}>
      <div style={styles.container}>
        {/* Logo */}
        <div className="header-logo-container" onClick={props.home}>
          <img alt={commonLang.file_sharing_platform} src={APP_PLATFORM===SYSTEM_PLATFORM_CENTRE ? "/images/tuition_logo.png" : "/images/logo.png"} style={{width: 40, height: 40}} className="header-logo" />
          <span className="header-brand" style={styles.appName}>{APP_NAME}</span>
        </div>

        {/* Menu Tab */}
        {desktopMenu}

        {/* Right Menu */}
        <div style={{ ...styles.rightMenu, ...props.rightMenuStyle }}>
          {props.rightMenu}
          {
            props.showNotification &&
            <Dropdown placement="bottomRight" overlay={rightMenu} trigger={['click']} onVisibleChange={handleNotification}>
              <div style={styles.notificationBadge}>
                <Badge dot count={dom.readNotification ? 0 : 1}><BellSvg /></Badge>
              </div>
            </Dropdown>
          }

          {
            isAuthorized(auth) &&
            <Popover placement="bottomRight" content={mobileMenu} trigger="click" className="top-menu-mobile">
              <Icon type="menu" />
            </Popover>
          }
        </div>

      </div>
    </Layout.Header>
  );
}
export default MainHeader;
