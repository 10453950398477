import {
	ACTION_ALL_RESOURCES,
	ACTION_VIEW_RESOURCE,
	ACTION_SUBMIT_HOMEWORK,
	ACTION_DELETE_HOMEWORK,
} from '../constants/student.constant';

import {
	storeItemToStorage,
	removeItemFromStorage,
} from '../actions/dom.action';
import { getSubject } from '../actions/app.action';

function studentReducer(state, action) {
	const payload = action.payload;
	switch (action.key) {
		case ACTION_ALL_RESOURCES:
			const { resources, resource, homeworks } = parseResources(payload, payload.selectedResource);
			storeItemToStorage('student.resource', JSON.stringify(resource));
			storeItemToStorage('student.resources', JSON.stringify(resources));
			storeItemToStorage('student.homeworks', JSON.stringify(homeworks));
			removeItemFromStorage('login.location');
			return Object.assign({}, state, {
				...state,
				resource: resource,
				resources: resources,
				homeworks: homeworks,
			});
		case ACTION_VIEW_RESOURCE:
			const { updatedResources, updatedResource } = updateResources(payload, state.resources, state.homeworks);
			storeItemToStorage('student.resource', JSON.stringify(updatedResource));
			storeItemToStorage('student.resources', JSON.stringify(updatedResources));
			removeItemFromStorage('login.location');
			return Object.assign({}, state, {
				...state,
				resource: updatedResource,
				resources: updatedResources,
			});
		case ACTION_SUBMIT_HOMEWORK:
			const { resourceAfterSubmit, homeworksAfterSubmit } = updateResourceHomework(payload, state.resource, state.homeworks);
			storeItemToStorage('student.resource', JSON.stringify(resourceAfterSubmit));
			storeItemToStorage('student.homeworks', JSON.stringify(homeworksAfterSubmit));
			return Object.assign({}, state, {
				...state,
				resource: resourceAfterSubmit,
				homeworks: homeworksAfterSubmit,
			});

		case ACTION_DELETE_HOMEWORK:
			const { resourceAfterDelete, homeworksAfterDelete } = deleteResourceHomework(payload, state.resource, state.homeworks);
			storeItemToStorage('student.resource', JSON.stringify(resourceAfterDelete));
			storeItemToStorage('student.homeworks', JSON.stringify(homeworksAfterDelete));
			return Object.assign({}, state, {
				...state,
				resource: resourceAfterDelete,
				homeworks: homeworksAfterDelete,
			});
		default:
			return state;
	}
}

function parseResources(payload, selectedResource) {
	let resource = {};
	const files = payload.files;
	const homeworks = payload.homeworks;

	const resources = files.map((unit, index) => {
		let subject = null
		if (unit.tags.subject !== undefined)
			if (unit.tags.subject[0] !== undefined)
				subject = getSubject(unit.tags.subject[0])

		const item = {
			...unit,
			type: unit.mime_type,
			subject: subject,
		};

		if (selectedResource && selectedResource === unit.uuid) {
			resource = item;
		}

		return item;
	});

	return {
		resources: resources,
		resource: resource,
		homeworks: homeworks
	}
}

function updateResources(payload, resources, homeworks) {
	const index = resources.findIndex((unit => unit.uuid === payload.uuid));
	payload.is_read = true; // mark as read in student sider
	resources[index] = payload;

	for (let [key, value] of Object.entries(homeworks)) {
		if (payload.uuid === key) {
			payload.homeworks = value
			break
		}
	}

	return { updatedResources: resources, updatedResource: payload };
}

function updateResourceHomework(payload, resource, homeworks) {
	let newHomework = homeworks
	let noMatch = true

	for (let [key, value] of Object.entries(homeworks)) {
		if (resource.uuid === key) {
			value.push(payload)
			resource.homeworks = value
			newHomework[key] = value
			noMatch = false
			break
		}
	}

	if (noMatch) {
		resource.homeworks = [payload]
		newHomework[resource.uuid] = [payload]
	}

	return { resourceAfterSubmit: resource, homeworksAfterSubmit: newHomework };
}

function deleteResourceHomework(payload, resource, homeworks) {
	let newHomework = homeworks
	for (let [key, value] of Object.entries(homeworks)) {
		if (resource.uuid === key) {
			const newList = value.filter((unit) => unit.uuid !== payload);
			resource.homeworks = newList
			newHomework[key] = newList
			break
		}
	}
	return { resourceAfterDelete: resource, homeworksAfterDelete: newHomework };
}

export default studentReducer;
