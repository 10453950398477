import LocalizedStrings from 'react-localization';
 
export const adminLang = new LocalizedStrings({
	'zh-hk':{
		"schools": "學校",
		"teachers": "老師",
		"students": "學生",
		"remarks": "備註",
		"username": "帳號名稱",
		"search_teachers": "搜尋老師",
		"search_students": "搜尋學生",
		"add_teacher": "新增老師",
		"add_student": "新增學生",
		"active": "啟用",
		"inactive": "停用",
		"status": "狀態",
		"set_active": "設為啟用",
		"set_inactive": "設為停用",
		"active_activity": "活躍",
		"empty_teacher": "沒有老師",
		"empty_student": "沒有學生",
	}
});

export default { adminLang };
