import LocalizedStrings from 'react-localization';

export const commonLang = new LocalizedStrings({
	'zh-hk': {
		"logout": "登出",
		"login": "登入",
		"tnc": "私隱權和條款",
		"faq": "常見問題",
		"file_sharing_platform": "雲端校本影片學習平台",
		"tuition_platform": "雲端教學及管理平台",
		"more": "更多",
		"learning_journal": "學習日誌",
		"view": "查看",
		"cancel": "取消",
		"delete": "刪除",
		"date": "日期",
		"save": "儲存",
		"edit": "編輯",
		"change": "更改",
		"today": "今日",
		"recent": "最新",
		"search_resources": "按資源名稱、描述、上載者或科目搜尋...",
		"name": "名稱",
		"download": "下載",
		"okay": "確定",

		"new_updates": "產品更新",
		"new_feature": "新功能",
		"enhancement": "改善",
		"view_all_updates": "查看所有更新",

		"webapp_refresh": "現可使用新版本，即將重新載入頁面",
		"empty_resource": "沒有資源",
		"select_files": "選擇檔案",
		"subject": "科目",
		"file_type_hint": "PDF 或 Word 檔案",
		"attachment": "附件",
		"upload_hint": "（最多上載 {0} 個檔案，每個檔案不大於 {1} MB）",
		"delete_file_hint": "你確定要刪除檔案嗎？",

		"minutes": "{0} 分鐘",
		"hours": "{0} 小時",

	},
});

export default { commonLang };
