// import React from 'react';
import { getTokenObjectForUpload as getTeacherTokenObjectForUpload } from '../../../actions/teacher.action';
import { getTokenObjectForUpload as getStudnetTokenObjectForUpload } from '../../../actions/student.action';
import { SDP_UPLOAD_SERVER_NAME, SDP_UPLOAD_SERVER_HOST, SDP_UPLOAD_PROTOCOL, FILE_UPLOAD_BY_TEACHER, FILE_UPLOAD_BY_STUDENT, UPLOAD_TYPE_MAIN_RESOURCE, UPLOAD_TYPE_ATTACHMENT, UPLOAD_TYPE_HOMEWORK } from '../../../constants/upload.constant';
import axios from 'axios';
import { UPLOAD_RESOURCE_FAIL_SPD, UPLOAD_RESOURCE_FAIL_S3, UPLOAD_ATTACHMENT_FAIL_SPD, UPLOAD_ATTACHMENT_FAIL_S3, UPLOAD_HOMEWORK_FAIL_SPD, UPLOAD_HOMEWORK_FAIL_S3, UPLOAD_RESOURCE_SUCCESS_SPD, UPLOAD_ATTACHMENT_SUCCESS_SPD, UPLOAD_HOMEWORK_SUCCESS_SPD, UPLOAD_RESOURCE_SUCCESS_S3, UPLOAD_ATTACHMENT_SUCCESS_S3, UPLOAD_HOMEWORK_SUCCESS_S3 } from '../../../constants/tracker.constant';
import { trackerWithEvent, trackerWithException } from '../../../actions/app.action';

export function uploadFileWithSignedUrl(uploadBy, type, file, successCallback, progressCallback, md5ProgressCallback, errorCallback) {
    return new Promise(async (resolve, reject) => {
        const fileName = file.name;
        let response;
        try {
            if (uploadBy === FILE_UPLOAD_BY_TEACHER) {
                response = await getTeacherTokenObjectForUpload(fileName);
            } else if (uploadBy === FILE_UPLOAD_BY_STUDENT) {
                response = await getStudnetTokenObjectForUpload(fileName);
            }
        } catch (e) {
            errorCallback(e); // if catch error from get token, return error immediately
            return;
        }

        let drivers = response.payload.data.drivers;
        const sharedObj = {
            ndspd: {
                loaded: 0,
                error: null,
                success: null,
            },
            s3: {
                loaded: 0,
                error: null,
                success: null,
            },
        };
        console.log(drivers);

        // <----- wrap events
        const progressFunc = (sObj) => {
            let loaded = 0;
            let count = 0;
            if (sObj.ndspd.error === null) { count++; loaded += sObj.ndspd.loaded; }
            if (sObj.s3.error === null) { count++; loaded += sObj.s3.loaded; }
            progressCallback({ loaded: (count > 0 ? Math.round(loaded / count) : Math.round(loaded)) })
        }
        const errorFunc = (sObj) => {
            let count = 0;
            if (sObj.ndspd.error !== null) { count++; }
            if (sObj.s3.error !== null) { count++; }
            if (count === 2) errorCallback(sObj.ndspd.error); // only show ndspd error if all failed
        }
        const successFunc = (sObj) => {
            let count = 0;
            let ttl = 0;
            if (sObj.ndspd.success !== null) { count++; }
            if (sObj.s3.success !== null) { count++; }
            if (sObj.ndspd.error === null) { ttl++; }
            if (sObj.s3.error === null) { ttl++; }
            if (count === ttl && ttl > 0) successCallback({
                dentry_id: sObj.ndspd.success ? sObj.ndspd.success.dentry_id : '',
                path: sObj.ndspd.success ? sObj.ndspd.success.path : '',
                path_global: sObj.s3.success ? sObj.s3.success : '',
            });
        }

        // <----- upload to s3 (multithreading without async)
        // uploadToS3SignedUrl(drivers.S3.signedUrl, drivers.S3.url, file, sharedObj, progressFunc, errorFunc, successFunc);

        // tempoary changes for moving to new API with both S3 and NDSDP START -- TODO: remove and uncomment above
        if (drivers === undefined) {
            drivers = {}
            drivers = {
                NDSPD: response.payload.data.info
            }
            uploadToS3SignedUrl(type, '', '', file, sharedObj, progressFunc, errorFunc, successFunc);
        }
        else {
            uploadToS3SignedUrl(type, drivers.S3.signedUrl, drivers.S3.url, file, sharedObj, progressFunc, errorFunc, successFunc);
        }
        // tempoary changes for moving to new API with both S3 and NDSDP END -- TODO: remove

        // <----- upload to ndspd

        try {
            const client = await window.CS(
                {
                    serviceName: SDP_UPLOAD_SERVER_NAME,
                    getToken: function () { return drivers.NDSPD; },
                    host: SDP_UPLOAD_SERVER_HOST,
                    protocol: SDP_UPLOAD_PROTOCOL
                });

            const emitter = await client.upload(file, { dirName: drivers.NDSPD.dir_name, scope: 1 })
            
            emitter.on('success', (res) => { 
                if (type === UPLOAD_TYPE_MAIN_RESOURCE) {
                    trackerWithEvent(UPLOAD_RESOURCE_SUCCESS_SPD)
                } else if (type === UPLOAD_TYPE_ATTACHMENT) {
                    trackerWithEvent(UPLOAD_ATTACHMENT_SUCCESS_SPD)
                } else if (type === UPLOAD_TYPE_HOMEWORK) {
                    trackerWithEvent(UPLOAD_HOMEWORK_SUCCESS_SPD)
                }
                sharedObj.ndspd.success = res.data; 
                successFunc(sharedObj); 
            })

            emitter.on('progress', (res) => { sharedObj.ndspd.loaded = res.loaded; progressFunc(sharedObj); })

            // emitter.on('md5Progress', (res) => { md5ProgressCallback(res) })
            
            emitter.on('error', (err) => {
                sharedObj.ndspd.error = err;
                errorFunc(sharedObj)

                if (type === UPLOAD_TYPE_MAIN_RESOURCE) {
                    trackerWithEvent(UPLOAD_RESOURCE_FAIL_SPD, err.message.replace(/ /g, '_'), err.code)
                } else if (type === UPLOAD_TYPE_ATTACHMENT) {
                    trackerWithEvent(UPLOAD_ATTACHMENT_FAIL_SPD, err.message.replace(/ /g, '_'), err.code)
                } else if (type === UPLOAD_TYPE_HOMEWORK) {
                    trackerWithEvent(UPLOAD_HOMEWORK_FAIL_SPD, err.message.replace(/ /g, '_'), err.code)
                }
                trackerWithException('UPLOAD FILE SPD - ' + err.message, false);
            })

            emitter.emit('start')
        } catch (e) {
            sharedObj.ndspd.error = e;
            if (type === UPLOAD_TYPE_MAIN_RESOURCE) {
                trackerWithEvent(UPLOAD_RESOURCE_FAIL_SPD, e.message.replace(/ /g, '_'), e.code)
            } else if (type === UPLOAD_TYPE_ATTACHMENT) {
                trackerWithEvent(UPLOAD_ATTACHMENT_FAIL_SPD, e.message.replace(/ /g, '_'), e.code)
            } else if (type === UPLOAD_TYPE_HOMEWORK) {
                trackerWithEvent(UPLOAD_HOMEWORK_FAIL_SPD, e.message.replace(/ /g, '_'), e.code)
            }
            trackerWithException('UPLOAD FILE SPD - ' + e.message, false);
            errorFunc(sharedObj)
        }
        // function stop() { emitter.emit('stop') }
        // function pause() { emitter.emit('pause') }
        // function start() { emitter.emit('start') }
    });
}

function uploadToS3SignedUrl(type, signedUrl, url, file, sObj, progressFunc, errorFunc, successFunc) {
    const config = {
        onUploadProgress: function (progressEvent) {
            sObj.s3.loaded = (progressEvent.loaded * 100) / progressEvent.total;
            progressFunc(sObj);
        },
        headers: {
            'Content-Type': file.type,
            'x-amz-acl': 'public-read',
        },
    };
    axios
        .put(signedUrl, file, config)
        .then(res => { 
            if (type === UPLOAD_TYPE_MAIN_RESOURCE) {
                trackerWithEvent(UPLOAD_RESOURCE_SUCCESS_S3)
            } else if (type === UPLOAD_TYPE_ATTACHMENT) {
                trackerWithEvent(UPLOAD_ATTACHMENT_SUCCESS_S3)
            } else if (type === UPLOAD_TYPE_HOMEWORK) {
                trackerWithEvent(UPLOAD_HOMEWORK_SUCCESS_S3)
            }
            sObj.s3.success = url; 
            successFunc(sObj); 
        })
        .catch(err => {
            sObj.s3.error = err;
            errorFunc(sObj);
            if (type === UPLOAD_TYPE_MAIN_RESOURCE) {
                trackerWithEvent(UPLOAD_RESOURCE_FAIL_S3, err.message.replace(/ /g, '_'), err.code)
            } else if (type === UPLOAD_TYPE_ATTACHMENT) {
                trackerWithEvent(UPLOAD_ATTACHMENT_FAIL_S3, err.message.replace(/ /g, '_'), err.code)
            } else if (type === UPLOAD_TYPE_HOMEWORK) {
                trackerWithEvent(UPLOAD_HOMEWORK_FAIL_S3, err.message.replace(/ /g, '_'), err.code)
            }
            trackerWithException('UPLOAD FILE S3 - ' + err.message, false);
        });
}
