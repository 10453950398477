import ReactGA from 'react-ga';
import axios from 'axios';
import {
  GOOGLE_ANALYTICS,
  FIREBASE_GA,
  APP_VERSION,
  CHECK_VERSION_API_KEYS,
  CHECK_VERSION_INTERVAL,
  CHECK_VERSION_REFRESH_INTERVAL,
  TERMS_AND_CONDITIONS,
  FAQ_URL,
} from '../constants/app.constant';
import { SUBJECT_LIST } from '../constants/subject.constant';
import {
  CATEGORIES,
  CATEGORY_OTHERS,
  CLICK_TNC,
  CLICK_FAQ,
} from '../constants/tracker.constant';
import { getItemFromStorage, storeItemToStorage, showMessage } from "../actions/dom.action";
import moment from 'moment';
import 'moment/locale/zh-hk';
import { getLocalApi } from '../utils/api';
import { parse } from 'node-html-parser';
import { commonLang } from '../languages/common.language';

export const trackerWithPageView = (pageName) => {
  // console.log('trackerWithPageView', pageName);
  if (GOOGLE_ANALYTICS)
    ReactGA.pageview(pageName);
  if (FIREBASE_GA)
    window.gtag('config', FIREBASE_GA, { 'page_title': pageName });
  return;
}

export const trackerWithEvent = (eventName, label, value) => {
  var ga = ReactGA.ga();
  var category = parseTrackerCategory(eventName);
  var gaObj = {
    hitType: 'event',
    eventCategory: category,
    eventAction: eventName,
  };
  var fgaObj = {
    event_category: category,
  };

  if (label) {
    gaObj.eventLabel = label;
    fgaObj.event_label = label;
  }
  if (value !== null && (Number.isInteger(parseInt(value)) || parseInt(value) === 0)) {
    gaObj.eventValue = parseInt(value);
    fgaObj.value = parseInt(value);
  }
  // console.log('trackerWithEvent', gaObj, fgaObj, GOOGLE_ANALYTICS);
  if (GOOGLE_ANALYTICS)
    ga('send', gaObj);
  if (FIREBASE_GA)
    window.gtag('event', eventName, fgaObj);
  return;
}

export const trackerWithException = (description, fatal) => {
  ReactGA.exception({ description, fatal });
}

export function parseProjectUrl(projectName, route) {
  const name = projectName.toLowerCase().replace(/ /g, "-");
  const url = route.replace(/:name/g, name);
  return url;
}

function parseTrackerCategory(eventName) {
  for (var key in CATEGORIES)
    if (CATEGORIES[key].indexOf(eventName) > -1)
      return key;
  return CATEGORY_OTHERS;
}

export function formatBytes(bytes) {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(1)) + ' ' + sizes[i];
}

export function formatDate(date, localeStr = "zh-hk") {
  return moment(date).locale(localeStr).format('ll')
}

// <------ upload related
export function checkFileType(fileType, acceptType){
  const pattern = '(' + acceptType.split(',').join('|').replace(/\//g, '\\\/') + ')';
	const regex = new RegExp(pattern, "g");
  return fileType.match(regex)
}

export function checkFileSize(fileSize,maxSize){
  return fileSize <= maxSize * 1024 * 1024
}

const subjects = SUBJECT_LIST.reduce((acc, subject) => {
  acc[subject.key] = subject.title
  if (subject.children) {
    const children_data = subject.children.reduce((child_acc, child_sub) => {
      child_acc[child_sub.key] = child_sub.title
      return child_acc
    }, {})
    acc = { ...acc, ...children_data }
  }
  return acc
}, {})

export function getSubject(key) {
  if (subjects[key])
    return subjects[key]
  return key
}

export async function checkVersion(isOnce=false){
  const flag = await checkWebappVersion();
  if(!flag){
    if(reloadIndexPage){
      showMessage(commonLang.webapp_refresh);
      const _reloadIndexPage = reloadIndexPage;
      setTimeout(function(){ _reloadIndexPage() }, CHECK_VERSION_REFRESH_INTERVAL);
      reloadIndexPage = null;
    }
  }else if(!isOnce){
    setTimeout((async function(){ await checkVersion(); }), CHECK_VERSION_INTERVAL);
  }
}

export function updateApiVersion(key, version){
  if(CHECK_VERSION_API_KEYS.indexOf(key)>=0){
    const current = getItemFromStorage('app.version.api');
    if(version!==current){
      storeItemToStorage(key, version);
      checkVersion(true);
    }
  }
}

async function checkWebappVersion(){
  const response = await getLocalApi().get(`/index.html?rnd=${Math.random()}`);
  const root = parse(response.data);
  const version = root.querySelector('#version').getAttribute('content');
  return APP_VERSION===version;
}

async function reloadIndexPage(){
  const response = await axios.get('/', { 
        headers: {
          "Pragma": "no-cache",
          "Expires": -1,
          "Cache-Control": "no-cache",
        },
      });
  window.location = '/';
}

export function externalLink(url) {
  switch (url) {
    case TERMS_AND_CONDITIONS:
      trackerWithEvent(CLICK_TNC);
      break;
    case FAQ_URL:
      trackerWithEvent(CLICK_FAQ);
      break;
    default:
      break;
  }
  window.open(url, '_blank');
}

export default {
  trackerWithPageView,
  trackerWithEvent,
  parseProjectUrl,
  formatBytes,
  formatDate,
  checkFileType,
  checkFileSize,
  getSubject,
  checkVersion,
  updateApiVersion,
  externalLink,
};
