import { run } from '../utils/promise';
import { getApi } from '../utils/api';
// import { fileToBase64 } from '../utils/file';
import {
	ACTION_ALL_RESOURCES,
	ACTION_VIEW_RESOURCE,
	ACTION_GET_TOKEN_FOR_UPLOAD,
	ACTION_SUBMIT_HOMEWORK,
	ACTION_DELETE_HOMEWORK,
	ACTION_MATERIAL_ACTION,
} from '../constants/student.constant';
import { getItemFromStorage } from './dom.action';

export const getAllResources = async (resource) => {
	const school_uuid = JSON.parse(getItemFromStorage('auth.school')).uuid;
	const response = await getApi(false).get(`/student/material/list?school_uuid=${school_uuid}`);
	response.data.data.selectedResource = resource;

	return run(
		ACTION_ALL_RESOURCES,
		response.data,
	);
}

export const getResourceDetail = async (data) => {
	await getApi().post(`/student/material/read`, data);
	const response = {data: data};
	return run(
		ACTION_VIEW_RESOURCE,
		response,
	);
}

export const getTokenObjectForUpload = async (fileName) => {
	const material_uuid = JSON.parse(getItemFromStorage('student.resource')).uuid;
	const response = await getApi().get(`/student/homework/signedUrl?material_uuid=${material_uuid}&file_name=${fileName}`)
	return run(
		ACTION_GET_TOKEN_FOR_UPLOAD,
		response,
	);
}

export const submitHomework = async (data) => {
	const response = await getApi().post(`/student/homework`, data);
	return run(
		ACTION_SUBMIT_HOMEWORK,
		response.data,
	);
}

export const deleteHomework = async (uuid) => {
	const response = await getApi().delete(`/student/homework/${uuid}`);
	return run(
		ACTION_DELETE_HOMEWORK,
		response.data,
	);
}

export const materialAction = async (uuid, action, details={}) => {
	const data = {
		material_uuid: uuid,
		action: action,
		details: details
	};
	const response = await getApi().post(`/student/material/action`, data);
	return run(
		ACTION_MATERIAL_ACTION,
		response.data,
	);
}

export default {
	getAllResources,
	getResourceDetail,
	getTokenObjectForUpload,
	submitHomework,
	deleteHomework,
	materialAction,
};
