import React from 'react';
import { colors, shadows } from '../styles';

function Button(props) {
  const base = {
    basic: {
      ...props.inlinestyle,
      borderRadius: `6px`,
      padding: props.icon ? `8px 20px 8px 12px` : `0px 20px`,
      boxShadow: shadows.elevation5,
      cursor: `pointer`,
      fontSize: `14px`,
      lineHeight: `20px`,
      height: `40px`,
      fontWeight: `500`,
      width: props.block ? `100%` : `default`,
    },
    filter: {
      ...props.inlinestyle,
      borderRadius: `6px`,
      padding: `0px 16px`,
      cursor: `pointer`,
      fontSize: `14px`,
      lineHeight: `20px`,
      height: `32px`,
      width: props.block ? `100%` : `default`,
    }
  }

  const styles = {
    primary: {
      ...base.basic,
      backgroundColor: colors.ndBlue,
      color: colors.white,
      border: `none`,
      fontWeight: `500`,
      
    },
    secondary: {
      ...base.basic,
      backgroundColor: colors.white,
      color: colors.gray2,
      border: `1px solid `+colors.gray5,
    },
    filter: {
      ...base.filter,
      backgroundColor: colors.white,
      color: colors.gray1,
      border: `1px solid `+colors.gray5,
    }
  }

  let name = "button-default";
  let style = base.basic;

  switch(props.type){
    case "primary":
      name = "button-primary";
      style = styles.primary;
      break;
    case "secondary":
      name = "button-secondary";
      style = styles.secondary;
      break;
    case "filter":
      name = "button-filter";
      style = styles.filter;
      break;
    default:
      break;
  }

  return <button className={name} style={style} onClick={props.onClick} disabled={props.disabled} type={props.htmltype}>{props.children}</button>;

}
export default Button;
