import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Layout } from 'antd';

import {
  Z_INDEX_MAIN,
} from '../../constants/dom.constant';
import { ContextStore } from '../../store/ContextStore';
import { scrollToTop } from '../../actions/dom.action';

import {  
  ROUTE_HOME,
  ROUTE_ORGADMIN,
  ROUTE_ORGADMIN_TEACHERS,
  ROUTE_ORGADMIN_TEACHER,
  ROUTE_ORGADMIN_ADD_TEACHER,
  ROUTE_ORGADMIN_STUDENTS,
  ROUTE_ORGADMIN_STUDENT,
  ROUTE_ORGADMIN_ADD_STUDENT,
} from '../../constants/app.constant';
import MainFooter from '../Common/MainFooter';
import TeachersView from './Teachers';
import StudentsView from './Students';
import EditTeacherView from './Teachers/EditTeacher';
import EditStudentView from './Students/EditStudent';
import AddTeacherView from './Teachers/AddTeacher';
import AddStudentView from './Students/AddStudent';
import TopNav from './TopNav';

const { Content } = Layout;

function OrgAdmin(props) {
  const { auth } = React.useContext(ContextStore);

  useEffect(() => {
    if(auth.role!=="orgadmin"){
      props.history.push("/"+auth.role);
    }
    const unlisten = props.history.listen((location, action) => {
      scrollToTop()
    });
    
    return () => {
      unlisten()
    }
  }, [auth.role, props.history]);

  return (
    <Layout style={{minHeight: `100vh`}}>
      <TopNav history={props.history} />

      {/* Handle empty user uuid */}
      <Route exact path={ROUTE_ORGADMIN_TEACHERS+"/"}>
        <Redirect to={ROUTE_ORGADMIN_TEACHERS} />
      </Route>
      <Route exact path={ROUTE_ORGADMIN_STUDENTS+"/"}>
        <Redirect to={ROUTE_ORGADMIN_STUDENTS} />
      </Route>

      {/* Orgadmin routes */}
      <Layout>
        <Content style={{marginTop:`64px`, padding: `24px 32px`, zIndex: Z_INDEX_MAIN }}>
          <div className="list-container">
            <Route exact path={ROUTE_HOME} component={TeachersView} />
            <Route exact path={ROUTE_ORGADMIN} component={TeachersView} />
            <Route exact path={ROUTE_ORGADMIN_TEACHERS} component={TeachersView} />
            <Route exact path={ROUTE_ORGADMIN_STUDENTS} component={StudentsView} />
          </div>
          <div className="upload-container">
            <Route exact path={ROUTE_ORGADMIN_ADD_TEACHER} component={AddTeacherView} />
            <Route exact path={ROUTE_ORGADMIN_ADD_STUDENT} component={AddStudentView} />
            <Route exact path={ROUTE_ORGADMIN_TEACHER} component={EditTeacherView} />
            <Route exact path={ROUTE_ORGADMIN_STUDENT} component={EditStudentView} />
          </div>
        </Content>
        <MainFooter />
      </Layout>

    </Layout>
  );
}
export default OrgAdmin;
