import React from 'react';
import { Layout, Select, DatePicker, Modal, Input, TreeSelect, Tabs } from 'antd';
import moment from 'moment';

import { ContextStore } from '../../../store/ContextStore';
import { ROUTE_TEACHER_RESOURCES, APP_PLATFORM, SYSTEM_PLATFORM_SCHOOL } from '../../../constants/app.constant';
import { SUBJECT_LIST } from '../../../constants/subject.constant';
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_SUBMIT_FORMAT,
  DEFAULT_DATETIME_FORMAT,
} from '../../../constants/teacher.constant';
import {
  FILE_UPLOAD_BY_TEACHER, UPLOAD_TYPE_ATTACHMENT,
} from '../../../constants/upload.constant';
import {
  startAppLoading,
  endAppLoading,
  showMessage,
  getItemFromStorage,
  convertAttachmentRequestData,
  formatDuration,
} from '../../../actions/dom.action';
import {
  deleteResource,
  updateResource,
  createResourceAttachment,
  deleteResourceAttachment
} from '../../../actions/teacher.action';
import { formatBytes, formatDate, trackerWithException } from '../../../actions/app.action';
import { commonLang } from '../../../languages/common.language';
import { teacherLang } from '../../../languages/teacher.language';
import { errorLang, parseErrorFromCatch } from '../../../languages/error.language';
import { uploadFileWithSignedUrl } from "../../Common/File/SignedUrlFileUploader";
import { colors, shadows, text } from '../../styles';
import StudentTable from './StudentTable';
import AttachmentTable from './AttachmentTable';

function TeacherSider(props) {
  const { Option } = Select;
  const { TabPane } = Tabs;
  const { dispatch, teacher } = React.useContext(ContextStore);
  const [newTags, setNewTags] = React.useState({});
  const [values, setValues] = React.useState({
    viewedModal: false,
    homeworksModal: false,
    editRemark: false,
    treeExpand: [],
    remarks: teacher.resource.remarks
  });

  const defaultClass = teacher.resource.tags && teacher.resource.tags.class ? teacher.resource.tags.class.sort() : [];
  const defaultSubject = teacher.resource.tags ? teacher.resource.tags.subject : [];

  const tags = getItemFromStorage('teacher.tags') ? JSON.parse(getItemFromStorage('teacher.tags')) : [];
  const styles = {
    root: {
      boxShadow: shadows.elevation3,
      color: colors.gray1,
    },
    item: {
      marginBottom: `24px`,
    },
    title: {
      ...text.body2loosen,
      color: colors.gray2,
    },
    text: {
      ...text.body2loosen,
      color: colors.gray1,
    },
    expiry_hint: {
      color: colors.gray3,
    },
    delete: {
      color: colors.danger2,
      cursor: `pointer`,
      marginBottom: 24,
    },
    button: {
      cursor: `pointer`,
      color: colors.ndBlue,
      marginTop: `4px`,
    },
    resourceDiv: {
      padding: "24px 24px 0",
      maxHeight: `calc(100vh - ${64 + 44}px)`,
      overflow: "scroll",
    }

  }

  async function handleDelete() {
    Modal.confirm({
      title: teacherLang.delete_resource_title,
      content: teacherLang.delete_resource_content,
      okText: commonLang.delete,
      cancelText: commonLang.cancel,
      icon: null,
      okButtonProps: { type: "danger" },
      onOk: async () => {
        try {
          dispatch(startAppLoading());
          dispatch(await deleteResource(teacher.resource.uuid));
          dispatch(endAppLoading());
          props.history.push(ROUTE_TEACHER_RESOURCES);
        } catch (e) {
          trackerWithException("DELETE RESOURCE - " + e.message, false)
          dispatch(endAppLoading());
          showMessage(parseErrorFromCatch(e))
        }
      }
    });
  }
  async function handleUpdate(changes) {
    if (changes.classes) {
      // if no class, show error message and reset to default
      if (changes.classes.length === 0) {
        showMessage(errorLang.teacher.class_not_empty)
        setNewTags({ value: defaultClass })
        return;
      }

      // replace all spaces, convert to upper case, filter unique values
      changes.classes = changes.classes
        .map(item => item.toUpperCase().replace(/ /g, ''))
        .filter((v, i, a) => a.indexOf(v) === i && v !== "")

      setNewTags({ value: changes.classes })
      if (JSON.stringify(defaultClass) === JSON.stringify(changes.classes)) {
        return
      }
    }

    // reuquest to update data
    try {
      dispatch(startAppLoading());
      dispatch(await updateResource(teacher.resource, changes));
      dispatch(endAppLoading());
    } catch (e) {
      trackerWithException("UPDATE RESOURCE - " + e.message, false)
      dispatch(endAppLoading());
      showMessage(parseErrorFromCatch(e))
    }

    setValues({
      ...values,
      editRemark: false,
    });
  }
  function formatStudentName(str) {
    return str.replace('::', '')
  }

  if (!teacher.resource || !teacher.resource.uuid) {
    return <div />
  }

  function uploadAttachment(attachmentFile) {
    if (!attachmentFile) {
      return
    }
    const onUploadSuccess = async (res) => {
      const attachmentData = convertAttachmentRequestData(teacher.resource.uuid, attachmentFile, res)
      handleUpdateAttachment({ isCreate: true, data: attachmentData })
    }
    const onUploadProgress = () => { }
    const onUploadMd5Progress = () => { }
    const onUploadError = (err) => {
      console.log("attachment upload error: ", err);
      dispatch(endAppLoading());
      showMessage(errorLang.teacher.upload_attachment_fail)
    }

    dispatch(startAppLoading());
    uploadFileWithSignedUrl(FILE_UPLOAD_BY_TEACHER, UPLOAD_TYPE_ATTACHMENT, attachmentFile, onUploadSuccess, onUploadProgress, onUploadMd5Progress, onUploadError)
  }

  async function handleUpdateAttachment(changes) {
    try {
      dispatch(startAppLoading());
      if (changes.isCreate) {
        dispatch(await createResourceAttachment(changes.data));
      } else {
        dispatch(await deleteResourceAttachment(changes.uuid));
      }
      dispatch(endAppLoading());
    } catch (e) {
      trackerWithException("UPDATE ATTACHMENT - " + e.message, false)
      dispatch(endAppLoading());
      showMessage(errorLang.teacher.upload_attachment_fail)
    }
  }

  // <----- combine student list, viewed list and homework list
  let students = [];
  let viewedStudentsWithHomeworks = [];
  if (APP_PLATFORM === SYSTEM_PLATFORM_SCHOOL)
    students = teacher.resource.reads;
  else
    students = teacher.students && teacher.resource.tags && teacher.resource.tags.students ? teacher.students.filter((record) => teacher.resource.tags.students.includes(record.uuid)) : [];

  for (let i in students) {
    let lastRead = null;
    let homeworks = [];
    const name = students[i].name;
    const uuid = APP_PLATFORM === SYSTEM_PLATFORM_SCHOOL ? name : students[i].uuid;

    if (teacher.resource.reads && teacher.resource.reads[uuid])
      lastRead = moment.utc(teacher.resource.reads[uuid].last_read_at).local().format(DEFAULT_DATETIME_FORMAT);
    if (teacher.resource.homeworks && teacher.resource.homeworks[name])
      homeworks = teacher.resource.homeworks[name];

    viewedStudentsWithHomeworks.push({
      key: uuid,
      name: formatStudentName(name),
      last_read_at: lastRead,
      homeworks: homeworks,
    });
  }

  return (
    <Layout.Sider
      breakpoint="lg"
      width={336}
      collapsedWidth={0}
      style={styles.root}
    >
      <Tabs defaultActiveKey="1" className="sider-tab-bar" style={{ width: 336 }}>
        <TabPane tab={teacherLang.resource} key="1" >
          <div style={styles.resourceDiv}>
            <div style={styles.item}>
              <div style={styles.title}>{teacherLang.learing_date}</div>
              <DatePicker
                style={{ marginTop: `8px` }}
                defaultValue={moment(teacher.resource.start_at, DEFAULT_DATE_FORMAT)}
                format={DEFAULT_DATE_FORMAT}
                onChange={(e) => e ? handleUpdate({ start_at: e.format(DEFAULT_DATE_SUBMIT_FORMAT) }) : null}
              />
            </div>

            <div style={styles.item}>
              <div style={styles.title}>{teacherLang.active_status}</div>
              <Select
                defaultValue={teacher.resource.is_active ? 1 : 0}
                style={{ width: `104px`, marginTop: `8px` }}
                onChange={(e) => handleUpdate({ is_active: e ? true : false })}
              >
                <Select.Option value={1} title={teacherLang.active}>{teacherLang.active}</Select.Option>
                <Select.Option value={0} title={teacherLang.inactive}>{teacherLang.inactive}</Select.Option>
              </Select>
            </div>

            <div style={styles.item}>
              <div style={styles.title}>{teacherLang.subject}</div>
              <div className="resource-tag-input-container">
                <TreeSelect
                  style={{ width: `100%`, marginTop: 8 }}
                  treeData={SUBJECT_LIST}
                  defaultValue={defaultSubject}
                  treeExpandedKeys={values.treeExpand}
                  onTreeExpand={e => setValues({...values, treeExpand: [e[e.length-1]]})}
                  placeholder={teacherLang.select_subject}
                  onChange={(e) => handleUpdate({ subjects: [e] })}
                />
              </div>
            </div>

            <div style={styles.item}>
              <div style={styles.title}>{teacherLang.remarks}</div>
              {
                values.editRemark
                  ? <div>
                    <Input.TextArea
                      value={values.remarks}
                      style={{ marginTop: `8px`, ...styles.text }}
                      onChange={(e) => setValues({ ...values, remarks: e.target.value })}
                      autoSize={{ minRows: 1, maxRows: 7 }}
                    />
                    <div style={styles.button} onClick={() => handleUpdate({ remarks: values.remarks })}>
                      {commonLang.change}
                    </div>
                  </div>
                  : <div style={styles.text}>
                    {teacher.resource.remarks}
                    <div style={styles.button} onClick={() => setValues({ ...values, editRemark: true })}>
                      {commonLang.edit}
                    </div>
                  </div>
              }

            </div>

            <div style={styles.item}>
              <div style={styles.title}>{teacherLang.upload_date}</div>
              <div style={styles.text} >{teacherLang.formatString(teacherLang.upload_by_date, formatDate(teacher.resource.created_at), teacher.resource.created_by)}</div>
            </div>

            <div style={styles.item}>
              <div style={styles.title}>{teacherLang.expiry_date}</div>
              <div style={styles.text}>
                {teacherLang.formatString(teacherLang.expiry_at, 30)}
                <span style={styles.expiry_hint}> - {formatDate(teacher.resource.expire_at)}</span>
              </div>
            </div>

            <div style={styles.item}>
              <div style={styles.title}>{teacherLang.file_size}</div>
              <div style={styles.text}>
                {formatBytes(teacher.resource.file_size)}
                {/* hide duration related ui */}
                {/* {teacher.resource.duration &&
                  <span> · {formatDuration(teacher.resource.duration )}</span>
                } */}
              </div>
            </div>

            {APP_PLATFORM === SYSTEM_PLATFORM_SCHOOL &&
              <div style={styles.item}>
                <div style={styles.title}>{teacherLang.class}</div>
                <div className="resource-tag-input-container">
                  <Select
                    mode="tags"
                    defaultValue={defaultClass}
                    {...newTags}
                    style={{ minWidth: 88 }}
                    onChange={(e) => handleUpdate({ classes: e })}>
                    {tags.map((unit) => <Option key={unit}>{unit}</Option>)}
                  </Select>
                </div>
              </div>
            }

            <div style={styles.delete} onClick={handleDelete}>{teacherLang.delete_resource}</div>
          </div>
        </TabPane>

        <TabPane tab={teacherLang.student} key="2" >
          <StudentTable
            students={viewedStudentsWithHomeworks}
            formatStudentName={formatStudentName}
          />
        </TabPane>

        <TabPane tab={teacherLang.formatString(teacherLang.attachment_count, teacher.resource.attachments.length)} key="3" >
          <AttachmentTable
            attachments={teacher.resource.attachments}
            uploadAttachment={uploadAttachment}
            handleUpdateAttachment={handleUpdateAttachment}
          />
        </TabPane>
      </Tabs>

    </Layout.Sider>
  );
}
export default TeacherSider;
