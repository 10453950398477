import { run } from '../utils/promise';
import { getPublicApi, getLocalApi } from '../utils/api';
import {
	ACTION_AUTH_LOGIN,
	ACTION_AUTH_LOGOUT,
	ACTION_TEACHER_LOGIN,
	ACTION_STUDENT_LOGIN,
	ACTION_TEACHER_LOGOUT,
	ACTION_STUDENT_LOGOUT,
} from '../constants/auth.constant';
import { storeRememberMe } from './dom.action';

export const teacherLogin = async (username, name, password, remember) => {
	const data = {
		username: username,
		password: password,
		name: name,
	};
	storeRememberMe(remember)
	const response = await getPublicApi().post(`/auth/login`, data);

	return run(
		ACTION_TEACHER_LOGIN,
		response.data,
	);
}

export const studentLogin = async (school, className, classNumber, remember) => {
	const data = {
		school_code: school,
		class_name: className,
		class_num: classNumber,
	};
	storeRememberMe(remember)
	const response = await getPublicApi().post(`/auth/studentLogin`, data);

	return run(
		ACTION_STUDENT_LOGIN,
		response.data,
	);
}

export const login = async (username, password, remember) => {
	const data = {
		username: username,
		password: password,
	};
	storeRememberMe(remember)
	const response = await getLocalApi().get(`/api/auth.json`, data);

	return run(
		ACTION_AUTH_LOGIN,
		response.data,
	);
}

export const logout = async () => ({
	key: ACTION_AUTH_LOGOUT,
	payload: true,
});

export const teacherLogout = async () => ({
	key: ACTION_TEACHER_LOGOUT,
	payload: true,
});

export const studentLogout = async () => ({
	key: ACTION_STUDENT_LOGOUT,
	payload: true,
});

export default {
	login,
	logout,
	teacherLogin,
	studentLogin,
	teacherLogout,
	studentLogout,
};
