import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Layout } from 'antd';

import {
  Z_INDEX_MAIN,
} from '../../constants/dom.constant';
import { ContextStore } from '../../store/ContextStore';
import { scrollToTop } from '../../actions/dom.action';

import {  
  ROUTE_HOME,
  ROUTE_STUDENT,
  ROUTE_STUDENT_RESOURCES,
  ROUTE_STUDENT_RESOURCE,
} from '../../constants/app.constant';
import StudentSider from './StudentSider';
import MainFooter from '../Common/MainFooter';
import ResourcesView from './Resources';
import ResourceView from './Resources/Resource';
import TopNav from './TopNav';

const { Content } = Layout;

function Student(props) {
  const { auth } = React.useContext(ContextStore);

  useEffect(() => {
    if(auth.role!=="student"){
      props.history.push("/"+auth.role);
    }
    const unlisten = props.history.listen((location, action) => {
      scrollToTop()
    });
    
    return () => {
      unlisten()
    }
  }, [auth.role, props.history]);

  return (
  	<Layout style={{minHeight: `100vh`}}>
      <TopNav history={props.history} />
      {/* Handle empty resource uuid */}
      <Route exact path={ROUTE_STUDENT_RESOURCES+"/"}>
        <Redirect to={ROUTE_STUDENT_RESOURCES} />
      </Route>

      {/* Student routes */}
      {
        props.history.location.pathname.includes('/resources/')
          ? <Layout style={{ marginTop: `64px`, backgroundColor: `#FAFAFA`, zIndex: Z_INDEX_MAIN }}>
            <Content>
              {/* set padding on detail-container to make scrollbar on the left*/}
              <div className="height-container">
                <div className="detail-container">
                  <Route exact path={ROUTE_STUDENT_RESOURCE} component={ResourceView} />
                </div>
              </div>
            </Content>
            <StudentSider history={props.history} />
          </Layout>
            
        : <Layout>
            <Content style={{marginTop:`64px`, padding: `24px 32px`, zIndex: Z_INDEX_MAIN }}>
              <div className="list-container">
                <Route exact path={ROUTE_HOME} component={ResourcesView} />
                <Route exact path={ROUTE_STUDENT} component={ResourcesView} />
                <Route exact path={ROUTE_STUDENT_RESOURCES} component={ResourcesView} />
              </div>
            </Content>
            <MainFooter />
          </Layout>
      }

    </Layout>
  );
}
export default Student;
