import React from 'react';
import { Table, Tooltip, Menu, Dropdown } from 'antd';

import { teacherLang } from '../../../languages/teacher.language';
import { commonLang } from '../../../languages/common.language';
import { ReactComponent as CircleCheck } from "../../../assets/circle-check.svg";
import { ReactComponent as ArrowDown } from "../../../assets/chevron-down.svg";
import { colors } from '../../styles';
import FileItem from '../../Common/File/FileItem/fileItem';
import { FILE_ITEM_TYPE_HOMEWORK } from '../../../constants/upload.constant';

function StudentTable(props) {
  const styles = {
    downloadLabel: {
      display: `flex`,
      color: colors.ndBlue,
      lineHeight: `24px`,
      alignItems: `center`,
      cursor: `pointer`,
    },
    arrow: {
      width: 16,
      height: 16,
      marginLeft: 4,
    },
    menuItem: {
      display: `flex`,
      alignItems: `center`,
    }
  }

  props.students.sort((a, b) => (a.name > b.name) ? 1 : -1);

  const columns = [
    {
      title: teacherLang.student,
      dataIndex: 'name',
      width: "40%",
      className: "table-item",
      defaultSortOrder: 'ascend',
      sorter: (a, b) => (a.name > b.name) ? 1 : -1,
      sortDirections: ['ascend', 'descend'],
      ellipsis: true,
    },
    {
      title: teacherLang.read_at,
      dataIndex: 'last_read_at',
      className: "table-item",
      render: (text, record) => {
        if (record.last_read_at) {
          return (
            <Tooltip
              placement="bottom"
              trigger={['click', 'hover']}
              title={teacherLang.formatString(teacherLang.last_read_at, text)}
            >
              <CircleCheck style={{ cursor: `pointer` }} />
            </Tooltip>
          )
        }
      },
    },
    {
      title: teacherLang.homework,
      dataIndex: 'homework',
      className: "table-item",
      render: (text, record) => {
        if (record.homeworks.length > 0) {
          const homeworks = record.homeworks.map((unit, index) => (
            <Menu.Item key={index}>
              <FileItem type={FILE_ITEM_TYPE_HOMEWORK} {...unit} name={unit.title} uid={unit.uuid} />
            </Menu.Item>
          ));
          return (
            <Dropdown trigger={['click', 'hover']} overlay={<Menu>{homeworks}</Menu>}>
              <div style={styles.downloadLabel}>
                {commonLang.download}
                <ArrowDown style={styles.arrow} />
              </div>
            </Dropdown>
          )
        }
        else
          return <span>– –</span>
      },
    }
  ];

  const tableProps = (dataCol, data, isHw = false) => {
    return {
      showHeader: true,
      columns: dataCol,
      dataSource: props.students,
      scroll: { y: `calc(100vh - ${64 + 44 + 54}px)` },
      pagination: false,
      className: 'student-table',
      bordered: false,
      locale: { emptyText: (<div style={{ marginTop: 26 }}>{teacherLang.student_table_empty}</div>) }
    }
  }

  return (
    <Table {...tableProps(columns, props.students)} />
  )
}

export default StudentTable;
