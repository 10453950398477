import React from 'react';
import { Menu } from 'antd';

import { ContextStore } from '../../store/ContextStore';
import { CLICK_LOGOUT } from '../../constants/tracker.constant';
import { showMessage } from '../../actions/dom.action';
import { trackerWithEvent, externalLink } from '../../actions/app.action';
import { logout } from '../../actions/auth.action';
import { commonLang } from '../../languages/common.language';
import { parseErrorFromCatch } from '../../languages/error.language';
import { ReactComponent as MoreSvg } from '../../assets/chevron-down.svg';

import {
  ROUTE_STUDENT,
  TERMS_AND_CONDITIONS,
  FAQ_URL,
  APP_PLATFORM, 
  SYSTEM_PLATFORM_CENTRE,
} from '../../constants/app.constant';
import MainHeader from '../Common/MainHeader';
import { text, colors } from '../styles';

const styles = {
  topNavSchoolItem: {
    ...text.body2tight,
    color: colors.gray2,
    height: 64,
    padding: 16,
  },
  topNavSchool: {
    ...text.body2tight,
    color: colors.gray1,
    paddingBottom: 8,
  },
  topNavItem: {
    ...text.body2tight,
    color: colors.gray2,
    padding: 16,
  },
  topNavLogoutItem: {
    ...text.body2tight,
    color: colors.gray2,
    background: colors.gray6,
    display: "flex",
    alignItems: "center",
  },
}

function TopNav(props) {
  const { dispatch, auth } = React.useContext(ContextStore);

  function toHome() {
    props.history.push(ROUTE_STUDENT);
  }

  async function handleLogout() {
    try {
      trackerWithEvent(CLICK_LOGOUT);
      dispatch(await logout());
      props.history.push(ROUTE_STUDENT);
      return;
    } catch (e) {
      showMessage(parseErrorFromCatch(e));
      return;
    }
  }

  return (
    <MainHeader
      showNotification
      rightMenuStyle={{ display: `flex` }}
      history={props.history}
      pathname={props.history.location.pathname}
      home={toHome}
    >
      <Menu.Item key={ROUTE_STUDENT} onClick={toHome}>{commonLang.learning_journal}</Menu.Item>
      <Menu.SubMenu
        key="more"
        title={
          <span>
            {commonLang.more}
            <MoreSvg className="top-menu-more-icon" />
          </span>
        }
      >
        {
          APP_PLATFORM === SYSTEM_PLATFORM_CENTRE &&
          <Menu.Item key="school-info" style={styles.topNavSchoolItem} className="top-menu-school">
            <div style={styles.topNavSchool}>{auth.school.name}</div>
            <div>{auth.userDisplayName}</div>
          </Menu.Item>
        }
        <Menu.Item key="faq" onClick={() => externalLink(FAQ_URL)} style={styles.topNavItem}>{commonLang.faq}</Menu.Item>
        <Menu.Item key="tnc" onClick={() => externalLink(TERMS_AND_CONDITIONS)} style={styles.topNavItem}>{commonLang.tnc}</Menu.Item>
        <Menu.Item key="logout" onClick={handleLogout} style={styles.topNavLogoutItem}>{commonLang.logout}</Menu.Item>
      </Menu.SubMenu>
    </MainHeader>
  )
}
export default TopNav;
