import React, { useEffect } from 'react';
import { Table } from 'antd';

import { getAllSchools } from '../../../actions/admin.action';
import { ContextStore } from '../../../store/ContextStore';
import Button from '../../Common/Button';

function Schools(props) {
	const { dispatch, admin } = React.useContext(ContextStore);
	const [values, setValue] = React.useState({
		init: false,
	});

	const columns = [
		{
			title: "name",
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: "code",
			dataIndex: 'code',
			key: 'code',
		},
		{
			title: "teachers",
			dataIndex: 'teachers',
			key: 'teachers',
			render: (text, record) => <Button type="primary" onClick={() => viewTeachers(record)}>Teachers</Button>,
		},
	];

	async function viewTeachers(school) {
		console.log(school)
	}

	async function getSchools() {
		setValue({init: true});
		dispatch(await getAllSchools());
	}

	useEffect(() => {
		if(!values.init){
			getSchools();
		}
	})

	return (
		<div>
			<Table 
				rowKey="uuid"
				columns={columns} 
				dataSource={admin.schools} 
				pagination={true} 
				scroll={{x: true}} 
			/>
		</div>
	);
}
export default Schools;
