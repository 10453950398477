import { getAppPlatformCode } from '../utils/app';
import { ACTION_ALL_RESOURCES as KEY01 } from './admin.constant';
import { ACTION_ALL_RESOURCES as KEY02 } from './teacher.constant';
import { ACTION_ALL_RESOURCES as KEY03 } from './student.constant';

// <----- external app IDs and secrets
export const GOOGLE_ANALYTICS = process.env.REACT_APP_GOOGLE_ANALYTICS;
export const FIREBASE_GA = process.env.REACT_APP_FIREBASE_GA;

// <----- external paths
export const PATH_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const PATH_APP_URL = process.env.REACT_APP_URL;
export const ROADMAP_URL = 'https://edu.nd.com.hk/vlp';
export const FAQ_VLP = 'https://edu.nd.com.hk/vlp/faq';
export const TERMS_VLP = 'https://edu.nd.com.hk/vlp/terms';
export const FAQ_TUITION = 'https://edu.nd.com.hk/tuition-platform/faq';
export const TERMS_TUITION = 'https://edu.nd.com.hk/vlp/terms';
export const S3_URL = process.env.REACT_APP_S3_URL;

// <----- platforms
export const PLATFORM_DESKTOP = 0;
export const PLATFORM_IOS = 1;
export const PLATFORM_ANDROID = 2;
export const PLATFORM_WINDOWS_PHONE = 3;
export const PLATFORM = getAppPlatformCode(window.navigator.userAgent || window.navigator.vendor || window.opera);
export const IS_MOBILE_PLATFORM = PLATFORM===PLATFORM_IOS || PLATFORM===PLATFORM_ANDROID; // || true;

// <----- system
export const NODE_ENV = process.env.NODE_ENV;
export const APP_NAME = process.env.REACT_APP_NAME;
export const APP_SERVER_LOCAL = 'Local';
export const APP_SERVER_DEVELOPMENT = 'Development';
export const APP_SERVER_STAGING = 'Staging';
export const APP_SERVER_PRODUCTION = 'Production';
export const APP_SERVER = process.env.REACT_APP_SERVER ? process.env.REACT_APP_SERVER : APP_SERVER_LOCAL;
export const APP_VERSION = process.env.REACT_APP_VERSION ? process.env.REACT_APP_VERSION : '0.0.1';
export const SYSTEM_PLATFORM_SCHOOL = "School";
export const SYSTEM_PLATFORM_CENTRE = "Centre";
export const APP_PLATFORM = process.env.REACT_APP_PLATFORM ? process.env.REACT_APP_PLATFORM : SYSTEM_PLATFORM_SCHOOL;
export const TERMS_AND_CONDITIONS = process.env.REACT_APP_PLATFORM === SYSTEM_PLATFORM_SCHOOL ? TERMS_VLP : TERMS_TUITION;
export const FAQ_URL = process.env.REACT_APP_PLATFORM === SYSTEM_PLATFORM_SCHOOL ? FAQ_VLP : FAQ_TUITION;
export const LANGUAGE_EN_US = 'en-us';
export const LANGUAGE_ZH_HK = 'zh-hk';
export const MAX_UPLOAD_FILE_SIZE = process.env.REACT_APP_MAX_UPLOAD_FILE_SIZE ? parseInt(process.env.REACT_APP_MAX_UPLOAD_FILE_SIZE) : 2097152;

// <----- check webapp version
export const CHECK_VERSION_API_KEYS = [KEY01, KEY02, KEY03];
export const CHECK_VERSION_INTERVAL = 900000; // 15 minutes
export const CHECK_VERSION_REFRESH_INTERVAL = 5000; // 5 seconds

// <----- system message
export const CHECK_SYSTEM_MESSAGE_INTERVAL = 900000; // 15 minutes

// <----- cdn
export const CDN_CACHE_REFRESH = 900000; // 15 minutes
export const CND_TYPE_ND_GCDNCS = 'nd-gcdncs';
export const CND_TYPE_ND_CDNCS = 'nd-cdncs';
export const CND_TYPE_AWS_S3 = 'aws-s3';
export const CND_ALL_TYPES = [
		CND_TYPE_ND_GCDNCS, // GA CDN index: 1
		CND_TYPE_ND_CDNCS, // GA CDN index: 2
		CND_TYPE_AWS_S3, // GA CDN index: 3
	];
export const CDN_PREFIX_PATHS = {
		'nd-gcdncs': 'https://gcdncs.101.com/v0.1/download?serviceName=cherrypicks&dentryId=', 
		'nd-cdncs': 'https://cdncs.101.com/v0.1/download?serviceName=cherrypicks&dentryId=', 
		'aws-s3': 'https://s3-ap-east-1.amazonaws.com/', 
	};
export const CDN_ND_SDP_LOAD_TEST_DENTRY_ID = '643373c3-ea34-46d7-bce0-052c99f01014';
export const CDN_ND_SDP_LOAD_TEST_GCDNCS_PATH = CDN_PREFIX_PATHS[CND_TYPE_ND_GCDNCS]+CDN_ND_SDP_LOAD_TEST_DENTRY_ID+'&';
export const CDN_ND_SDP_LOAD_TEST_CDNCS_PATH = CDN_PREFIX_PATHS[CND_TYPE_ND_CDNCS]+CDN_ND_SDP_LOAD_TEST_DENTRY_ID+'&';
export const CDN_AWS_S3_LOAD_TEST_PATH = CDN_PREFIX_PATHS[CND_TYPE_AWS_S3]+'file-sharing-assets-bucket/_system/load-test.zip?';
export const CDN_ALL_LOAD_TEST_PATHS = {
		'nd-gcdncs': CDN_ND_SDP_LOAD_TEST_GCDNCS_PATH, 
		'nd-cdncs': CDN_ND_SDP_LOAD_TEST_CDNCS_PATH, 
		'aws-s3': CDN_AWS_S3_LOAD_TEST_PATH, 
	};

// <----- actions
export const ACTION_CHANGE_LANGUAGE = 'action_change_language';

// <----- routes
export const ROUTE_HOME = '/';
export const ROUTE_LOGIN = '/login';

export const ROUTE_SUPERADMIN = '/superadmin';
export const ROUTE_SUPERADMIN_LOGIN = '/superadmin/login';
export const ROUTE_SUPERADMIN_SCHOOLS = '/superadmin/schools';

export const ROUTE_ORGADMIN = '/orgadmin';
export const ROUTE_ORGADMIN_TEACHERS = '/orgadmin/teachers';
export const ROUTE_ORGADMIN_TEACHER = '/orgadmin/teachers/:id';
export const ROUTE_ORGADMIN_ADD_TEACHER = '/orgadmin/add/teacher';
export const ROUTE_ORGADMIN_STUDENTS = '/orgadmin/students';
export const ROUTE_ORGADMIN_STUDENT = '/orgadmin/students/:id';
export const ROUTE_ORGADMIN_ADD_STUDENT = '/orgadmin/add/student';

export const ROUTE_TEACHER = '/teacher';
export const ROUTE_TEACHER_LOGIN = '/teacher/login';
export const ROUTE_TEACHER_REPORT = '/teacher/report';
export const ROUTE_TEACHER_UPLOAD = '/teacher/upload';
export const ROUTE_TEACHER_RESOURCES = '/teacher/resources';
export const ROUTE_TEACHER_RESOURCE = '/teacher/resources/:id';

export const ROUTE_STUDENT = '/student';
export const ROUTE_STUDENT_LOGIN = '/student/login';
export const ROUTE_STUDENT_RESOURCES = '/student/resources';
export const ROUTE_STUDENT_RESOURCE = '/student/resources/:id';
