import {
  ACTION_AUTH_LOGIN,
  ACTION_AUTH_LOGOUT,
  ACTION_TEACHER_LOGIN,
  ACTION_STUDENT_LOGIN,
  ACTION_TEACHER_LOGOUT,
  ACTION_STUDENT_LOGOUT,
 } from '../constants/auth.constant';
import { 
	logout,
	teacherLogout,
	studentLogout,
} from '../reducers/rules/auth.rules';
import { addHotjarIdentity } from '../actions/hotjar.action';
import { storeItemToStorage } from '../actions/dom.action';

function authReducer(state, action) {
	const payload = action.payload;
	switch (action.key) {
		case ACTION_AUTH_LOGIN:
			const {userId, token, userDisplayName} = parsePayloadFromAuthLogin(payload);
			storeItemToStorage('auth.userId', userId);
			storeItemToStorage('auth.token', token);
			storeItemToStorage('auth.userDisplayName', userDisplayName);
			storeItemToStorage('auth.role', "superadmin");
			addHotjarIdentity(userDisplayName);
			return Object.assign({}, state, {
						...state,
						userId: userId,
						token: token,
						userDisplayName: userDisplayName,
						role: "superadmin",
					});
		case ACTION_TEACHER_LOGIN:
			const schoolData = parseTeacherLogin(payload);
			storeItemToStorage('auth.userId', schoolData.userId);
			storeItemToStorage('auth.token', schoolData.token);
			storeItemToStorage('auth.userDisplayName', schoolData.userDisplayName);
			storeItemToStorage('auth.school', JSON.stringify(schoolData.school));
			storeItemToStorage('auth.role', schoolData.role);
			addHotjarIdentity(schoolData.userDisplayName);
			return Object.assign({}, state, {
						...state,
						userId: schoolData.userId,
						token: schoolData.token,
						userDisplayName: schoolData.userDisplayName,
						school: schoolData.school,
						role: schoolData.role,
					});
		case ACTION_STUDENT_LOGIN:
			const studentData = parseStudentLogin(payload);
			storeItemToStorage('auth.userId', studentData.userId);
			storeItemToStorage('auth.token', studentData.token);
			storeItemToStorage('auth.userDisplayName', studentData.userDisplayName);
			storeItemToStorage('auth.school', JSON.stringify(studentData.school));
			storeItemToStorage('auth.role', "student");
			addHotjarIdentity(studentData.userDisplayName);
			return Object.assign({}, state, {
						...state,
						userId: studentData.userId,
						token: studentData.token,
						userDisplayName: studentData.userDisplayName,
						school: studentData.school,
						role: "student",
					});
		case ACTION_AUTH_LOGOUT:
			logout();
			return Object.assign({}, state, {
					...state,
					userId: null,
					token: null,
					userDisplayName: "",
					school: {},
				});
		case ACTION_TEACHER_LOGOUT:
			teacherLogout();
			return Object.assign({}, state, {
					...state,
					userId: null,
					token: null,
					studentToken: null,
					userDisplayName: "",
					school: {},
				});
		case ACTION_STUDENT_LOGOUT:
			studentLogout();
			return Object.assign({}, state, {
					...state,
					userId: null,
					studentToken: null,
					userDisplayName: "",
					school: {},
				});
		default:
			return state;
	}
}
export default authReducer;

function parseTeacherLogin(payload){
	return {
		userId: payload.user.uuid,
		token: payload.token,
		userDisplayName: payload.user.name,
		name: payload.user.name,
		role: payload.user.user_type,
		school: payload.schools[0],
	};
}

function parseStudentLogin(payload){
	return {
		userId: payload.school.uuid,
		token: payload.token,
		userDisplayName: payload.school.name,
		school: payload.school,
	};
}

function parsePayloadFromAuthLogin(payload){
	return {
		userId: payload.user.uuid,
		token: payload.jwt.token,
		userDisplayName: payload.user.username,
	};
}
