import React from 'react';
import { Upload, Row, Col, Button } from 'antd';

import {
  ATTACHMENT_FILE_MAX_COUNT,
  FILE_ITEM_TYPE_TEACHER_EDIT,
  UPLOAD_TYPE_ATTACHMENT,
} from '../../../constants/upload.constant';
import {
  showMessage,
  getUploadFileCheckingResult,
  getUploadProps,
  convertResponseToFileItemObjList
} from '../../../actions/dom.action';
import { teacherLang } from '../../../languages/teacher.language';
import { commonLang } from '../../../languages/common.language';
import { ReactComponent as CloudUpload } from "../../../assets/upload-cloud.svg";
import { colors, text } from '../../styles';
import FileItem from '../../Common/File/FileItem/fileItem';

function AttachmentTable(props) {

  let files = []
  const divStyle = {
    header: {
      background: colors.gray6,
      display: "flex",
      justifyContent: "space-between",
      padding: "16px 24px",
      borderBottom: `1px solid ${colors.gray5}`,
    },
    headerText: {
      ...text.body2bold,
      color: colors.gray1,
    },
    uploadBtn: {
      height: 20,
      display: "flex",
      alignItems: "center",
      color: colors.ndBlue
    },
    uploadBtnText: {
      marginLeft: 4,
    },
    item: {
      // padding: "18px 26px",
      borderBottom: `1px solid ${colors.gray5}`,
    },
    fileItem: {
      padding: "18px 26px",
    },
    emptyItem: {
      paddingTop: 48,
      textAlign: "center",
    }
  }

  if (props.attachments && props.attachments.length > 0) {
    files = convertResponseToFileItemObjList(props.attachments)
  }

  // attachment callback
  const handleSelectAttachment = (file, fileList) => {
    console.log("handleAttachmentSelected", file, fileList)
    const result = getUploadFileCheckingResult(UPLOAD_TYPE_ATTACHMENT, file);
    if (!result.isReady) {
      showMessage(result.error)
    } else {
      if (files.length < ATTACHMENT_FILE_MAX_COUNT) {
        props.uploadAttachment(file)
      }
    }
    return false;
  }

  const handleDeleteAttachment = (file) => {
    console.log("handleDeleteAttachment", file.uuid)
    props.handleUpdateAttachment({ isCreate: false, uuid: file.uuid })
  }
  // attachment upload props 
  const attachmentUploadProps = getUploadProps(UPLOAD_TYPE_ATTACHMENT, files, handleSelectAttachment, () => { })

  // attachement list 
  let list = files.map((unit, index) => {
    return (
      <Col span={24} key={index} style={divStyle.item}>
        <FileItem
          type={FILE_ITEM_TYPE_TEACHER_EDIT} {...unit}
          deleteAction={() => { handleDeleteAttachment(unit) }}
          inlineCSS={divStyle.fileItem} 
          isDeleteAfterConfirm={true}
          popupDirection={"topRight"}
          deleteConfirmText={teacherLang.delete_attachment_hint}/>
      </Col>

    )
  })

  if (files.length === 0) list = (<Col span={24} style={divStyle.emptyItem}> {teacherLang.empty_attachment}</Col>)

  return (
    <Row>
      <Col span={24} style={divStyle.header}>
        <div style={divStyle.headerText}>{commonLang.attachment}</div>
        <Upload {...attachmentUploadProps} >
          <Button type="link" style={divStyle.uploadBtn}>
            <CloudUpload style={{ width: 16, height: 14 }} />
            <span style={divStyle.uploadBtnText}>{teacherLang.upload_btn}</span>
          </Button>
        </Upload>
      </Col>
      {list}
    </Row>
  )
}

export default AttachmentTable;
