import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { ContextStore } from '../../../store/ContextStore';
import Previewer from '../../Common/File/Previewer';
import { ROUTE_STUDENT_RESOURCES } from '../../../constants/app.constant';
import { startAppLoading, endAppLoading, getItemFromStorage, getUploadProps, getUploadFileCheckingResult, convertHomeworkRequestData, showMessage, convertResponseToFileItemObjList } from '../../../actions/dom.action';
import { getAllResources, getResourceDetail, submitHomework, deleteHomework, materialAction } from '../../../actions/student.action';
import { text, colors, shadows } from '../../styles';
import { studentLang } from '../../../languages/student.language';
import { errorLang, parseErrorFromCatch } from '../../../languages/error.language';
import {
	FILE_ITEM_TYPE_VIEW,
	HOMEWORK_FILE_MAX_COUNT,
	FILE_ITEM_TYPE_UPLOAD,
	UPLOAD_TYPE_HOMEWORK,
	FILE_UPLOAD_BY_STUDENT,
	HOMEWORK_FILE_MAX_SIZE_IN_MB,
} from '../../../constants/upload.constant';
import { 
	S_STUDENT_RESOURCE, 
	CLICK_PLAY_VIDEO, 
	CLICK_DOWNLOAD_ATTACHMENT,
	CLICK_DOWNLOAD_HOMEWORK,
} from '../../../constants/tracker.constant';
import { PLAY_MATERIAL, DOWNLOAD_ATTACHMENT } from '../../../constants/student.constant';
import { trackerWithPageView, trackerWithEvent, trackerWithException } from '../../../actions/app.action';
import FileItem from '../../Common/File/FileItem/fileItem';
import { Row, Col, Upload } from 'antd';
import Button from '../../Common/Button'
import { uploadFileWithSignedUrl } from '../../Common/File/SignedUrlFileUploader';
import { commonLang } from '../../../languages/common.language';
import { ReactComponent as UploadSvg } from '../../../assets/upload-cloud.svg';

function Resource(props) {
	const { dispatch, dom, student, auth } = React.useContext(ContextStore);
	const [values, setValue] = React.useState({
		uuid: student.resource.uuid,
		init: false,
		tracker: false,
		play: false,
	});

	let files = []
	const pageId = useParams().id;
	const styles = {
		content: {
			padding: `32px 48px`,
			backgroundColor: `#fff`,
			boxShadow: shadows.elevation5,
			borderBottomLeftRadius: 6,
			borderBottomRightRadius: 6,
		},
		title: {
			...text.h6,
			color: colors.gray1,
			paddingBottom: 16,
		},
		description: {
			...text.body1,
			color: colors.gray1,
		},
		subtitle: {
			...text.body1,
			color: colors.gray1,
			margin: "16px 0 8px"
		},
		hint: {
			color: colors.gray3
		},
	}

	async function getResources(uuid) {
		dispatch(startAppLoading());
		setValue({ ...values, init: true });
		dispatch(await getAllResources(uuid));
		const resource = getItemFromStorage('student.resource') ? JSON.parse(getItemFromStorage('student.resource')) : {};
		// redirect to resources list if invalid resource uuid
		if (!resource.uuid) {
			props.history.push(ROUTE_STUDENT_RESOURCES);
		}
		dispatch(await getResourceDetail(resource));
		dispatch(endAppLoading());
	}

	async function onDownloadAttachment(uuid) {
		dispatch(materialAction(student.resource.uuid, DOWNLOAD_ATTACHMENT, { attachemnt_uuid: uuid }));
		trackerWithEvent(CLICK_DOWNLOAD_ATTACHMENT);
	}

	function onDownloadHomework(uuid) {
		trackerWithEvent(CLICK_DOWNLOAD_HOMEWORK);
	}

	function pageTracker() {
		setValue({
			...values,
			tracker: true
		});
		trackerWithPageView(S_STUDENT_RESOURCE);
	}

	function resetState() {
		setValue({
			...values,
			uuid: student.resource.uuid,
			tracker: false,
			play: false,
		});
	}

	useEffect(() => {
		if (values.uuid !== student.resource.uuid) {
			resetState();
		}
		if (!values.init
			&& !dom.loading
			&& student.resource.uuid !== pageId
			&& auth.role === "student") {
			getResources(pageId);
		}
		if (!values.tracker) {
			pageTracker()
		}
	})

	function parseAttachments(attachemnts) {
		if (attachemnts && attachemnts.length > 0) {
			const divStyle = {
				container: {
					margin: "32px -48px 0",
					padding: "32px 48px 0",
					borderTop: `1px solid ${colors.gray5}`
				},
				title: {
					...text.body1,
					color: colors.gray2,
					marginBottom: 16
				},
			}
			const files = convertResponseToFileItemObjList(attachemnts)
			const list = files.map((unit, index) => {
				return (
					<Col span={24} key={index}>
						<FileItem
							type={FILE_ITEM_TYPE_VIEW} {...unit}
							onDownload={() => onDownloadAttachment(unit.uuid)} />
					</Col>
				)
			})

			return (
				<div style={divStyle.container}>
					<div style={divStyle.title}>{commonLang.attachment}</div>
					<Row gutter={[0, 16]}>{list}</Row>
				</div>
			)

		} else {
			return null
		}
	}

	function parseHomeworks(homeworks) {
		let files = []
		const divStyle = {
			container: {
				padding: "24px 48px",
				marginTop: 12,
				backgroundColor: `#fff`,
				boxShadow: shadows.elevation5,
				borderBottomLeftRadius: 6,
				borderBottomRightRadius: 6,
			},
			title: {
				...text.subtitle,
				color: colors.gray1,
			},
			hint: {
				...text.body2tight,
				color: colors.gray3,
				marginBottom: 16
			},
		}
		if (homeworks && homeworks.length > 0) {
			files = convertResponseToFileItemObjList(homeworks)
		}
		const list = files.map((unit, index) => {
			return (
				<Col span={24} key={index}>
					<FileItem {...unit}
						type={FILE_ITEM_TYPE_UPLOAD}
						deleteAction={() => { handleHomeworkCancelled(unit) }}
						isDeleteAfterConfirm={true}
						deleteConfirmText={commonLang.delete_file_hint} 
						onDownload={() => onDownloadHomework(unit)} />
				</Col>
			)
		})

		const uploadProps = getUploadProps(UPLOAD_TYPE_HOMEWORK, files, handleHomeworkSelected, handleHomeworkCancelled)
		return (
			<div style={divStyle.container}>
				<div style={divStyle.title}>
					{studentLang.upload_homeworks}
				</div>
				<div style={divStyle.hint}>
					{commonLang.file_type_hint} {commonLang.formatString(commonLang.upload_hint, HOMEWORK_FILE_MAX_COUNT, HOMEWORK_FILE_MAX_SIZE_IN_MB)}
				</div>

				<div>
					<Upload {...uploadProps} >
						<Button type="secondary" htmltype="button" disabled={files.length >= HOMEWORK_FILE_MAX_COUNT} inlinestyle={{ display: "flex", alignItems: "center", padding: "14px 16px" }} >
							<UploadSvg style={{ width: 16, height: 14, marginRight: 8 }} />
							{commonLang.select_files}
						</Button>
					</Upload>

					{files.length > 0 &&
						<Row gutter={[0, 6]} style={{ marginTop: 8 }}>{list}</Row>
					}
				</div>
			</div>
		)
	}

	function handleHomeworkSelected(file, fileList) {
		const result = getUploadFileCheckingResult(UPLOAD_TYPE_HOMEWORK, file)
		if (!result.isReady) {
			showMessage(result.error)
		} else {
			if (files.length < HOMEWORK_FILE_MAX_COUNT) {
				uploadHomework(file)
			}
		}
		return false;
	}

	function handleHomeworkCancelled(file) {
		handleUpdateHomework({ isCreate: false, uuid: file.uid })
	}

	function uploadHomework(homeworkFile) {
		if (!homeworkFile) {
			return
		}
		const onUploadSuccess = async (res) => {
			const data = convertHomeworkRequestData(student.resource.uuid, homeworkFile, res)
			handleUpdateHomework({ isCreate: true, data: data })
		}

		const onUploadProgress = () => { }
		const onUploadMd5Progress = () => { }
		const onUploadError = (err) => {
			dispatch(endAppLoading());
			console.log("homework upload error: ", err);
			showMessage(errorLang.student.upload_homework_fail)
			// todo: error tracking and handling
		}

		dispatch(startAppLoading());
		uploadFileWithSignedUrl(FILE_UPLOAD_BY_STUDENT, UPLOAD_TYPE_HOMEWORK, homeworkFile, onUploadSuccess, onUploadProgress, onUploadMd5Progress, onUploadError)
	}

	async function handleUpdateHomework(changes) {
		try {
			dispatch(startAppLoading());
			if (changes.isCreate) {
				dispatch(await submitHomework(changes.data));
			} else {
				dispatch(await deleteHomework(changes.uuid));
			}
			dispatch(endAppLoading());
		} catch (e) {
			dispatch(endAppLoading());
			showMessage(parseErrorFromCatch(e))
			trackerWithException("UPDATE HOMEWORK - " + e.message, false)
		}
	}

	async function onPlay() {
		// prevent calling action api when replay video
		if (values.play) return;

		setValue({
			...values,
			play: true,
		});
		dispatch(materialAction(student.resource.uuid, PLAY_MATERIAL, {}));
		trackerWithEvent(CLICK_PLAY_VIDEO);
	}


	if (!student.resource || !student.resource.uuid)
		return <div />

	if (student.resource.uuid !== pageId)
		return <div />

	return (
		<div style={{ marginBottom: 24 }}>
			<div>
				<Previewer {...student.resource} onPlay={onPlay} />
				<div style={styles.content}>
					<div style={styles.title}>{student.resource.title}</div>
					<div style={styles.description} dangerouslySetInnerHTML={{ __html: `${student.resource.description}` }} />
					{parseAttachments(student.resource.attachments)}
				</div>
			</div>
			{parseHomeworks(student.resource.homeworks)}
		</div>
	);
}
export default Resource;
