import { run } from '../utils/promise';
import { 
	getLocalApi, 
	getApi,
} from '../utils/api';

import {
	ACTION_ALL_RESOURCES,
	ACTION_VIEW_RESOURCE,
	ACTION_ALL_SCHOOLS,
	ACTION_ORG_TEACHERS,
	ACTION_ORG_STUDENTS,
	ACTION_ORG_GET_TEACHER,
	ACTION_ORG_GET_STUDENT,
	ACTION_ORG_POST_TEACHER,
	ACTION_ORG_POST_STUDENT,
	ACTION_ORG_PUT_TEACHER,
	ACTION_ORG_PUT_STUDENT,
} from '../constants/admin.constant';
import { getItemFromStorage } from './dom.action';

export const getAllResources = async () => {
	const response = await getLocalApi().get(`/api/resource.json`);

	return run(
		ACTION_ALL_RESOURCES,
		response.data,
	);
}

export const getResourceDetail = async (data) => {
	const response = {data: data};
	return run(
		ACTION_VIEW_RESOURCE,
		response,
	);
}

export const getAllSchools = async () => {
	const response = await getLocalApi().get(`/api/schools.json`);

	return run(
		ACTION_ALL_SCHOOLS,
		response.data,
	);
}

export const getOrgTeachers = async () => {
	const school_uuid = JSON.parse(getItemFromStorage('auth.school')).uuid;
	const response = await getApi(false).get(`/orgadmin/teachers?school_uuid=${school_uuid}`);
	return run(
		ACTION_ORG_TEACHERS,
		response.data,
	);
}

export const getOrgStudents = async () => {
	const school_uuid = JSON.parse(getItemFromStorage('auth.school')).uuid;
	const response = await getApi(false).get(`/orgadmin/students?school_uuid=${school_uuid}`);
	return run(
		ACTION_ORG_STUDENTS,
		response.data,
	);
}

export const getTeacherDetail = async (data) => {
	const response = {data: data};
	return run(
		ACTION_ORG_GET_TEACHER,
		response,
	);
}

export const getStudentDetail = async (data) => {
	const response = {data: data};
	return run(
		ACTION_ORG_GET_STUDENT,
		response,
	);
}

export const createTeacher = async (data) => {
	const response = await getApi().post(`/orgadmin/teacher`, data);
	return run(
		ACTION_ORG_POST_TEACHER,
		response.data,
	);
}

export const createStudent = async (data) => {
	const response = await getApi().post(`/orgadmin/student`, data);
	return run(
		ACTION_ORG_POST_STUDENT,
		response.data,
	);
}

export const updateTeacher = async (data) => {
	const response = await getApi().put(`/orgadmin/teacher`, data);
	return run(
		ACTION_ORG_PUT_TEACHER,
		response.data,
	);
}

export const updateStudent = async (data) => {
	const response = await getApi().put(`/orgadmin/student`, data);
	return run(
		ACTION_ORG_PUT_STUDENT,
		response.data,
	);
}

export default {
	getAllResources,
	getResourceDetail,
	getAllSchools,
	getOrgTeachers,
	getOrgStudents,
	getTeacherDetail,
	getStudentDetail,
	createTeacher,
	createStudent,
	updateTeacher,
	updateStudent,
};
