import PdfPreviewer from './Previewers/PdfPreviewer';
import VideoPlayer from './Previewers/VideoPlayer';
import OfficePreviewer from './Previewers/OfficePreviewer';
import ImagePreviewer from './Previewers/ImagePreviewer';

const videoType = [
    'video/mp4',
    'video/x-flv',
    'video/quicktime',
    'video/x-msvideo',
    'video/x-ms-wmv',
    'video/3gpp',
    'video/ogg',
    'video/webm',
];

const officeType = [
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

const pdfType = [
    'application/pdf',
];

const imageType = [
    'image/bmp',
    'image/gif',
    'image/jpeg',
    'image/png',
    'image/svg+xml',
    'image/tiff',
    'image/webp',
];

export default function FilePreviewer(props) {
    if (videoType.includes(props.type))
        return VideoPlayer(props);

    if (pdfType.includes(props.type))
        return PdfPreviewer(props);

    if (officeType.includes(props.type))
        return OfficePreviewer(props);

    if (imageType.includes(props.type))
        return ImagePreviewer(props);

    return null;
}