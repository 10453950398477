import {
	ACTION_ALL_RESOURCES,
	ACTION_VIEW_RESOURCE,
	ACTION_ALL_SCHOOLS,
	ACTION_ORG_TEACHERS,
	ACTION_ORG_STUDENTS,
	ACTION_ORG_GET_TEACHER,
	ACTION_ORG_GET_STUDENT,
} from '../constants/admin.constant';
import { storeItemToStorage } from '../actions/dom.action';
 
function adminReducer(state, action) {
	const payload = action.payload;
	switch (action.key) {
		case ACTION_ALL_RESOURCES:
			const resources = parseResources(payload.resources);
			storeItemToStorage('admin.resources', JSON.stringify(resources));
			return Object.assign({}, state, {
				...state,
				resources: resources
			});
		case ACTION_VIEW_RESOURCE:
			storeItemToStorage('admin.resource', JSON.stringify(payload));
			return Object.assign({}, state, {
				...state,
				resource: payload
			});
		case ACTION_ALL_SCHOOLS:
			const schools = parseSchools(payload.schools);
			storeItemToStorage('admin.schools', JSON.stringify(schools));
			return Object.assign({}, state, {
				...state,
				schools: schools
			});
		case ACTION_ORG_TEACHERS:
			const teachers = parseTeachers(payload);
			storeItemToStorage('admin.teachers', JSON.stringify(teachers));
			return Object.assign({}, state, {
				...state,
				teachers: teachers
			});
		case ACTION_ORG_STUDENTS:
			const students = parseStudents(payload);
			storeItemToStorage('admin.students', JSON.stringify(students));
			return Object.assign({}, state, {
				...state,
				students: students
			});
		case ACTION_ORG_GET_TEACHER:
			storeItemToStorage('admin.teacher', JSON.stringify(payload));
			return Object.assign({}, state, {
				...state,
				teacher: payload
			});
		case ACTION_ORG_GET_STUDENT:
			storeItemToStorage('admin.student', JSON.stringify(payload));
			return Object.assign({}, state, {
				...state,
				student: payload
			});
		default:
			return state;
	}
}

function parseResources(payload) {
	return payload.map((unit, index) => {
		return{
			uuid: unit.uuid,
			name: unit.name,
			type: unit.type,
			link: unit.src,
			content: unit.content,
			created_at: unit.created_at,
		};
	});
}

function parseSchools(payload) {
	return payload.map((unit, index) => {
		return{
			uuid: unit.uuid,
			name: unit.name,
			code: unit.code,
			teachers: unit.teachers,
		};
	});
}

function parseTeachers(payload) {
	return payload.map((unit, index) => {
		return{
			uuid: unit.uuid,
			username: unit.username,
			name: unit.name,
			is_active: unit.is_active,
		};
	});
}

function parseStudents(payload) {
	return payload.map((unit, index) => {
		return{
			uuid: unit.uuid,
			username: unit.username,
			name: unit.name,
			is_active: unit.is_active,
			activity: unit.activity,
		};
	});
}
export default adminReducer;
