import React from 'react';
import { Popconfirm, Typography } from "antd";
import { text, colors } from "../../../styles";
import { FILE_ITEM_TYPE_TEACHER_EDIT, FILE_ITEM_TYPE_VIEW, FILE_ITEM_TYPE_UPLOAD, FILE_ITEM_TYPE_HOMEWORK } from "../../../../constants/upload.constant";
import { cdn } from "../../../../utils/cdn"
import { downloadFileFromUrl, downloadFileFromLocal } from '../../../../actions/dom.action';
import { ReactComponent as PaperClip } from "../../../../assets/PaperClip.svg"
import { ReactComponent as Trash } from "../../../../assets/Trash.svg"
import { ReactComponent as CloudDownload } from "../../../../assets/CloudDownload.svg"
import { commonLang } from "../../../../languages/common.language"

export default function FileItem(props) {

    const { Paragraph } = Typography

    const downloadType = {
        remote: "remote",
        local: "local",
        notSupport: "notSupport",
    }

    let item = {
        key: props.uid,
        type: props.type !== undefined ? props.type : FILE_ITEM_TYPE_VIEW,
        name: props.name !== undefined ? props.name : null,
        file: props.file !== undefined ? props.file : null,
        file_id: props.file_id !== undefined ? props.file_id : null,
        file_path_global: props.file_path_global !== undefined ? props.file_path_global : null,
        error: props.error !== undefined ? props.error : null,
        isForceDownload: props.isForceDownload !== undefined ? props.isForceDownload : true,
        isDeleteAfterConfirm: props.isDeleteAfterConfirm !== undefined ? props.isDeleteAfterConfirm : false,
        deleteConfirmText: props.deleteConfirmText,
        popupDirection: props.popupDirection !== undefined ? props.popupDirection : "bottomRight",
        deleteAction: props.deleteAction !== undefined ? props.deleteAction : () => { },
        downloadUrl: null,
        mime_type: props.mime_type
    }

    item = { ...item, ...parseType() }

    const style = {
        fileContainer: {
            paddingBottom: 2,
            display: "flex",
            alignItems: "center",
        },
        icon: {
            marginRight: 8,
            color: item.error ? colors.danger2 : colors.gray2,
            height: 15,
        },
        filename: {
            flex: "1 1 auto",
            overflow: "hidden",
            display: "flex",
            marginRight: 4,
        },
        text: {
            ...text.body2tight,
            color: item.error ? colors.danger2 : item.clickableName ? colors.ndBlue : colors.gray2,
            cursor: item.clickableName ? "pointer" : "default",
            marginBottom: 0,
        },
        iconBtn: {
            cursor: "pointer",
            // display: "flex",
            height: 15,
        },
        error: {
            ...text.caption,
            color: colors.danger2,
            paddingBottom: 2,
        },
        cloud: {
            width: 16,
            height: 16,
            marginRight: 8,
            color: colors.gray2,
        },
        homeworkItem: {
            display: `flex`,
            alignItems: `center`,
        }
    }


    async function parseCDN(material) {
        const url = await cdn(material.file_id, material.file_path_global);
        item.downloadUrl = url
    }

    function parseType() {
        const remoteDownload = item.file_id && item.file_path_global
        if (remoteDownload) parseCDN(item)
        const localDownload = item.file !== null
        const download = remoteDownload ? downloadType.remote : localDownload ? downloadType.local : downloadType.notSupport
        const downloadButton = item.type === FILE_ITEM_TYPE_TEACHER_EDIT ? true : false
        const deleteButton = item.type === FILE_ITEM_TYPE_VIEW ? false : true
        const clickableName = downloadButton ? false : (remoteDownload || localDownload)
        return {
            downloadType: download,
            downloadButton: downloadButton,
            clickableName: clickableName,
            deleteButton: deleteButton
        }
    }


    // view related
    function getTrashColor() {
        if (item.type === FILE_ITEM_TYPE_TEACHER_EDIT || item.error) {
            return colors.danger2
        }
        return colors.gray2
    }

    function getTrashClassName() {
        if (item.error) {
            return "file-item-button"
        }
        return "file-item-hover-button"
    }

    function getFileItemClass() {
        if (item.type === FILE_ITEM_TYPE_UPLOAD) {
            return "file-item file-item-hover"

        }
        return "file-item"

    }

    // download related
    // support download remote file or local file (file selected from input)
    const downloadAction = () => {
        if (item.downloadType === downloadType.remote && item.downloadUrl) {
            downloadFileInRemote()
        } else if (item.downloadType === downloadType.local) {
            downloadFileInLocal()
        }
        if (props.onDownload) {
            props.onDownload();
        }
    }

    function downloadFileInRemote() {
        if (item.isForceDownload) {
            downloadFileFromUrl(item.downloadUrl, item.name, item.mime_type)
        } else {
            window.open(item.downloadUrl)
        }

    }

    function downloadFileInLocal() {
        downloadFileFromLocal(item.file)
    }

    function deleteButton() {
        if (item.deleteButton) {
            if (item.isDeleteAfterConfirm) {
                return (
                    // remark : known issue that 2 chinese chars will added a space between 2 chars (i.e. 取 消), added a space in the beginning can solve this problem
                    <Popconfirm
                        className={`file-item-popconfirm ${getTrashClassName()}`}
                        title={item.deleteConfirmText}
                        okText={` ${commonLang.delete}`}
                        cancelText={` ${commonLang.cancel}`}
                        placement={item.popupDirection}
                        onConfirm={item.deleteAction}>
                        <Trash style={{ ...style.iconBtn, color: getTrashColor() }}></Trash>
                    </Popconfirm>
                )
            } else {
                return <Trash className={getTrashClassName()} style={{ ...style.iconBtn, color: getTrashColor() }} onClick={item.deleteAction}></Trash>

            }
        } else {
            return null
        }
    }

    if (item.type === FILE_ITEM_TYPE_HOMEWORK) {
        return (
            <div onClick={downloadAction} style={style.homeworkItem}>
                <CloudDownload style={style.cloud} />
                {item.name}
            </div>
        )
    }

    return (
        <div key={item.key} className={getFileItemClass()} style={props.inlineCSS}>
            <div style={style.fileContainer}>
                <PaperClip style={style.icon} ></PaperClip>
                <div style={style.filename}>
                    <Paragraph style={style.text} onClick={item.downloadButton ? () => { } : downloadAction} ellipsis>
                        {item.name}
                    </Paragraph>
                </div >
                {item.downloadButton &&
                    <CloudDownload className="file-item-hover-button" style={{ ...style.iconBtn, marginRight: 25 }} onClick={downloadAction}></CloudDownload>
                }
                {deleteButton()}
            </div>
            {item.error &&
                <div style={style.error} >{item.error}</div>
            }
        </div>
    )
}