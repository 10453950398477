import React from 'react';
import { Row, Col } from 'antd';
import { text, colors } from '../../../styles';
import { formatDate } from '../../../../actions/app.action';

function ItemTitle(props) {
  const styles = {
    title: {
      ...text.body2loosen,
      color: colors.gray2,
      padding: `8px 24px 16px`,

    }
  }

  return (
    <Row style={styles.item}>
      <Col xs={24} sm={12}>
        <div style={styles.title}>{formatDate(props.date)}</div>
      </Col>
    </Row>
  );
}
export default ItemTitle;