export const SUBJECT_LIST = [
	{
		key: "ChineseEdu",
		value: "ChineseEdu",
		title: "中國語文教育",
		children: [
			{
				key: "Chinese",
				value: "Chinese",
				title: "中國語文"
			},
			{
				key: "Clit",
				value: "Clit",
				title: "中國文學"
			},
			{
				key: "Putonghua",
				value: "Putonghua",
				title: "普通話"
			}
		]
	},
	{
		key: "EnglishEdu",
		value: "EnglishEdu",
		title: "英國語文教育",
		children: [
			{
				key: "English",
				value: "English",
				title: "英國語文"
			},
			{
				key: "Elit",
				value: "Elit",
				title: "英語文學"
			}
		]
	},
	{
		key: "MathEdu",
		value: "MathEdu",
		title: "數學教育",
		children: [
			{
				key: "Mathematics",
				value: "Mathematics",
				title: "數學教育"
			}
		]
	},
	{
		key: "ScienceEdu",
		value: "ScienceEdu",
		title: "科學教育",
		children: [
			{
				key: "GeneralStudies",
				value: "GeneralStudies",
				title: "常識科"
			},
			{
				key: "Science",
				value: "Science",
				title: "科學"
			},
			{
				key: "Biology",
				value: "Biology",
				title: "生物"
			},
			{
				key: "Chemistry",
				value: "Chemistry",
				title: "化學"
			},
			{
				key: "Physics",
				value: "Physics",
				title: "物理"
			},
			{
				key: "Scicom",
				value: "Scicom",
				title: "科學 (綜合模式，組合模式)"
			}
		]
	},
	{
		key: "TechEdu",
		value: "TechEdu",
		title: "科技教育",
		children: [
			{
				key: "TEKLAC",
				value: "TEKLAC",
				title: "科技教育學習領域課程"
			},
			{
				key: "BAFS",
				value: "BAFS",
				title: "企業、會計與財務概論"
			},
			{
				key: "DAT",
				value: "DAT",
				title: "設計與應用科技"
			},
			{
				key: "HMSC",
				value: "HMSC",
				title: "健康管理與社會關懷"
			},
			{
				key: "ICT",
				value: "ICT",
				title: "資訊及通訊科技"
			},
			{
				key: "TL",
				value: "TL",
				title: "科技與生活"
			}
		]
	},
	{
		key: "HumanitiesEdu",
		value: "HumanitiesEdu",
		title: "個人、社會及人文教育",
		children: [
			{
				key: "Chist",
				value: "Chist",
				title: "中國歷史"
			},
			{
				key: "Geography",
				value: "Geography",
				title: "地理"
			},
			{
				key: "History",
				value: "History",
				title: "歷史"
			},
			{
				key: "LifeSociety",
				value: "LifeSociety",
				title: "生活與社會"
			},
			{
				key: "ReligiousEdu",
				value: "ReligiousEdu",
				title: "宗教教育"
			},
			{
				key: "Economics",
				value: "Economics",
				title: "經濟"
			},
			{
				key: "ER",
				value: "ER",
				title: "倫理與宗教"
			},
			{
				key: "THS",
				value: "THS",
				title: "旅遊與款待"
			}
		]
	},
	{
		key: "ArtsEdu",
		value: "ArtsEdu",
		title: "藝術教育",
		children: [
			{
				key: "Music",
				value: "Music",
				title: "音樂"
			},
			{
				key: "VA",
				value: "VA",
				title: "視覺藝術"
			}
		]
	},
	{
		key: "PE",
		value: "PE",
		title: "體育",
	},
	{
		key: "LS",
		value: "LS",
		title: "通識教育科",
	}
];