import React from 'react';
import { Layout, Progress } from 'antd';

import { ContextStore } from '../../store/ContextStore';
import { ROUTE_STUDENT_RESOURCE } from '../../constants/app.constant';
import { startAppLoading, endAppLoading, formatDuration } from '../../actions/dom.action';
import { getResourceDetail } from '../../actions/student.action';
import { ReactComponent as CheckSvg } from '../../assets/circle-check.svg';
import { ReactComponent as ClockSvg } from '../../assets/Clock.svg';
import { commonLang } from '../../languages/common.language';
import { text, colors, shadows } from '../styles';
import { formatDate } from '../../actions/app.action';

import moment from 'moment'
import { studentLang } from '../../languages/student.language';

function StudentSider(props) {
  const { dispatch, student } = React.useContext(ContextStore);

  const styles = {
    root: {
      boxShadow: shadows.elevation3,
      color: colors.gray1,
    },
    header: {
      display: `flex`,
      justifyContent: `space-between`,
      // marginBottom: `24px`,
      padding: `0 24px 24px`,
    },
    item: {
      minHeight: `72px`,
      padding: `15px 18px 15px 25px`,
      position: `relative`,
    },
    subtitle: {
      ...text.body2loose,
      color: colors.gray2
    },
    date: {
      ...text.subtitle,
      color: colors.gray1,
    },
    title: {
      ...text.body2loose,
      color: colors.gray1,
      overflowWrap: `break-word`,
      paddingRight: `32px`, // for completed mark
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
    complete: {
      position: `absolute`,
      right: `18px`,
      top: `16px`,
    },
    itemsContainer: {
      maxHeight: `calc( 100vh - ${64 + 64 + 48}px)`,
      overflow: "scroll",
    },
    info: {
      ...text.caption,
      color: colors.gray3,
      marginTop: 2,
      display: "flex"
    }
  }

  async function viewResource(resource) {
    dispatch(startAppLoading());
    props.history.push(ROUTE_STUDENT_RESOURCE.replace(/:id/g, resource.uuid));
    dispatch(await getResourceDetail(resource));
    dispatch(endAppLoading());
  }

  const filteredResources = student.resources.filter(function (each) {
    return moment(each.start_at).isSame(student.resource.start_at, 'day')
  })

  let readCount = 0;
  let durationCount = 0;

  const resources = filteredResources.map((unit, index) => {
    let itemProps;
    if (unit.uuid === student.resource.uuid) {
      itemProps = {
        style: { ...styles.item, backgroundColor: colors.gray6 }
      };
    }
    else {
      itemProps = {
        style: { ...styles.item, backgroundColor: colors.white, cursor: `pointer` },
        onClick: () => viewResource(unit),
      };
    }

    if (unit.is_read) readCount++
    if (unit.duration) durationCount += Math.ceil(unit.duration / 60)

    if (index >= filteredResources.length - 1) {
      itemProps.style = { ...itemProps.style, marginBottm: 24 }
    }
    return (
      <div key={unit.uuid} {...itemProps}>
        <div style={styles.title}>{unit.title}</div>
        <div style={styles.info}>
          {unit.subject && <div style={{ marginRight: 16 }}>{unit.subject}</div>}
          {/* hide duration related ui */}
          {/* {unit.duration && <div style={{ display: "flex", alignItems: "center" }}><ClockSvg style={{ marginRight: 4, width: 12, height: 12 }} /> {formatDuration(unit.duration)}</div>} */}
        </div>
        {
          unit.is_read && <CheckSvg style={styles.complete} />
        }
      </div>
    );
  });

  const progress = () => {
    const precentage = parseInt(readCount / filteredResources.length * 100)

    if (precentage === 100) {
      return <img alt="homework" src="/images/daily_completed.png" style={{ width: 65, height: 65 }} />
    } else {
      return <Progress type="circle" percent={precentage} width={64} />
    }
  }

  return (
    <Layout.Sider
      breakpoint="lg"
      width={336}
      collapsedWidth={0}
      style={styles.root}
    >
      <div style={{ padding: `24px 0` }}>
        <div style={styles.header}>
          <div>
            <div style={styles.subtitle}>{commonLang.learning_journal}</div>
            <div style={styles.date}>{formatDate(student.resource.start_at)}</div>
            {/* hide duration related ui */}
            {/* {durationCount !== 0 &&
              <div>{studentLang.formatString(studentLang.today_duration, formatDuration(durationCount * 60))}</div>
            } */}
          </div>
          {progress()}
        </div>
        <div style={styles.itemsContainer}>
          {resources}
        </div>
      </div>
    </Layout.Sider>
  );
}
export default StudentSider;
