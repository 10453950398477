import React from 'react';
import { cdn } from '../../../../utils/cdn';

export default function ImagePreviewer(props) {
    const style = {
        imageContainer: {
            width: "100%"
        },
        image: {
            width: "100%"
        }
    }

    const [values, setValue] = React.useState({
        url: null,
    });

    async function parseCDN(material) {
        let path = await cdn(material.file_id, material.file_path_global)
        setValue({ ...values, url: path })
    }

    if (values.url === null) parseCDN(props);

    return (
        <div key={props.uuid} style={style.imageContainer}>
            {
                values.url && 
                <img alt="file" style={style.image} src={values.url}></img>
            }
        </div>
    )
}
