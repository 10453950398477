import axios from 'axios';
import {
	CDN_CACHE_REFRESH,
	CND_TYPE_ND_GCDNCS,
	CND_TYPE_ND_CDNCS,
	CND_TYPE_AWS_S3,
	CND_ALL_TYPES,
	CDN_ALL_LOAD_TEST_PATHS,
	CDN_PREFIX_PATHS,
} from '../constants/app.constant';

export const parseCdnInfo = (path) => {
	const name = Object.keys(CDN_PREFIX_PATHS).filter(function(key) { return path.indexOf(CDN_PREFIX_PATHS[key])===0; })[0];
	const index = CND_ALL_TYPES.indexOf(name) + 1;
	return { name, index};
}

export const cdn = (ndsdpDentryId='', awsS3RelativePath='', callbackFunc=null) => {
	return new Promise(async (resolve) => {
		const types = await getCdnType();
		// console.log('CDN priority:', types);
		for(var i=0; i<types.length; i++){
			switch(types[i]){
				case CND_TYPE_ND_GCDNCS:
				case CND_TYPE_ND_CDNCS:
					if(ndsdpDentryId){
						const path1 = CDN_PREFIX_PATHS[types[i]]+ndsdpDentryId;
						const flag1A = await testPath(path1);
						if(flag1A){
							if(callbackFunc) callbackFunc(ndsdpDentryId, awsS3RelativePath, path1);
							return resolveIt(resolve, path1);
						}
					}
				break;
				default:
				case CND_TYPE_AWS_S3:
					if(awsS3RelativePath){
						const path2 = CDN_PREFIX_PATHS[CND_TYPE_AWS_S3]+awsS3RelativePath;
						const flag2A = await testPath(path2);
						if(flag2A){
							if(callbackFunc) callbackFunc(ndsdpDentryId, awsS3RelativePath, path2);
							return resolveIt(resolve, path2);
						}
					}
				break;
			}
		}
		const path3 = CDN_PREFIX_PATHS[CND_TYPE_ND_CDNCS]+ndsdpDentryId;
		if(callbackFunc) callbackFunc(ndsdpDentryId, awsS3RelativePath, path3);
		resolveIt(resolve, path3);
	});
};

let cdnTypesArr = null; // the first record is the fastest
const getCdnType = (useCache = true) => {
	return cdnTypesArr && useCache
		? cdnTypesArr
		: speedTest();
}
const speedTest = async () => {
	const data = {};
	for(var key in CDN_ALL_LOAD_TEST_PATHS)
		data[key] = await testCDN(CDN_ALL_LOAD_TEST_PATHS[key]);
	cdnTypesArr = Object.keys(data).sort(function(a,b){return data[a]-data[b]});
	setTimeout(function(){ cdnTypesArr = null }, CDN_CACHE_REFRESH);
	return cdnTypesArr;
}
const testCDN = async (path) => { // <----- load our default file to test CDN speed
	const sTime = (new Date()).valueOf();
	const timeout = 1000;
	try {
		await axios.get(path+'rnd='+Math.random(), { timeout: timeout });
	} catch (error) {
		return Number.MAX_SAFE_INTEGER;
	}
	const eTime = (new Date()).valueOf();
	return eTime-sTime;
}
const testPath = async (path) => { // <----- prevent database passing invalid url, so test the url in real-time
	return new Promise(async (resolve) => {
		const timeout = 1000;
		try {
			await axios({
				method: 'get',
				url: path, 
		        onDownloadProgress: (progressEvent) => {
		        	if(progressEvent.loaded>0) return resolve(true);
		        },
		        timeout: timeout,
			});
		} catch (error) {
			return resolve(false);
		}
	});
}
const resolveIt = async (resolve, path) => {
	// console.log('CDN path', path);
	resolve(path);
	return true;
}
