// <----- actions
export const ACTION_ALL_RESOURCES = 'action_superadmin_resources';
export const ACTION_VIEW_RESOURCE = 'action_superadmin_resource';
export const ACTION_ALL_SCHOOLS = 'action_superadmin_schools';

export const ACTION_ORG_TEACHERS = 'action_org_teachers';
export const ACTION_ORG_STUDENTS = 'action_org_students';
export const ACTION_ORG_GET_TEACHER = 'action_org_get_teacher';
export const ACTION_ORG_GET_STUDENT = 'action_org_get_student';
export const ACTION_ORG_POST_TEACHER = 'action_org_post_teacher';
export const ACTION_ORG_POST_STUDENT = 'action_org_post_student';
export const ACTION_ORG_PUT_TEACHER = 'action_org_put_teacher';
export const ACTION_ORG_PUT_STUDENT = 'action_org_put_student';