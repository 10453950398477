import { PROMISE_RUN } from '../constants/promise.constant';
import { E_INVALID_JWT_TOKEN, DISABLED_USER_TOKEN } from '../constants/auth.constant';
import { logout } from '../reducers/rules/auth.rules';
import { ROUTE_HOME } from '../constants/app.constant';
import { showMessage } from '../actions/dom.action';
import { updateApiVersion } from '../actions/app.action';
import { parseErrorFromAPI } from '../languages/error.language';

export const run = async (key, payload, options = { skipLoadingState: false }) => {
	if(payload.error && payload.error.message){
		switch(payload.error.status){
			case E_INVALID_JWT_TOKEN:
			case DISABLED_USER_TOKEN:
				logout();
				window.location = ROUTE_HOME;
				break;
			default: 
				showMessage(parseErrorFromAPI(payload.error));
				break;
		}
		return {}
	}

	// <----- cache api version
	updateApiVersion(key, payload.version);

	return {
		type: PROMISE_RUN,
		key: key,
		payload: await payload.data,
		...options
	}
};

export default {
  run
};
