import React, { useEffect } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import qs from 'query-string';
import ReactGA from 'react-ga';

import './App.css';
import {
	defaultApp,
	defaultDom,
	defaultAuth,
	defaultAdmin,
	defaultTeacher,
	defaultStudent,
	ContextStore
} from '../store/ContextStore';
import appReducer from "../reducers/app.reducer";
import domReducer from "../reducers/dom.reducer";
import authReducer from "../reducers/auth.reducer";
import adminReducer from "../reducers/admin.reducer";
import teacherReducer from "../reducers/teacher.reducer";
import studentReducer from "../reducers/student.reducer";
import { E_INVALID_JWT_TOKEN } from '../constants/auth.constant';
import { message } from 'antd';
import { errorLang } from '../languages/error.language';
import { addHotjarIdentity } from '../actions/hotjar.action';
import { isAuthorized } from '../reducers/rules/auth.rules';

import {
	GOOGLE_ANALYTICS,
	ROUTE_HOME,
	ROUTE_LOGIN,
	ROUTE_SUPERADMIN,
	ROUTE_ORGADMIN,
	ROUTE_TEACHER,
	ROUTE_STUDENT,
	APP_PLATFORM,
	SYSTEM_PLATFORM_CENTRE,
} from '../constants/app.constant';

import Teacher from './Teacher';
import Student from './Student';
import Superadmin from './Superadmin';
import OrgAdmin from './OrgAdmin';
import Public from './Public';
import Loading from "./Loading";
import SystemMessage from './Common/SystemMessage';

import { checkVersion } from '../actions/app.action';

if(GOOGLE_ANALYTICS) ReactGA.initialize(GOOGLE_ANALYTICS);
checkVersion();

function App() {
	const [appState, appDispatch] = React.useReducer(appReducer, defaultApp);
	const [domState, domDispatch] = React.useReducer(domReducer, defaultDom);
	const [authState, authDispatch] = React.useReducer(authReducer, defaultAuth);
	const [adminState, adminDispatch] = React.useReducer(adminReducer, defaultAdmin);
	const [teacherState, teacherDispatch] = React.useReducer(teacherReducer, defaultTeacher);
	const [studentState, studentDispatch] = React.useReducer(studentReducer, defaultStudent);

	const pageStyle = {
		backgroundColor: APP_PLATFORM===SYSTEM_PLATFORM_CENTRE ? `#F7FBFD` : `#FCFCFC`,
		backgroundImage: APP_PLATFORM===SYSTEM_PLATFORM_CENTRE 
			? `url("/images/tuition_bg.png")` 
			: `url("/images/bg.png"), linear-gradient(180deg, #fcfcfc 7.67%, #f6f6f6 39.33%)`,
		backgroundRepeat: `no-repeat`,
		backgroundPosition: `bottom`,
		backgroundAttachment: `fixed`,
		backgroundSize: `100vw`,
	}

	const isAuthorizedFlag = isAuthorized(authState);
	
	function combineDispatchs(dispatchs) {
		return function(obj){
			for (let i = 0; i < dispatchs.length; i++)
			dispatchs[i](obj);
		}
	}

	function parseMessage(){
		if(!(window.location.hash)) return null;
		const parameters = qs.parse(window.location.hash);
		window.location.hash = '';
		switch(parameters.message){
			case E_INVALID_JWT_TOKEN:
				return message.error(errorLang.response[parameters.message]);
			default:
				return null;
		}
	}

	function parseAuthRoutes() {
		if(APP_PLATFORM===SYSTEM_PLATFORM_CENTRE){
			let role;
			switch(authState.role){
				case "student":
					role = Student;
					break;
				case "teacher":
					role = Teacher;
					break;
				case "orgadmin":
					role = OrgAdmin;
					break;
				case "superadmin":
					role = Superadmin;
					break;
				default:
					break;
			}

			return(
				<Switch>
					<Route path={ROUTE_SUPERADMIN} component={Superadmin} />
					<Route path={ROUTE_ORGADMIN} component={OrgAdmin} />
					<Route path={ROUTE_TEACHER} component={Teacher} />
					<Route path={ROUTE_STUDENT} component={Student} />
					<Route path={ROUTE_LOGIN}>
						<Redirect to={ROUTE_HOME} />
					</Route>
					<Route path={ROUTE_HOME} component={role} />
				</Switch>
			);
		}

		return(
			<Switch>
				<Route path={ROUTE_SUPERADMIN} component={Superadmin} />
				<Route path={ROUTE_TEACHER} component={Teacher} />
				<Route path={ROUTE_STUDENT} component={Student} />
				<Route path={ROUTE_HOME} component={authState.role==="student" ? Student : Teacher} />
			</Switch>
		);
	}

	function showSystemMessage(flag){
		if(flag) return <SystemMessage />
		return;
	}

	useEffect(() => {

	});
	
	parseMessage();
	addHotjarIdentity(authState.userDisplayName);

	return (
		<ContextStore.Provider
			value={{
				app: appState,
				dom: domState,
				auth: authState,
				admin: adminState,
				teacher: teacherState,
				student: studentState,
				dispatch: combineDispatchs([
					appDispatch, 
					domDispatch, 
					authDispatch, 
					adminDispatch, 
					teacherDispatch, 
					studentDispatch,
				]),
			}}
		>
			<Loading/>
			{ showSystemMessage(isAuthorizedFlag) }
			<BrowserRouter basename="" forceRefresh={false}>
				<div style={pageStyle}>
				<Switch>
					{
						isAuthorizedFlag
						? parseAuthRoutes() 
						: <Route path={ROUTE_HOME} component={Public} />
					}
				</Switch>
				</div>
			</BrowserRouter>
		</ContextStore.Provider>
	);
}
export default App;
