import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Layout } from 'antd';

import { Z_INDEX_MAIN } from '../constants/dom.constant';
import { publicLang } from '../languages/public.language';

import {
  ROUTE_HOME,
  ROUTE_TEACHER_LOGIN,
  ROUTE_STUDENT,
  ROUTE_STUDENT_LOGIN,
  ROUTE_SUPERADMIN,
  APP_PLATFORM,
  SYSTEM_PLATFORM_CENTRE,
} from '../constants/app.constant';
import MainHeader from './Common/MainHeader';
import MainFooter from './Common/MainFooter';
import Button from './Common/Button';
import TeacherLogin from './Teacher/Login';
import StudentLogin from './Student/Login';
import SuperadminLogin from './Superadmin/Login';
import OrgadminLogin from './OrgAdmin/Login';
import { scrollToTop } from '../actions/dom.action';

const { Content } = Layout;

function Public(props) {
  function toHome() {
    props.history.push(ROUTE_HOME);
  }

  useEffect(() => {
    const unlisten = props.history.listen((location, action) => {
      scrollToTop()
    });
    
    return () => {
      unlisten()
    }
  }, [props.history]);

  if(APP_PLATFORM===SYSTEM_PLATFORM_CENTRE){
    return (
      <Layout style={{minHeight: `100vh`}}>
        <MainHeader 
          history={props.history} 
          pathname={props.history.location.pathname} 
          home={toHome}
        />
        <Layout>
          <Content style={{ marginTop: `64px`, padding: `24px 32px`, zIndex: Z_INDEX_MAIN }}>
            <div className="list-container vertical-align-page-content">
              <Switch>
                <Route path={ROUTE_SUPERADMIN} component={SuperadminLogin} />
                <Route path={ROUTE_HOME} component={OrgadminLogin} />
              </Switch>
            </div>
          </Content>
          <MainFooter />
        </Layout>
      </Layout>
    );
  }

  return (
  	<Layout style={{minHeight: `100vh`}}>
      <MainHeader 
        history={props.history} 
        pathname={props.history.location.pathname} 
        home={toHome}
        rightMenu={
          <div>
            <Button type="secondary" inlinestyle={{ marginRight: `16px` }} onClick={() => props.history.push(ROUTE_TEACHER_LOGIN)}>
              {publicLang.teacher_login}
            </Button>
            <Button type="primary" onClick={() => props.history.push(ROUTE_STUDENT_LOGIN)}>
              {publicLang.student_login}
            </Button>
          </div>
        }
      />
      <Layout>
        <Content style={{ marginTop: `64px`, padding: `24px 32px`, zIndex: Z_INDEX_MAIN }}>
          <div className="list-container vertical-align-page-content">
            <Switch>
              <Route path={ROUTE_STUDENT} component={StudentLogin} />
              <Route path={ROUTE_SUPERADMIN} component={SuperadminLogin} />
              <Route path={ROUTE_HOME} component={TeacherLogin} />
            </Switch>
          </div>
        </Content>
        <MainFooter />
      </Layout>
    </Layout>
  );
}
export default Public;
