
// <----- can't access lexical declaration before initialization in ReactJs 16.10.1, so define duplicate constants in here
const PLATFORM_DESKTOP = 0;
const PLATFORM_IOS = 1;
const PLATFORM_ANDROID = 2;
const PLATFORM_WINDOWS_PHONE = 3;

export const getBrowser = () => {
  var ua = navigator.userAgent;
  var browserName  = navigator.appName;
  var fullVersion  = ''+parseFloat(navigator.appVersion); 
  var majorVersion = parseInt(navigator.appVersion,10);
  var nameOffset,verOffset,ix;

  if ((verOffset=ua.indexOf("Opera"))!==-1) {
   browserName = "Opera";
   fullVersion = ua.substring(verOffset+6);
   if ((verOffset=ua.indexOf("Version"))!==-1) 
     fullVersion = ua.substring(verOffset+8);
  }
  else if ((verOffset=ua.indexOf("MSIE"))!==-1) {
   browserName = "Microsoft Internet Explorer";
   fullVersion = ua.substring(verOffset+5);
  }
  else if ((verOffset=ua.indexOf("Chrome"))!==-1) {
   browserName = "Chrome";
   fullVersion = ua.substring(verOffset+7);
  }
  else if ((verOffset=ua.indexOf("Safari"))!==-1) {
   browserName = "Safari";
   fullVersion = ua.substring(verOffset+7);
   if ((verOffset=ua.indexOf("Version"))!==-1) 
     fullVersion = ua.substring(verOffset+8);
  }
  else if ((verOffset=ua.indexOf("Firefox"))!==-1) {
   browserName = "Firefox";
   fullVersion = ua.substring(verOffset+8);
  }
  else if ((nameOffset=ua.lastIndexOf(' ')+1) < (verOffset=ua.lastIndexOf('/')) ) {
   browserName = ua.substring(nameOffset,verOffset);
   fullVersion = ua.substring(verOffset+1);
   if (browserName.toLowerCase()===browserName.toUpperCase()) {
    browserName = navigator.appName;
   }
  }
  // trim the fullVersion string at semicolon/space if present
  if ((ix=fullVersion.indexOf(";"))!==-1)
     fullVersion=fullVersion.substring(0,ix);
  if ((ix=fullVersion.indexOf(" "))!==-1)
     fullVersion=fullVersion.substring(0,ix);

  majorVersion = parseInt(''+fullVersion,10);
  if (isNaN(majorVersion)) {
   fullVersion  = ''+parseFloat(navigator.appVersion);
   majorVersion = parseInt(navigator.appVersion,10);
  }

  return browserName + ' ' + fullVersion;
};

export const getAppPlatformCode = (userAgent) => {
  if (/windows phone/i.test(userAgent)) {
    return PLATFORM_WINDOWS_PHONE;
  }
  if (/android/i.test(userAgent)) {
    return PLATFORM_ANDROID;
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return PLATFORM_IOS;
  }
  return PLATFORM_DESKTOP;
};

export const disableScalableOnIOS = () => { // <----- don't run this function more than once
  document.addEventListener('touchstart', (event) => {
    if (event.touches.length > 1) {
       event.preventDefault();
    }
  }, { passive: false });
  
  let lastTouchEnd = 0;
  document.addEventListener('touchend', (event) => {
    const now = (new Date()).getTime();
    if (now - lastTouchEnd <= 300) {
      event.preventDefault();
    }
    lastTouchEnd = now;
  }, false);
}

export const parseDomain = (url) => {
  return url.replace('http://','').replace('https://','').split(/[/?#]/)[0];
}

export const parseDate = (start) => {
  const now = Date.now();
  const duration = now - start;
  const offset = (new Date()).getTimezoneOffset() * 60000; // offset in milliseconds
  const attended_at = new Date(start - offset).toISOString().slice(0, 19).replace('T', ' ');
  const submitted_at = new Date(now - offset).toISOString().slice(0, 19).replace('T', ' ');
  return {
    duration: duration,
    attended_at: attended_at,
    submitted_at: submitted_at,
  }
}
