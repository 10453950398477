import LocalizedStrings from 'react-localization';

export const publicLang = new LocalizedStrings({
	'zh-hk': {
		// login
		"login": "登入",
		"student_login": "學生登入",
		"teacher_login": "教師登入",
		"admin_login": "管理員登入",
		"center_login": "登入平台",
		"school_code": "學校代碼",
		"school_usernname": "學校帳號",
		"teacher_name": "教師名稱 (e.g. 陳大文老師, Ms. Chan)",
		"class_name": "班別 (e.g. 1A)",
		"class_number": "班號 (e.g. 01)",
		"username": "用戶名稱",
		"password": "密碼",
		"remember_me": "記住我",
		"agreement": "登入本平台代表你同意我們的{0}",
		"tnc": "私隱權及條款",
		"suggested_platforms": "建議使用最新 Chrome、Firefox 或 Safari 瀏覽器，以獲得最佳使用體驗。",
		// footer
		"footer": "Copyright © NetDragon Websoft Holdings. All Rights Reserved.",
	},
});

export default { publicLang };
