// <----- actions
export const ACTION_ALL_RESOURCES = 'action_student_resources';
export const ACTION_VIEW_RESOURCE = 'action_student_resource';
export const ACTION_GET_TOKEN_FOR_UPLOAD = 'action__student_get_token_for_upload';
export const ACTION_SUBMIT_HOMEWORK = 'action_student_submit_homework';
export const ACTION_DELETE_HOMEWORK = 'action_student_delete_homework';
export const ACTION_MATERIAL_ACTION = 'action_material_action';

// <----- material actions
export const PLAY_MATERIAL = 'PLAY_MATERIAL';
export const DOWNLOAD_ATTACHMENT = 'DOWNLOAD_ATTACHMENT';