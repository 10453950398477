import React, { useEffect } from 'react';
import { Row, Col, Input } from 'antd';
import moment from 'moment';

import { commonLang } from '../../../languages/common.language';
import { studentLang } from '../../../languages/student.language';
import { ROUTE_STUDENT_RESOURCE } from '../../../constants/app.constant';
import { S_STUDENT } from '../../../constants/tracker.constant';
import { RESOURCE_SEARCH } from '../../../constants/dom.constant';
import { startAppLoading, endAppLoading, filterObject } from '../../../actions/dom.action';
import { getAllResources, getResourceDetail } from '../../../actions/student.action';
import { trackerWithPageView } from '../../../actions/app.action';
import { ContextStore } from '../../../store/ContextStore';
import { text, colors, shadows } from '../../styles';
import Button from '../../Common/Button';
import ItemTitle from "./List/ItemTitle";
import Item from "./List/Item";

function Resources(props) {
	const { dispatch, student, auth } = React.useContext(ContextStore);
	const topDom = React.createRef();
	const todayDom = React.createRef();
	const [values, setValue] = React.useState({
		init: false,
		search: "",
	});

	const resourceItemContainerHeight = 64 + 24 + 56 + 57 + 69;
	const styles = {
		root: {
			backgroundColor: colors.white,
			boxShadow: shadows.elevation5,
		},

		header: {
			padding: `4px 0 20px`,
			backgroundColor: colors.transparent,
			display: `flex`,
			flexDirection: `row`,
		},
		title: {
			...text.h5,
			color: colors.gray1,
		},
		school: {
			...text.body1,
			color: colors.gray3,
		},
		listHeader: {
			...text.body2bold,
			color: colors.gray1,
			padding: `8px 24px`,
			borderBottom: `1px solid ${colors.gray5}`,
		},
		dateItem: {
			paddingTop: 0, // handle scroll to position (64 = topnav height)
			marginTop: 0, // handle scroll to position (64 = topnav height)
		},
		resourceItemContainer: {
			maxHeight: `calc( 100vh - ${resourceItemContainerHeight}px)`,
			overflow: `scroll`,
			marginBottom: -24
		}
	}

	async function viewResource(resource) {
		dispatch(startAppLoading());
		dispatch(await getResourceDetail(resource));
		props.history.push(ROUTE_STUDENT_RESOURCE.replace(/:id/g, resource.uuid));
		dispatch(endAppLoading());
	}

	async function getResources() {
		setValue({ init: true });
		dispatch(startAppLoading());
		trackerWithPageView(S_STUDENT);
		dispatch(await getAllResources());
		dispatch(endAppLoading());
	}

	function handleTop() {
		if (!topDom.current) return;
		topDom.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
	}

	function handleSearch(e) {
		setValue({
			...values,
			search: e.target.value,
		});
	}

	useEffect(() => {
		if (!values.init && auth.role === "student") {
			getResources();
		}
	})

	let resources = []
	const today = moment().format("YYYY-MM-DD");
	const resourceGroups = student.resources
		.sort((a, b) => moment(b.start_at).format('X') - moment(a.start_at).format('X'))
		.reduce((acc, resource) => {
			const date = moment(resource.start_at).format("YYYY-MM-DD")
			acc[date] = (acc[date] || []).concat(resource);
			return acc;
		}, {});

	let latest = true
	const refParse = (ref, date) => {
		if (latest) {
			topDom.current = ref
			latest = false
		}
		if (today === date) todayDom.current = ref
	}
	for (let date in resourceGroups) {
		// <----- search and filter
		if (values.search)
			resourceGroups[date] = resourceGroups[date].filter((each) => filterObject(each, values.search.toLowerCase(), RESOURCE_SEARCH));
		if (resourceGroups[date].length === 0)
			continue;

		// <----- parse list item
		const dateItem = <div key={date} style={styles.dateItem} ref={(ref) => { refParse(ref, date) }}><ItemTitle date={date} /></div>;
		const units = resourceGroups[date].map(
			(unit, index) => <Item key={unit.uuid} unit={unit} viewResource={viewResource} />
		);
		resources = resources.concat(dateItem).concat(units)
	}

	if (resources.length === 0) {
		const emptyDiv = {
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			alignItems: "center",
			height: `calc( 100vh - ${resourceItemContainerHeight}px)`,
		}
		resources = (
			<div style={emptyDiv}>
				<img alt="empty" src="/images/resources_empty.png" style={{width: 270, height: 150}} />
				<div style={{ marginTop: 8 }}>{commonLang.empty_resource}</div>
			</div>
		)
	}


	return (
		<div>
			<div style={styles.header}>
				<div className="resource-list-header">
					<span style={styles.title}>{commonLang.learning_journal}</span>
					<span className="resource-list-header-school" style={styles.school}>{auth.school.name}</span>
				</div>
				<div className="resource-list-filter">
					{/* <Button type="filter" inlinestyle={{ outline: `none` }} onClick={handleToday}>{commonLang.today}</Button> */}
					<Button type="filter" inlinestyle={{ marginLeft: 8, outline: `none` }} onClick={handleTop}>{commonLang.recent}</Button>
				</div>
			</div>
			<div style={styles.root}>
				<Row style={styles.listHeader} type="flex" align="middle">
					{/* hide duration related ui 13 4 4 3 */}
					<Col xs={24} sm={15} className="list-search" style={{ display: `flex` }}>
						<Input.Search
							placeholder={commonLang.search_resources}
							onChange={handleSearch}
							value={values.search}
						/>
					</Col>
					<Col xs={0} sm={6}>{commonLang.subject}</Col>
					{/* <Col xs={0} sm={4}>{studentLang.video_duration}</Col> */}
					<Col xs={0} sm={3}>{studentLang.complete_status}</Col>
				</Row>
				<div style={styles.resourceItemContainer}>
					{resources}
				</div>
			</div>
		</div>
	);
}
export default Resources;
