import React from 'react';
import { Col, Dropdown, Menu, Row, Typography } from 'antd';
import moment from 'moment'

import { teacherLang } from '../../../../languages/teacher.language';
import { ReactComponent as InactiveSvg } from '../../../../assets/red-dot.svg';
import { ReactComponent as ActiveSvg } from '../../../../assets/green-dot.svg';
import { ReactComponent as ExpiredSvg } from '../../../../assets/grey-dot.svg';
import { ReactComponent as MoreSvg } from '../../../../assets/more-vertical.svg';
import { ReactComponent as VideoSvg } from '../../../../assets/video-color.svg';
import { ReactComponent as ShareSvg } from '../../../../assets/share.svg';
import { text, colors } from '../../../styles';

const { Paragraph } = Typography;

function Item(props) {
  const styles = {
    item: {
      padding: `0 24px 32px`,
    },
    itemContainer: {
      overflow: `hidden`
    },
    itemButtonContainer: {
      width: 32,
      height: 32,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    icon: {
      width: 48,
      height: 48,
      padding: 4,
      marginRight: 16,
      verticalAlign: 'top',
      background: "rgba(255, 79, 84, 0.08)",
      borderRadius: 6,
    },
    title: {
      ...text.body1bold,
      color: colors.gray1,
      marginBottom: 4,
    },
    description: {
      ...text.body2tight,
      color: colors.gray2,
      marginBottom: 0,
    },
    caption: {
      ...text.caption,
      color: colors.gray3,
    },
    subject: {
      paddingRight: 16,
    },
  }
  const { unit, viewResource, handleDelete, handleUpdate, shareResource } = props;

  function parseMenu() {
    return (
      <Dropdown
        trigger={['click']}
        placement="bottomRight"
        overlay={
          <Menu>
            {
              unit.is_active
                ? <Menu.Item key={0} onClick={() => handleUpdate(unit, { is_active: false })}>{teacherLang.set_inactive}</Menu.Item>
                : <Menu.Item key={1} onClick={() => handleUpdate(unit, { is_active: true })}>{teacherLang.set_active}</Menu.Item>
            }
            <Menu.Item key={2} style={{ color: colors.danger2 }} onClick={() => handleDelete(unit)}>{teacherLang.delete_resource}</Menu.Item>
          </Menu>
        }
      >
        <div style={{ ...styles.itemButtonContainer, cursor: `pointer` }}>
          <MoreSvg style={{ color: colors.gray4 }} />
        </div>
      </Dropdown>
    );
  }

  function parseStatus(expired, active) {
    if (expired)
      return <div style={{ color: colors.gray3 }}><ExpiredSvg style={{ marginRight: `8px` }} />{teacherLang.expired}</div>
    if (active)
      return <div><ActiveSvg style={{ marginRight: `8px` }} />{teacherLang.active}</div>
    else
      return <div><InactiveSvg style={{ marginRight: `8px` }} />{teacherLang.inactive}</div>;
  }

  const expired = moment().isAfter(moment(unit.expire_at))

  return (
    <Row style={styles.item} className="resource-list-item">
      <Col xs={24} sm={12} style={{ display: `flex`, cursor: `pointer`, paddingRight: 16 }} onClick={() => viewResource(unit)}>
        <div>
          {
            <VideoSvg style={styles.icon} />
          }
        </div>
        <div style={styles.itemContainer}>
          <Paragraph style={styles.title} className="resource-list-item-title" ellipsis>{unit.title}</Paragraph>
          <div style={styles.caption}>{teacherLang.formatString(teacherLang.upload_by, unit.created_by)}</div>
        </div>
      </Col>
      <Col xs={0} sm={4} align="left">
        <Paragraph ellipsis style={styles.subject}>{unit.subject}</Paragraph>
      </Col>
      <Col xs={0} sm={2} >
        {parseStatus(expired, unit.is_active)}
      </Col>
      <Col xs={0} sm={2} align="right" >
        {unit.read}
      </Col>
      <Col xs={0} sm={2} align="right">
        <div style={{ cursor: `pointer`,color:colors.gray4, ...styles.itemButtonContainer }} onClick={() => shareResource(unit)}>
          <ShareSvg/>
        </div>
      </Col>
      <Col xs={0} sm={2} align="right">
        {parseMenu()}
      </Col>
    </Row>
  );
}
export default Item;