import React, { useEffect } from 'react';
import { Modal } from 'antd';
import decode from 'unescape';

import { teacherLang } from '../../../languages/teacher.language';
import {
  ROUTE_STUDENT_RESOURCE,
  PATH_APP_URL,
  APP_NAME,
} from '../../../constants/app.constant';
import { SHARE_DESCRIPTION_MAX_LENGTH } from '../../../constants/teacher.constant';
import { CLICK_SHARE_RESOURCE } from '../../../constants/tracker.constant';
import { trackerWithEvent } from '../../../actions/app.action';
import { colors } from '../../styles';
import CopyToClipboard from '../../../modules/CopyToClipboard'

function ShareModal(props) {
  const [values, setValue] = React.useState({
    copied: false,
  });

  const styles = {
    copy: {
      border: `solid 1px ${colors.gray5}`,
      borderRadius: `6px`,
      padding: `10px 16px 22px 16px`,
      marginBottom: `5px`,
    },
    clipboardText: {
      position: 'absolute',
      opacity: 0,
    },
  }

  // <---------- share Info format = START
  let description = null
  if (props.resource) {
    description = decode(props.resource.description.replace(/(<([^>]+)>)/ig, ''))
    if (description.length > SHARE_DESCRIPTION_MAX_LENGTH)
      description = description.substring(0, SHARE_DESCRIPTION_MAX_LENGTH) + '...'
    if (description)
      description = `${description}`
  }

  let shareInfo = ''
  if (props.resource)
    shareInfo = `${teacherLang.formatString(teacherLang.share_resource_title, APP_NAME)}

${props.resource.title}

${description}
${PATH_APP_URL + ROUTE_STUDENT_RESOURCE.replace(/:id/g, props.resource.uuid)}`
  // <---------- share Info format = STOP

  function closeModal() {
    setValue({
      ...values,
      copied: false,
    });
    props.onCancel()
  }

  useEffect(() => {
    if (props.visible && !values.copied) {
      setTimeout(() => copyToClipboard(), 300)
    }
  })

  function copyToClipboard() {
    CopyToClipboard.copy(shareInfo);
    setValue({
      ...values,
      copied: true,
    });
    trackerWithEvent(CLICK_SHARE_RESOURCE);
  }


  return (
    <Modal
      {...props}
      footer={null}
      title={teacherLang.share_resource}
      onCancel={closeModal}
    >
      {
        props.resource &&
        <div style={styles.copy}>
          <p><b>{teacherLang.formatString(teacherLang.share_resource_title, APP_NAME)}</b></p>
          <p><b>{props.resource.title}</b></p>
          <p>{description}</p>
          <a
            href={PATH_APP_URL + ROUTE_STUDENT_RESOURCE.replace(/:id/g, props.resource.uuid)}
            target="_blank"
            rel="noopener noreferrer"
          >
            {PATH_APP_URL + ROUTE_STUDENT_RESOURCE.replace(/:id/g, props.resource.uuid)}
          </a>
        </div>
      }
      {values.copied && <div>{teacherLang.copied_to_clipboard}</div>}

    </Modal>
  )
}
export default ShareModal;