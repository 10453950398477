// <----- sdp upload
export const SDP_UPLOAD_SERVER_NAME = 'cherrypicks';
export const SDP_UPLOAD_SERVER_HOST = 'cs.101.com';
export const SDP_UPLOAD_PROTOCOL = 'https';

export const FILE_UPLOAD_BY_TEACHER = 'file-upload-by-teacher'
export const FILE_UPLOAD_BY_STUDENT = 'file-upload-by-student'


// <------ main resource
export const MAIN_RESOURCE_FILE_MAX_SIZE_IN_MB = 500;
export const MAIN_RESOURCE_FILE_ACCEPT_TYPES = "video/mp4,video/webm,video/ogg,video/quicktime";

// <------ attachment
export const ATTACHMENT_FILE_MAX_SIZE_IN_MB = 50;
export const ATTACHMENT_FILE_ACCEPT_TYPES = "application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf";
export const ATTACHMENT_FILE_MAX_COUNT = 5;

// <------ homewoek
export const HOMEWORK_FILE_MAX_SIZE_IN_MB = 50;
export const HOMEWORK_FILE_ACCEPT_TYPES = "application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf";
export const HOMEWORK_FILE_MAX_COUNT = 5;

// <------ selected file status
export const SELECTED_FILE_VALID = "done"
export const SELECTED_FILE_INVALID = "error"

// <----- download only
export const DOWNLOAD_ONLY_URL = "&attachment=true"

// <----- file item type
export const FILE_ITEM_TYPE_VIEW = "file-item-type-view"
export const FILE_ITEM_TYPE_TEACHER_EDIT = "file-item-type-teacher-edit"
export const FILE_ITEM_TYPE_UPLOAD = "file-item-type-edit"
export const FILE_ITEM_TYPE_HOMEWORK = "file-item-type-homework"

// <----- upload props type
export const UPLOAD_TYPE_MAIN_RESOURCE = "upload-props-type-main-resource"
export const UPLOAD_TYPE_ATTACHMENT = "upload-props-type-attachment"
export const UPLOAD_TYPE_HOMEWORK = "upload-props-type-homework"



