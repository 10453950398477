import React from 'react';
import { cdn } from '../../../../utils/cdn';

export default function PdfPreviewer(props){
    const [values, setValue] = React.useState({
        url: null,
    });
    
    async function parseCDN(material) {
        let path = await cdn(material.file_id, material.file_path_global)
        setValue({ ...values, url: path })
    }

    if (values.url === null) parseCDN(props);
    const height = props.height ? props.height : "546px"

    return (
        <div key={props.uuid}>
        	{
        		values.url && 
	            <iframe
	                title="pdf viewer"
	                src={"https://docs.google.com/viewer?url=" + values.url + "&embedded=true"}
	                width="100%" height={height}
	                frameBorder="0" />
	        }
        </div>
    )
}
