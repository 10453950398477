// <----- actions
export const ACTION_START_APP_LOADING = 'action_start_app_loading';
export const ACTION_END_APP_LOADING = 'action_end_app_loading';
export const ACTION_LIGHTBOX = 'control_lightbox';
export const ACTION_VISIT_LESSON = 'visit_lesson';
export const ACTION_READ_NOTIFICATION = 'read_notification';

// <----- z-indexes
export const Z_INDEX_MAIN = 1;
export const Z_INDEX_MAIN_HEADER = 99;
export const Z_INDEX_PROJECT_HEADER = 90;
export const Z_INDEX_LOADING = 9999;

// <----- variables
export const RICH_TEXT_CONTROLS = ['bold', 'italic', 'underline', 'text-color', 'separator', 'link', 'separator'];
export const RESOURCE_SEARCH = ["title", "description", "created_by", "subject", "tags.class"];
export const USER_SEARCH = ["username", "name"];

// <----- notification type: new or enhancement
export const NOTIFICATION_VLP = [
	{
		type: "enhancement",
		title: "微調介面設計",
		description: "來找找學習日誌和資源詳細頁有甚麼改變吧 👀",
	}
];
export const NOTIFICATION_TUITION = [
	{
		type: "new",
		title: "平台正式面世",
		description: "感謝您的耐心等候，如有任何疑難，歡迎向我們查詢。",
	}
];
export const NOTIFICATION_CONTENT = process.env.REACT_APP_PLATFORM === "Centre" ? NOTIFICATION_TUITION : NOTIFICATION_VLP;

