import React, { useEffect } from 'react';
import BraftEditor from 'braft-editor';
import { useParams } from 'react-router-dom';

import { errorLang } from '../../../languages/error.language';
import { ContextStore } from '../../../store/ContextStore';

import { ROUTE_TEACHER_RESOURCES } from '../../../constants/app.constant';
import { S_TEACHER_RESOURCE } from '../../../constants/tracker.constant';
import { startAppLoading, endAppLoading, showMessage, getItemFromStorage } from '../../../actions/dom.action';
import { getAllResources, updateResource, getResourcesHomework } from '../../../actions/teacher.action';
import { trackerWithPageView } from '../../../actions/app.action';
import Previewer from '../../Common/File/Previewer';
import ShareModal from './ShareModal';
import EditMode from './ResourceDetail/EditMode';
import ViewMode from './ResourceDetail/ViewMode';
import AssignMode from './ResourceDetail/AssignMode';
import { cdn } from '../../../utils/cdn';

function Resource(props) {
	const { dispatch, dom, teacher, auth } = React.useContext(ContextStore);
	const [values, setValue] = React.useState({
		init: false,
		tracker: false,
		title: teacher.resource.title,
		description: BraftEditor.createEditorState(teacher.resource.description),
		students: teacher.resource.tags && teacher.resource.tags.students ? teacher.resource.tags.students : [],
		mode: 'view',
	});
	const [share, setShare] = React.useState(false);
	const pageId = useParams().id;

	function shareResource(resource) {
		setShare(true)
	}

	function closeShareModal() {
		setShare(false)
	}

	async function getResources(uuid) {
		dispatch(startAppLoading());
		setValue({ ...values, init: true });
		dispatch(await getAllResources(uuid));
		const resource = getItemFromStorage('teacher.resource') ? JSON.parse(getItemFromStorage('teacher.resource')) : {};

		// redirect to resources list if invalid resource uuid
		if (!resource.uuid) {
			props.history.push(ROUTE_TEACHER_RESOURCES);
			dispatch(endAppLoading());
			return;
		}
		if (!teacher.resource.duration) getResourceDuration(resource)

		setValue({
			...values,
			title: resource.title,
			description: BraftEditor.createEditorState(resource.description),
			students: resource.tags.students ? resource.tags.students : [],
		});
		dispatch(await getResourcesHomework(resource.uuid));
		dispatch(endAppLoading());
	}

	async function getResourceDuration(resource) {
		dispatch(startAppLoading());
		setValue({ ...values, init: true });
		const path = await cdn(resource.file_id, resource.file_path_global)
		new Promise((resolve, reject) => {
			const video = document.createElement('video')
			video.preload = 'metadata'
			video.onloadedmetadata = function () {
				window.URL.revokeObjectURL(video.src)
				const duration = !video.duration && video.duration !== 0 ? null : parseInt(video.duration)
				resolve(duration)
			}
			video.onerror = () => {
				resolve(null)
			}
			video.src = path
		}).then(async (duration) => {
			if (duration) dispatch(await updateResource(resource, { duration: duration }));
			dispatch(endAppLoading());
		})
	}

	function pageTracker() {
		setValue({
			...values,
			tracker: true
		});
		trackerWithPageView(S_TEACHER_RESOURCE);
	}

	function changeValue(name, data) {
		let value = {};
		value[name] = data;
		setValue({
			...values,
			...value,
		});
	}

	async function handleSubmit() {
		const title = values.title;
		const description = values.description.toHTML();
		const students = values.students;

		if (!title) {
			showMessage(errorLang.teacher.title_not_empty);
			return;
		}

		dispatch(startAppLoading());
		dispatch(await updateResource(teacher.resource, { title: title, description: description, students: students }));
		dispatch(endAppLoading());
		setValue({ ...values, mode: 'view' });
	}

	function handleCancel() {
		setValue({
			...values,
			title: teacher.resource.title,
			description: BraftEditor.createEditorState(teacher.resource.description),
			mode: 'view',
		});
	}

	function parseMode(mode) {
		switch (mode) {
			case 'view':
				return (
					<ViewMode
						title={teacher.resource.title}
						description={teacher.resource.description}
						changeValue={changeValue}
						shareResource={shareResource}
					/>
				);
			case 'edit':
				return (
					<EditMode
						title={teacher.resource.title}
						description={values.description}
						changeValue={changeValue}
						handleCancel={handleCancel}
						handleSubmit={handleSubmit}
					/>
				);
			case 'assign':
				return (
					<AssignMode
						data={teacher.students}
						students={values.students}
						changeValue={changeValue}
						handleCancel={handleCancel}
						handleSubmit={handleSubmit}
					/>
				);
			default:
				return <div />;
		}
	}

	useEffect(() => {
		if (!values.init
			&& !dom.loading
			&& teacher.resource.uuid !== pageId
			&& auth.role === "teacher") {
			getResources(pageId);
		}
		if (!values.tracker) {
			pageTracker()
		}

		if (!values.init && !teacher.resource.duration) {
			getResourceDuration(teacher.resource)
		}

	})

	if (!teacher.resource || !teacher.resource.uuid)
		return <div />

	if (pageId !== teacher.resource.uuid)
		return <div />

	return (
		<div style={{ marginBottom: 24 }}>
			<Previewer {...teacher.resource} />
			{parseMode(values.mode)}
			<ShareModal
				visible={share}
				onCancel={closeShareModal}
				resource={teacher.resource}
			/>
		</div>
	);
}
export default Resource;
