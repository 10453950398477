import {
	ACTION_START_APP_LOADING,
	ACTION_END_APP_LOADING,
	ACTION_LIGHTBOX,
	ACTION_VISIT_LESSON,
	ACTION_READ_NOTIFICATION,
} from '../constants/dom.constant';
import { storeItemToStorage } from '../actions/dom.action';
 
function domReducer(state, action) {
	const payload = action.payload;
	switch (action.key) {
		case ACTION_START_APP_LOADING:
		case ACTION_END_APP_LOADING:
			return Object.assign({}, state, {
					...state,
					loading: payload
				});
		case ACTION_LIGHTBOX:
			if(payload.isOpened){
				for(var i=0; i<payload.imageSources.length; i++){
					if(payload.imageSources[i].src===payload.selectedSource){
						return Object.assign({}, state, {
									...state,
									lightbox:{
										...state.lightbox,
										isOpened: true,
										imageSources: payload.imageSources,
										currentIndex: i,
									},
								});
					}
				}
			}
			
			return Object.assign({}, state, {
						...state,
						lightbox:{
							...state.lightbox,
							isOpened: false,
						},
					});
		case ACTION_VISIT_LESSON:
			return Object.assign({}, state, {
					...state,
					lesson: payload
				});
		case ACTION_READ_NOTIFICATION:
			storeItemToStorage('dom.lastRead', payload);
			return Object.assign({}, state, {
					...state,
					readNotification: true
				});
		default:
			return state;
	}
}
export default domReducer;
