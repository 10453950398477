import { run } from '../utils/promise';
import {
	getApi,
} from '../utils/api';

// import { fileToBase64 } from '../utils/file';
import {
	ACTION_ALL_RESOURCES,
	ACTION_VIEW_RESOURCE,
	ACTION_UPDATE_RESOURCE,
	ACTION_CREATE_RESOURCE,
	ACTION_CREATE_RESOURCE_ATTACHMENT,
	ACTION_DELETE_RESOURCE_ATTACHMENT,
	ACTION_DELETE_RESOURCE,
	ACTION_GET_TOKEN_FOR_UPLOAD,
	ACTION_TEACHER_GET_STUDENTS,
	ACTION_GET_RESOURCE_HOMEWORK,
} from '../constants/teacher.constant';
import { getItemFromStorage } from './dom.action';

export const getAllResources = async (resource) => {
	const school_uuid = JSON.parse(getItemFromStorage('auth.school')).uuid;
	const response = await getApi(false).get(`/teacher/material/list?school_uuid=${school_uuid}`);
	response.data.data.selectedResource = resource;
	return run(
		ACTION_ALL_RESOURCES,
		response.data,
	);
}

export const getTokenObjectForUpload = async (fileName) => {

	// TODO: change to params
	const school_uuid = JSON.parse(getItemFromStorage('auth.school')).uuid;
	const response = await getApi().get(`/teacher/material/signedUrl?school_uuid=${school_uuid}&file_name=${fileName}`)
	return run(
		ACTION_GET_TOKEN_FOR_UPLOAD,
		response,
	);
}

export const createResource = async (data) => {
	const response = await getApi().post(`/teacher/material`, data);

	return run(
		ACTION_CREATE_RESOURCE,
		response.data,
	);
}

export const createResourceAttachment = async (data) => {
	const response = await getApi().post(`/teacher/material/attachment`, data);
	console.log("createResourceAttachment ", response)
	return run(
		ACTION_CREATE_RESOURCE_ATTACHMENT,
		response.data,
	);
}

export const deleteResourceAttachment = async (uuid) => {
	const response = await getApi().delete(`/teacher/material/attachment/${uuid}`);
	console.log("deleteResourceAttachment ", response)
	return run(
		ACTION_DELETE_RESOURCE_ATTACHMENT,
		response.data,
	);
}

export const updateResource = async (resource, changes) => {
	const tags = {
		...resource.tags,
		class: changes.classes !== undefined ? changes.classes : resource.tags.class,
		subject: changes.subjects !== undefined ? changes.subjects : resource.tags.subject,
		students: changes.students !== undefined ? changes.students : resource.tags.students,
	};
	const data = {
		metadata: {
			file_id: resource.file_id,
			file_path: resource.file_path,
			file_size: resource.file_size,
			mime_type: resource.mime_type,
			duration: changes.duration !== undefined ? changes.duration : resource.duration,
			title: changes.title !== undefined ? changes.title : resource.title,
			description: changes.description !== undefined ? changes.description : resource.description,
			remarks: changes.remarks !== undefined ? changes.remarks : resource.remarks,
			start_at: changes.start_at !== undefined ? changes.start_at : resource.start_at,
			end_at: resource.end_at,
			is_active: changes.is_active !== undefined ? changes.is_active : resource.is_active,
		},
		tags: tags,
	};

	const response = await getApi().put(`/teacher/material/${resource.uuid}`, data);
	response.data.data.tags = tags;

	return run(
		ACTION_UPDATE_RESOURCE,
		response.data,
	);
}
export const deleteResource = async (uuid) => {
	const response = await getApi().delete(`/teacher/material/${uuid}`);

	return run(
		ACTION_DELETE_RESOURCE,
		response.data,
	);
}

export const getResourceDetail = async (data) => {
	const response = { data: data };
	return run(
		ACTION_VIEW_RESOURCE,
		response,
	);
}

export const getStudents = async () => {
	const school_uuid = JSON.parse(getItemFromStorage('auth.school')).uuid;
	const response = await getApi(false).get(`/teacher/students?school_uuid=${school_uuid}`);
	return run(
		ACTION_TEACHER_GET_STUDENTS,
		response.data,
	);
}

export const getResourcesHomework = async (uuid) => {
	const response = await getApi().get(`teacher/homeworks?material_uuid=${uuid}`);
	return run(
		ACTION_GET_RESOURCE_HOMEWORK,
		response.data,
	);
}


export default {
	getAllResources,
	createResource,
	updateResource,
	deleteResource,
	getResourceDetail,
	getTokenObjectForUpload,
	createResourceAttachment,
	deleteResourceAttachment,
	getStudents,
	getResourcesHomework
};
