import React from 'react'
import { Player, BigPlayButton } from "video-react";
import "./video-react.css";
import { errorLang } from '../../../../languages/error.language';
import { showMessage } from '../../../../actions/dom.action';
import { RESOURCE_LOAD_SUCCESS, RESOURCE_LOAD_FAIL } from '../../../../constants/tracker.constant';
import { trackerWithEvent, trackerWithException } from '../../../../actions/app.action';
import { cdn, parseCdnInfo } from '../../../../utils/cdn';

export default function VideoPlayer(props) {
    const [values, setValue] = React.useState({
        url: null,
    });

    let error = null; // <----- prevent Player to trigger twice error messages

    function onLoadError() {
        if (error) return; else error = 1;
        showMessage(errorLang.common.error_load_video);
        const { name, index } = parseCdnInfo(values.url);
        trackerWithEvent(RESOURCE_LOAD_FAIL, 'cnd', index);
        trackerWithException(`RESOURCE DETAIL - Fail to load resource from ${name}`, true);
    }

    function onLoadSuccess() {
        const { name, index } = parseCdnInfo(values.url);
        trackerWithEvent(RESOURCE_LOAD_SUCCESS, name, index);
    }

    async function parseCDN(material) {
        let path = await cdn(material.file_id, material.file_path_global)
        setValue({ ...values, url: path })
    }

    if (values.url === null) parseCDN(props);

    return (
        <div className="custom-video-player">
            {values.url &&
                <Player
                    key={props.uuid}
                    fluid={false}
                    width={"100%"}
                    muted={false}
                    autoPlay={false}
                    preload={"auto"}
                    onError={onLoadError}
                    onPlay={props.onPlay ? props.onPlay : null}
                    onCanPlay={onLoadSuccess}>
                    <BigPlayButton position="center" />
                    <source src={values.url} />
                </Player>
            }
        </div>
    )
}
