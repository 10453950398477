import { getItemFromStorage, clearStorage, getRememberMeValue } from "../../actions/dom.action";

export const isAuthorized = (auth) => {
	return auth.userId !== null;
};

export const isTeacher = (auth) => {
	return auth.token !== null;
};

export const isStudent = (auth) => {
	return auth.studentToken !== null;
};

export const logout = () => {
	clearStorageAfterLogout()
}

export const teacherLogout = () => {
	clearStorageAfterLogout()
}

export const studentLogout = () => {
	clearStorageAfterLogout()
}

function clearStorageAfterLogout() {
	const lastRead = getItemFromStorage('dom.lastRead');
	const sysMessages = getItemFromStorage('app.systemMessages');
	const rememberMe = getRememberMeValue();
	clearStorage()
	if (lastRead && rememberMe) {
		localStorage.setItem('dom.lastRead', lastRead);
		localStorage.setItem('app.systemMessages', sysMessages);
	}
}
