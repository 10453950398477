import React, { useEffect } from 'react';
import { Form, Upload, Select, Input, DatePicker, Row, Col, TreeSelect, message } from 'antd';
import { teacherLang } from '../../../languages/teacher.language';
import { errorLang } from '../../../languages/error.language';
import { ROUTE_TEACHER_RESOURCES, APP_PLATFORM, SYSTEM_PLATFORM_SCHOOL } from '../../../constants/app.constant';
import Button from '../../Common/Button';
import { uploadFileWithSignedUrl } from '../../Common/File/SignedUrlFileUploader';
import { colors, shadows, text } from '../../styles';
import {
	STATUS_UPLOAD_INITED,
	STATUS_UPLOAD_SELECTED,
	STATUS_UPLOAD_CANCELLED,
	STATUS_UPLOAD_FILE_TYPE_INVALID,
	STATUS_UPLOAD_FILE_TOO_LARGE,
	STATUS_UPLOAD_DONE,
	STATUS_UPLOAD_UPLOADING,
	DEFAULT_DATE_FORMAT,
	DEFAULT_DATE_SUBMIT_FORMAT,
	STATUS_UPLOAD_ATTACHMENT_ERROR
} from '../../../constants/teacher.constant';
import { S_TEACHER_UPLOAD } from '../../../constants/tracker.constant';
import { RICH_TEXT_CONTROLS } from '../../../constants/dom.constant';
import { SUBJECT_LIST } from '../../../constants/subject.constant';
import { ReactComponent as VideoSvg } from '../../../assets/video-color.svg';
import { ReactComponent as CloseSvg } from '../../../assets/Close.svg';
import { ReactComponent as AlertSvg } from '../../../assets/alert-circle.svg';
import { ReactComponent as UploadSvg } from '../../../assets/upload-cloud.svg';

import 'braft-editor/dist/index.css'
import BraftEditor from 'braft-editor'
import { createResource, createResourceAttachment } from '../../../actions/teacher.action';
import { formatBytes, formatDate, trackerWithPageView, trackerWithException, checkFileType, checkFileSize } from '../../../actions/app.action'
import moment from 'moment'
import { getItemFromStorage, showMessage, convertAttachmentRequestData, getUploadFileCheckingResult, getUploadProps, checkIsUrlValid } from '../../../actions/dom.action';
import {
	MAIN_RESOURCE_FILE_ACCEPT_TYPES,
	MAIN_RESOURCE_FILE_MAX_SIZE_IN_MB,
	ATTACHMENT_FILE_MAX_COUNT,
	ATTACHMENT_FILE_MAX_SIZE_IN_MB,
	SELECTED_FILE_VALID,
	SELECTED_FILE_INVALID,
	UPLOAD_TYPE_ATTACHMENT,
	FILE_UPLOAD_BY_TEACHER,
	FILE_ITEM_TYPE_UPLOAD,
	UPLOAD_TYPE_MAIN_RESOURCE
} from '../../../constants/upload.constant';
import FileItem from '../../Common/File/FileItem/fileItem';
import { commonLang } from '../../../languages/common.language';

function UploadView(props) {
	const { Dragger } = Upload;
	const { Option } = Select;

	const [values, setValues] = React.useState({
		status: STATUS_UPLOAD_INITED,
		mainResources: [],
		isMainResourceReady: false,
		attachments: [],
		isAttachmentReady: true,
		uploading: false,
		formValue: {
			title: null,
			start_at: null,
			end_at: null,
			classes: [],
			is_active: true,
			remarks: null,
			subjects: [],
		},
		editorState: BraftEditor.createEditorState(),
		isRequiredFieldComplete: false,
		material_uuid: null,
		treeExpand: [],
	})

	const [uploadingFile, setUploadingFile] = React.useState({
		name: "",
		progress: 0
	})


	useEffect(() => {
		trackerWithPageView(S_TEACHER_UPLOAD);
	}, []);

	// <!----- main view (struc)
	const styles = {
		form: {
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			height: "100%"
		},
		formDiv: {
			backgroundColor: colors.white,
			boxShadow: shadows.elevation5,
			borderRadius: "6px",
			maxHeight: "100%",
			position: "relative"
		},
		formHeader: {
			width: "100%",
			padding: "24px 24px 0"
		},
		uploadBody: {
			padding: "16px 24px 24px",
		},
		formBody: {
			padding: "16px 24px"
		},
		formFooter: {
			width: "100%",
			borderTop: `1px solid ${colors.gray5}`,
			padding: "12px 24px",
			display: "flex",
			justifyContent: "space-between",
		},
		formHeaderText: {
			...text.h6,
			color: colors.gray1
		},
		uploadBoxTitle: {
			...text.body1,
			color: colors.gray1,
			marginBottom: "8px",
		},
		uploadBoxDesc: {
			...text.body2tight,
			color: colors.gray3,
			marginBottom: "16px",
		},
		formItemStyle: {
			marginBottom: 16,
			paddingBottom: 0,
		},
		formLabelHint: {
			color: colors.gray3
		},
		uploadFormClassLabel: {
			display: `inline-block`,
			width: `calc(100% - 10px)`,
		},
	}

	function pageView() {
		let view;
		if (values.status !== STATUS_UPLOAD_DONE && values.status !== STATUS_UPLOAD_ATTACHMENT_ERROR) {
			view = formView()
		} else {
			view = formUploadSuccessView()
		}

		return (
			<div className="upload-container vertical-align-page-content">
				{view}
			</div>
		)
	}

	// <!----- form view
	function formView() {
		function formHeaderView() {
			return (
				<div style={styles.formHeader}>
					<div style={styles.formHeaderText}>{teacherLang.upload}</div>
				</div>
			)
		}

		function formBodyView() {
			if (values.status === STATUS_UPLOAD_INITED) {
				return (
					<div style={styles.uploadBody}>
						{fileDragView()}
					</div>)
			} else {
				return (
					<div style={styles.formBody}>
						{formBodyFileView()}
						{formBodyformView()}
					</div>
				)
			}
		}

		function formFooterView() {
			if (values.status !== STATUS_UPLOAD_INITED) {
				return (
					<div style={styles.formFooter}>
						<Select onChange={(e) => updateFormValue([['is_active', e]])} size="large" defaultValue="true" style={{ boxShadow: shadows.elevation5, width: 88, borderRadius: "9px", }}>
							<Option value="true">{teacherLang.active}</Option>
							<Option value="false">{teacherLang.inactive}</Option>
						</Select>
						<div style={{ display: "flex", alignItems: "center" }}>
							{values.status === STATUS_UPLOAD_UPLOADING &&
								<div style={{ ...text.body2tight, color: colors.gray2, marginRight: 16 }}>
									{teacherLang.formatString(teacherLang.upload_progress, uploadingFile.name, uploadingFile.progress)}
								</div>
							}
							<Button name="submitBtn" type="primary" disabled={checkIsSubmitButtonDisabled()}>{teacherLang.upload_btn}</Button>
						</div>
					</div>
				)
			}
			return null
		}

		return (
			<Form onSubmit={handleSubmit} layout="vertical" style={styles.form}>
				<div style={styles.formDiv}>
					{formHeaderView()}
					{formBodyView()}
					{formFooterView()}
				</div>
			</Form>
		);
	}
	// <!---- form body view
	function formBodyformView() {
		const tags = JSON.parse(getItemFromStorage('teacher.tags'));
		return (
			<div>
				<Form.Item label={teacherLang.title} style={styles.formItemStyle} required>
					<Input onChange={(e) => updateFormValue([['title', e.target.value]])} name="title" />
				</Form.Item>
				<Form.Item label={teacherLang.desc} style={styles.formItemStyle}>
					{textEditor()}
				</Form.Item>
				<Form.Item label={
					<div style={styles.uploadFormClassLabel}>
						{teacherLang.remarks}
						<span style={styles.formLabelHint}>{teacherLang.remarks_hints}
						</span>
					</div>} style={styles.formItemStyle}>
					<Input onChange={(e) => updateFormValue([['remarks', e.target.value]])} name="remarks" />
				</Form.Item>
				<Row gutter={[24, 0]}>
					<Col xs={24} sm={12}>
						<Form.Item
							label={<div style={styles.uploadFormClassLabel}>{teacherLang.subject}</div>}
							style={styles.formItemStyle}>
							<TreeSelect
								name="subject"
								value={values.formValue.subjects}
								treeData={SUBJECT_LIST}
								treeExpandedKeys={values.treeExpand}
								onTreeExpand={e => setValues({...values, treeExpand: [e[e.length-1]]})}
								placeholder={teacherLang.select_subject}
								onChange={(e) => updateFormValue([["subjects", [e]]])}
							/>
						</Form.Item>
					</Col>
					<Col xs={24} sm={12}>
						<Form.Item label={teacherLang.learing_date} style={styles.formItemStyle} required>
							<DatePicker onChange={handleDatePickerChange} format={DEFAULT_DATE_FORMAT} name="publishDate" placeholder={DEFAULT_DATE_FORMAT} style={{ width: "100%" }} />
							<div style={{ ...text.caption, color: colors.gray3, paddingTop: 5 }}>{teacherLang.expiry_hint}</div>
						</Form.Item>
					</Col>
				</Row>
				{
					APP_PLATFORM === SYSTEM_PLATFORM_SCHOOL &&
					<Form.Item
						label={
							<div style={styles.uploadFormClassLabel}>
								{teacherLang.class}
								<span style={styles.formLabelHint}>{teacherLang.classHint}
								</span>
							</div>}
						style={styles.formItemStyle} required>
						<Select
							onChange={(e) => updateFormValue([["classes", e]])}
							name="class"
							mode="tags"
							value={values.formValue.classes}>
							{tags.map((unit) => <Option key={unit}>{unit}</Option>)}
						</Select>
					</Form.Item>
				}

				<Form.Item
					label={
						<div>
							{commonLang.attachment} – {commonLang.file_type_hint}
							<span style={styles.formLabelHint}>{teacherLang.formatString(commonLang.upload_hint, ATTACHMENT_FILE_MAX_COUNT, ATTACHMENT_FILE_MAX_SIZE_IN_MB)}</span>
						</div>}
					style={styles.formItemStyle}>
					{attachmentView()}
				</Form.Item>
			</div>
		)
	}
	function handleDatePickerChange(e) {
		if (!e) {
			updateFormValue([["start_at", null], ["end_at", null]])
		} else {
			const startDate = e.format(DEFAULT_DATE_SUBMIT_FORMAT);
			const endDate = moment(startDate).add(30, 'days').format(DEFAULT_DATE_SUBMIT_FORMAT);
			updateFormValue([["start_at", startDate], ["end_at", endDate]])
		}
	}

	function updateFormValue(arr) {
		let value = {};
		for (var item of arr) {
			if (item[0] === 'classes')
				value[item[0]] = item[1]
					.map(item => item.toUpperCase().replace(/ /g, ''))
					.filter((v, i, a) => a.indexOf(v) === i && v !== "")
			else
				value[item[0]] = item[1];
		}
		setValues({
			...values,
			formValue: {
				...values.formValue,
				...value
			},
		})
	}

	function checkIsAllDataReady() {
		const isFileReady = values.isMainResourceReady && values.isAttachmentReady
		const isTitleReady = !(!values.formValue.title)
		const isStartDateReady = !(!values.formValue.start_at)
		const isClassReady = values.formValue.classes.length > 0 || APP_PLATFORM !== SYSTEM_PLATFORM_SCHOOL
		return isFileReady && isTitleReady && isStartDateReady && isClassReady
	}

	function checkIsSubmitButtonDisabled() {
		if (values.status === STATUS_UPLOAD_SELECTED) {
			return !checkIsAllDataReady()
		} else {
			return true
		}
	}

	// <!---- rich text editor
	function textEditor() {
		const handleChange = (editorState) => {
			setValues({ ...values, editorState: editorState })
		}
		const editorStyle = {
			border: "1px solid #d9d9d9",
			borderRadius: 8,
		}

		return (
			<div style={{ height: 300 }}>
				<BraftEditor
					style={editorStyle}
					controls={RICH_TEXT_CONTROLS}
					onChange={handleChange}
					language="zh-hant"
					hooks={{
						'toggle-link': ({ href, target }) => {
							const vaild = checkIsUrlValid(href)
							if (!vaild) showMessage(errorLang.teacher.invalid_link)
							href =  vaild? href : ""
							return { href, target }
						}
					}}
				/>
			</div> // maybe can do resizing on div to enlarge editor
		);
	}
	// <!----- Attachment views related
	function attachmentView() {

		const attachmentUploadProps = getUploadProps(UPLOAD_TYPE_ATTACHMENT, values.attachments, handleAttachmentSelected, handleAttachmentCancelled, true)
		const list = values.attachments.map((unit, index) => {
			const itemProps = {
				type: FILE_ITEM_TYPE_UPLOAD,
				file: unit,
				name: unit.name,
				error: unit.error,
				deleteAction: () => { handleAttachmentCancelled(unit) }
			}
			return (
				<Col span={24} key={index}>
					<FileItem {...itemProps} />
				</Col>
			)
		})
		return (
			<div>
				<Upload {...attachmentUploadProps} >
					<Button type="secondary" inlinestyle={{ display: "flex", alignItems: "center", padding: "14px 16px" }} htmltype="button" disabled={values.attachments.length >= ATTACHMENT_FILE_MAX_COUNT} >
						<UploadSvg style={{ width: 16, height: 14, marginRight: 8 }} />
						{commonLang.select_files}
					</Button>
				</Upload>
				{values.attachments.length > 0 &&
					<Row gutter={[0, 6]} style={{ marginTop: 8 }}>{list}</Row>
				}
			</div>
		)
	}

	function handleAttachmentSelected(file, fileList) {
		if (fileList.length > 1 && file !== fileList[fileList.length - 1]) {
			return false
		}

		let newList = values.attachments
		let isReachLimit = false

		for (var item of fileList) {
			if (newList.length >= ATTACHMENT_FILE_MAX_COUNT) {
				isReachLimit = true
				break;
			}

			const result = getUploadFileCheckingResult(UPLOAD_TYPE_ATTACHMENT, item)
			console.log(result)

			if (result.isReady) {
				item.status = SELECTED_FILE_VALID
			} else {
				item.status = SELECTED_FILE_INVALID
				item.error = result.error
			}
			newList.push(item)
		}

		if (isReachLimit) {
			showMessage(errorLang.formatString(errorLang.common.file_exceed_limit, ATTACHMENT_FILE_MAX_COUNT))
		}
		const isAllValid = checkIsAllAttachmentValid(newList)
		setValues({ ...values, attachments: newList, isAttachmentReady: isAllValid })
		return false;
	}
	function handleAttachmentCancelled(file) {
		const newFileList = values.attachments.filter((unit) => unit.uid !== file.uid);
		let isAllValid = checkIsAllAttachmentValid(newFileList)
		setValues({ ...values, attachments: newFileList, isAttachmentReady: isAllValid })
	}

	function checkIsAllAttachmentValid(fileList) {
		let isAllSuccess = true
		for (var item of fileList) {
			if (item.status === SELECTED_FILE_INVALID) {
				isAllSuccess = false
				break
			}
		}
		return isAllSuccess
	}

	// <!----- File views related
	function formBodyFileView() {
		let view = null;
		if (values.status === STATUS_UPLOAD_CANCELLED) {
			view = fileSelectView()
		} else {
			view = fileItemView()
		}
		return (
			<div style={{ paddingBottom: 16 }}>{view}</div>
		)
	}

	const mainResourceUploadProps = {
		beforeUpload: handleMainResourceSelected,
		accept: MAIN_RESOURCE_FILE_ACCEPT_TYPES
	}

	function fileDragView() {
		return (
			<div style={{ height: "240px" }}>
				<Dragger {...mainResourceUploadProps}>
					<div style={styles.uploadBoxTitle}>{teacherLang.upload_hint}</div>
					<div style={styles.uploadBoxDesc}>{teacherLang.formatString(teacherLang.upload_size_hint, MAIN_RESOURCE_FILE_MAX_SIZE_IN_MB)}</div>
					<Button type="primary" htmltype="button" >{teacherLang.select_video}</Button>
				</Dragger>
			</div>
		)
	}

	function fileSelectView() {
		return (
			<Upload {...mainResourceUploadProps}>
				<Button type="primary" htmltype="button" >{teacherLang.select_video}</Button>
			</Upload>
		)
	}

	function fileItemView() {
		const divStyle = {
			item: {
				padding: "10px 21px",
				display: "flex",
				marginBottom: 12,
				boxShadow: shadows.elevation5,
				borderRadius: 6,
				border: `1px solid ${colors.gray5}`,
				backgroundColor: colors.white
			},
			itemIconDiv: {
				display: 'flex',
				alignItems: 'center',
			},
			itemInfoDiv: {
				flex: "1 1 auto",
				margin: "0 20px",
				overflow: "hidden",
			},
			itemInfoName: {
				...text.body2tight,
				color: colors.gray1,
				overflow: "hidden",
				textOverflow: "ellipsis",
			},
			itemInfoSize: {
				...text.caption,
				color: colors.gray3
			},
			errorDiv: {
				display: 'flex',
				alignItems: 'center',
			},
			errorText: {
				...text.body2tight,
				color: colors.gray2
			}
		}
		return (
			<div>
				<div style={divStyle.item}>
					<div style={divStyle.itemIconDiv}>
						<VideoSvg style={{ width: `33px`, height: `auto` }} />
					</div>
					<div style={divStyle.itemInfoDiv}>
						<div style={divStyle.itemInfoName}>{values.mainResources[0].name}</div>
						<div style={divStyle.itemInfoSize}>{formatBytes(values.mainResources[0].size)}</div>
					</div>
					<div style={divStyle.itemIconDiv}>
						<CloseSvg style={{ color: colors.gray4, height: `auto`, cursor: `pointer` }} onClick={handleMainResourceCancelled} />
					</div>
				</div>

				{values.status === STATUS_UPLOAD_FILE_TOO_LARGE &&
					<div style={divStyle.errorDiv}>
						<AlertSvg style={{ marginRight: 5, width: 14, color: colors.alert2 }} />
						<div>
							{teacherLang.formatString(teacherLang.file_size_too_large, MAIN_RESOURCE_FILE_MAX_SIZE_IN_MB)}
						</div>
					</div>
				}

				{values.status === STATUS_UPLOAD_FILE_TYPE_INVALID &&
					<div style={divStyle.errorDiv}>
						<AlertSvg style={{ marginRight: 5, width: 14, color: colors.alert2 }} />
						<div>
							{teacherLang.file_type_invalid}
						</div>
					</div>
				}
			</div>
		)
	}

	const handleMainResourceCancelled = () => {
		setValues({
			...values,
			status: STATUS_UPLOAD_CANCELLED,
			mainResources: [],
			isUploadReady: false,
		})
	}

	function handleMainResourceSelected(file, fileList) {
		return new Promise((resolve, reject) => {
			let status = STATUS_UPLOAD_SELECTED
			let isFileReady = true
			if (!checkFileType(file.type, MAIN_RESOURCE_FILE_ACCEPT_TYPES)) {
				status = STATUS_UPLOAD_FILE_TYPE_INVALID
				isFileReady = false
			}
			if (!checkFileSize(file.size, MAIN_RESOURCE_FILE_MAX_SIZE_IN_MB)) {
				status = STATUS_UPLOAD_FILE_TOO_LARGE
				isFileReady = false
			}
			setValues({
				...values,
				status: status,
				mainResources: fileList,
				isMainResourceReady: isFileReady
			})
			if (isFileReady)
				resolve(file)
			else
				reject(file)
		});
	}

	// <!----- success view
	// Todo: attachment upload failed ui
	function formUploadSuccessView() {
		const centerText = {
			textAlign: "center",
		}
		const divStyle = {
			container: {
				backgroundColor: colors.white,
				boxShadow: shadows.elevation1,
				borderRadius: 6,
				padding: 0
			},

			header: {
				...centerText,
				paddingTop: 40
			},
			title: {
				...text.h6,
				...centerText,
				color: colors.gray1,
			},
			desc: {
				...text.body2tight,
				...centerText,
				color: colors.gray2,
			},
			reminderContainer: {
				display: "flex",
				justifyContent: "center",
				padding: "8px 32px"
			},
			reminder: {
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				background: colors.alert6,
				border: "1px solid rgba(255, 213, 69, 0.5)",
				boxSizing: "border-box",
				borderRadius: 6,
				padding: "10px 18px"
			},
			reminderText: {
				...text.body2tight,
				...centerText,
				color: colors.gray1,
			},
			footer: {
				...centerText,
				paddingBottom: 40
			}
		}

		const onBackBtnClicked = () => {
			props.history.push(ROUTE_TEACHER_RESOURCES);
		}

		return (
			<div style={divStyle.container}>
				<Row gutter={[16, 16]}>
					<Col span={24} style={divStyle.header}>
						<img alt="clloud upload" src="/images/CloudUpload.png" style={{ width: 88, height: 88 }} />
					</Col>
					<Col span={24} style={divStyle.title}>
						{teacherLang.upload_success_title}
					</Col>
					<Col span={24} style={divStyle.desc}>
						{teacherLang.formatString(teacherLang.upload_success_desc, formatDate(values.formValue.start_at))}
					</Col>
					<Col span={24} style={divStyle.reminderContainer}>
						<div style={divStyle.reminder}>
							<AlertSvg style={{ marginRight: 10, width: 20, color: colors.alert2 }} />
							<div style={divStyle.reminderText}>
								{teacherLang.upload_success_hint}
							</div>
						</div>
					</Col>
					<Col span={24} style={divStyle.footer}>
						<Button type="primary" onClick={onBackBtnClicked}>
							{teacherLang.upload_success_back}
						</Button>
					</Col>

				</Row>


			</div>
		)
	}

	// <!---- logic
	function construcData(file, res, duration) {
		const form = values.formValue
		const school = JSON.parse(getItemFromStorage('auth.school'))

		return {
			"school_uuid": school.uuid,
			"bucket_uuid": school.buckets[0].uuid,
			"metadata": {
				"file_id": res.dentry_id ? res.dentry_id : '',
				"file_path": res.path ? res.path : '',
				"file_path_global": res.path_global ? res.path_global : '',
				"file_size": file.size,
				"mime_type": file.type,
				"title": form.title,
				"description": values.editorState.toHTML(),
				"remarks": form.remarks,
				"start_at": form.start_at,
				"end_at": form.end_at,
				"is_active": form.is_active,
				"duration": duration
			},
			"tags": {
				"class": form.classes,
				"subject": form.subjects,
			},
			"attachments": []
		}
	}

	function handleCreateResourceError() {
		message.error(errorLang.teacher.upload_file_fail);
		setValues({
			...values,
			status: STATUS_UPLOAD_SELECTED,
			uploading: false
		})
	}

	// < ------ main resource upload
	async function handleSubmit(e) {
		e.preventDefault();
		if (!checkIsAllDataReady()) {
			console.log("form not completed, please check")
			return
		}

		const mainResource = values.mainResources[0];
		if (!mainResource) {
			console.log("file not existed, please check")
			return
		}

		const onMainResourceUploadSuccess = async (res) => {
			console.log("main resource upload success", res)
			new Promise((resolve, reject) => {
				const video = document.createElement('video')
				video.preload = 'metadata'
				video.onloadedmetadata = function () {
					window.URL.revokeObjectURL(video.src)
					const duration = !video.duration && video.duration !== 0 ? null : parseInt(video.duration)
					resolve(duration)
				}
				video.onerror = () => {
					resolve(null)
				}
				video.src = URL.createObjectURL(values.mainResources[0])
			}).then(async (duration) => {
				const data = construcData(mainResource, res, duration)
				try {
					const createResult = await createResource(data)
					if (!createResult.payload) {
						// upload error - failed to create resource from api response
						handleCreateResourceError()
					} else {
						attachmentUpload(createResult)
					}
				} catch (e) {
					// upload error - failed to create resource
					trackerWithException('CREATE RESOURCE - ' + e.message, false);
					handleCreateResourceError()
				}
			})
		}

		const onMainResourceUploadProgress = (res) => {
			setUploadingFile({
				...uploadingFile,
				name: teacherLang.resource,
				progress: parseInt(res.loaded)
			})
		}

		const onMainResourceUploadMd5Progress = (res) => { }

		const onMainResourceUploadError = (err) => {
			console.log("main resource upload failed", err);
			// remark: track upload fail in file uploader
			// trackerWithEvent(UPLOAD_RESOURCE_FAIL, err.message.replace(/ /g, '_'), err.code); // upload fail message
			// trackerWithException('UPLOAD FILE - ' + err.message, false);
			// upload error - failed to upload main resource
			handleCreateResourceError()
		}

		setValues({
			...values,
			status: STATUS_UPLOAD_UPLOADING,
			uploading: true
		})

		setUploadingFile({
			...uploadingFile,
			name: teacherLang.resource,
			progress: 0
		})
		uploadFileWithSignedUrl(FILE_UPLOAD_BY_TEACHER, UPLOAD_TYPE_MAIN_RESOURCE, mainResource, onMainResourceUploadSuccess, onMainResourceUploadProgress, onMainResourceUploadMd5Progress, onMainResourceUploadError)
	}

	function attachmentUpload(createResult) {
		let isUploadFailed = false

		new Promise((resolve, reject) => {
			if (values.attachments.length < 1) {
				resolve(createResult)
			} else {
				let count = -1
				let currentAttachment = null

				const tryUploadAttachment = (attachmentResult) => {
					if (count < values.attachments.length - 1) {
						count++
						currentAttachment = values.attachments[count]
						setUploadingFile({
							...uploadingFile,
							name: `${commonLang.attachment} ${count + 1} `,
							progress: 0
						})
						uploadFileWithSignedUrl(FILE_UPLOAD_BY_TEACHER, UPLOAD_TYPE_ATTACHMENT, currentAttachment, onUploadAttachmentSuccess, onUploadAttachmentProgress, () => { }, onUploadAttachmentError)
					} else {
						const result = {
							...attachmentResult,
							material_uuid: createResult.payload.uuid
						}
						resolve(result)
					}
				}

				const onUploadAttachmentSuccess = async (res) => {
					const attachmentData = convertAttachmentRequestData(createResult.payload.uuid, currentAttachment, res)
					try {
						const attachmentResult = await createResourceAttachment(attachmentData)
						tryUploadAttachment(attachmentResult)
					} catch (e) {
						isUploadFailed = true
						// upload error - attachment create fail
						trackerWithException('CREATE ATTACHMENT - ' + e.message, false);
						tryUploadAttachment()
					}
				}

				const onUploadAttachmentProgress = (res) => {
					setUploadingFile({
						name: `${commonLang.attachment} ${count + 1} `,
						progress: parseInt(res.loaded)
					})
				}

				const onUploadAttachmentError = (err) => {
					// upload error - attachment upload fail
					// upload tracker handled by file uploader
					isUploadFailed = true
					tryUploadAttachment()
				}

				tryUploadAttachment()
			}
		}).then(
			(result) => {
				if (!result.payload || isUploadFailed) {
					showMessage(teacherLang.attachment_upload_failed)
				}
				setValues({
					...values,
					status: STATUS_UPLOAD_DONE,
					uploading: false,
				})

			}
		)
	}

	return pageView()
}

export default UploadView;
