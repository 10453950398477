import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Layout, Menu } from 'antd';

import {
  Z_INDEX_MAIN,
} from '../../constants/dom.constant';
import { ContextStore } from '../../store/ContextStore';
import { CLICK_LOGOUT } from '../../constants/tracker.constant';
import { showMessage, scrollToTop } from '../../actions/dom.action';
import { trackerWithEvent, externalLink } from '../../actions/app.action';
import { logout } from '../../actions/auth.action';
import { commonLang } from '../../languages/common.language';
import { adminLang } from '../../languages/admin.language';
import { parseErrorFromCatch } from '../../languages/error.language';
import { ReactComponent as MoreSvg } from '../../assets/chevron-down.svg';

import {  
  ROUTE_HOME,
  ROUTE_SUPERADMIN,
  ROUTE_SUPERADMIN_SCHOOLS,
  TERMS_AND_CONDITIONS,
  FAQ_URL,
} from '../../constants/app.constant';
import MainHeader from '../Common/MainHeader';
import MainFooter from '../Common/MainFooter';
import SchoolsView from './Schools';

const { Content } = Layout;

function Superadmin(props) {
  const { dispatch, auth } = React.useContext(ContextStore);

  function toHome() {
    props.history.push(ROUTE_SUPERADMIN);
  }

  async function handleLogout(){
    try{
      trackerWithEvent(CLICK_LOGOUT);
      dispatch(await logout());
      props.history.push(ROUTE_SUPERADMIN);
      return;
    }catch(e){
      showMessage(parseErrorFromCatch(e));
      return;
    }
  }

  useEffect(() => {
    if(auth.role!=="superadmin"){
      props.history.push("/"+auth.role);
    }
    const unlisten = props.history.listen((location, action) => {
      scrollToTop()
    });
    
    return () => {
      unlisten()
    }
  }, [auth.role, props.history]);

  return (
    <Layout style={{minHeight: `100vh`}}>
      <MainHeader 
        showNotification
        rightMenuStyle={{display: `flex`}}
        history={props.history} 
        pathname={props.history.location.pathname} 
        home={toHome}
      >
        <Menu.Item key={ROUTE_SUPERADMIN} onClick={toHome}>{adminLang.schools}</Menu.Item>
        <Menu.SubMenu 
          key="more" 
          title={
            <span>
              {commonLang.more}
              <MoreSvg className="top-menu-more-icon" />
            </span>
          }
        >
          <Menu.Item key="faq" onClick={() => externalLink(FAQ_URL)}>{commonLang.faq}</Menu.Item>
          <Menu.Item key="tnc" onClick={() => externalLink(TERMS_AND_CONDITIONS)}>{commonLang.tnc}</Menu.Item>
          <Menu.Item key="logout" onClick={handleLogout}>{commonLang.logout}</Menu.Item>
        </Menu.SubMenu>
      </MainHeader>
      <Layout>
        <Content style={{marginTop:`64px`, padding: `24px 32px`, zIndex: Z_INDEX_MAIN }}>
          <div className="list-container">
            <Route exact path={ROUTE_HOME} component={SchoolsView} />
            <Route exact path={ROUTE_SUPERADMIN} component={SchoolsView} />
            <Route exact path={ROUTE_SUPERADMIN_SCHOOLS} component={SchoolsView} />
          </div>
        </Content>
        <MainFooter />
      </Layout>

      <Route path={ROUTE_HOME+"*"}>
        <Redirect to={ROUTE_HOME} />
      </Route>
    </Layout>
  );
}
export default Superadmin;
