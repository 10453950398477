import React, { useEffect } from 'react';
import { Card, Input, Checkbox, Form } from 'antd';

import { teacherLogin } from '../../../actions/auth.action';
import { trackerWithPageView, trackerWithEvent, externalLink } from '../../../actions/app.action';
import {
	S_LOGIN,
	CLICK_LOGIN_BUTTON,
} from '../../../constants/tracker.constant';
import { ContextStore } from '../../../store/ContextStore';
import { parseErrorFromCatch, errorLang } from '../../../languages/error.language';
import { publicLang } from '../../../languages/public.language';
import {
	startAppLoading,
	endAppLoading,
	showMessage,
} from '../../../actions/dom.action';
import {
	ROUTE_TEACHER,
	ROUTE_TEACHER_LOGIN,
	TERMS_AND_CONDITIONS
} from '../../../constants/app.constant';
import { colors, shadows, text } from '../../styles';
import Button from '../../Common/Button';

function Login(props) {
	const { dispatch } = React.useContext(ContextStore);
	const [values, setValue] = React.useState({
		schoolCode: "",
		teacherName: "",
		password: "",
		remember: true,
	});

	const styles = {
		loginPanel: {
			borderRadius: `12px`,
			boxShadow: shadows.elevation2,
			textAlign: `center`,
			maxWidth: `420px`,
			// minWidth: `380px`,
			margin: `auto`,
		},
		input: {
			borderRadius: `6px`,
			marginTop: `16px`,
		},
		title: {
			...text.body2tight,
			color: colors.gray1,
			fontWeight: `600`,
			margin: `16px 0px`,
		},
		agreement: {
			...text.body2loose,
			fontSize: `14px`,
			lineHeight: `24px`,
			color: colors.gray3,
			marginTop: `24px`
		},
		href: {
			color: colors.ndBlue,
			fontWeight: `500`,
			cursor: `pointer`,
		}
	}

	async function handleLogin(e) {
		e.preventDefault();
		const { schoolCode, teacherName, password, remember } = values;
		if (schoolCode === "" || password === "") {
			showMessage(errorLang.public.empty_required_input)
			return;
		}

		dispatch(startAppLoading());
		try {
			dispatch(await teacherLogin(schoolCode, teacherName, password, remember));
			trackerWithEvent(CLICK_LOGIN_BUTTON, '', 1); // login status
			if(props.history.location.pathname===ROUTE_TEACHER_LOGIN){
				props.history.push(ROUTE_TEACHER);
			}
			dispatch(endAppLoading());
		} catch (e) {
			dispatch(endAppLoading());
			showMessage(parseErrorFromCatch(e));
			trackerWithEvent(CLICK_LOGIN_BUTTON, '', 0); // login status
			return;
		}
	}

	function changeValue(name, data) {
		let value = {};
		if (name === 'schoolCode')
			data = data.toUpperCase()
		value[name] = data;
		setValue({
			...values,
			...value,
		});
	}

	useEffect(() => {
		trackerWithPageView(S_LOGIN);
	}, []);

	return (
		<Card id="login-panel" bordered={false} style={styles.loginPanel} bodyStyle={{ padding: `32px 32px 24px 32px` }}>
			<div style={styles.title}>{publicLang.teacher_login}</div>
			<Form onSubmit={handleLogin}>
				<Input style={styles.input} placeholder={publicLang.school_usernname} onChange={(e) => changeValue('schoolCode', e.target.value)} value={values.schoolCode} />
				<Input style={styles.input} placeholder={publicLang.teacher_name} onChange={(e) => changeValue('teacherName', e.target.value)} />
				<Input.Password style={styles.input} placeholder={publicLang.password} onChange={(e) => changeValue('password', e.target.value)} />
				<div style={{ width: `100%`, textAlign: `left`, margin: `20px 0px` }}>
					<Checkbox defaultChecked={true} onChange={(e) => changeValue('remember', e.target.checked)}>{publicLang.remember_me}</Checkbox>
				</div>
				<Button type="primary" block htmlType="submit">{publicLang.login}</Button>
			</Form>

			<div style={styles.agreement}>
				{publicLang.formatString(
					publicLang.agreement,
					<span style={styles.href} onClick={() => externalLink(TERMS_AND_CONDITIONS)}>{publicLang.tnc}</span>
				)}
				<div style={{marginTop: `8px`}}>{publicLang.suggested_platforms}</div>
			</div>
		</Card>
	);
}

export default Login;
