import React from "react";
import { LANGUAGE_ZH_HK } from '../constants/app.constant';
import { getItemFromStorage } from "../actions/dom.action";

export const defaultApp = {
	language: getItemFromStorage('app.language') || LANGUAGE_ZH_HK,
};
export const defaultDom = {
	loading: false,
	lesson: false,
	readNotification: getItemFromStorage('dom.lastRead') ? true : false,
};
export const defaultAuth = {
	userId: getItemFromStorage('auth.userId') || null,
	token: getItemFromStorage('auth.token') || null,
	studentToken: getItemFromStorage('auth.studentToken') || null,
	userDisplayName: getItemFromStorage('auth.userDisplayName') || "",
	school: getItemFromStorage('auth.school') ? JSON.parse(getItemFromStorage('auth.school')) : {},
	role: getItemFromStorage('auth.role') || "teacher",
};
export const defaultAdmin = {
	schools: getItemFromStorage('admin.schools') ? JSON.parse(getItemFromStorage('admin.schools')) : [],
	teacher: getItemFromStorage('admin.teacher') ? JSON.parse(getItemFromStorage('admin.teacher')) : [],
	teachers: getItemFromStorage('admin.teachers') ? JSON.parse(getItemFromStorage('admin.teachers')) : [],
	student: getItemFromStorage('admin.student') ? JSON.parse(getItemFromStorage('admin.student')) : [],
	students: getItemFromStorage('admin.students') ? JSON.parse(getItemFromStorage('admin.students')) : [],
	resources: getItemFromStorage('admin.resources') ? JSON.parse(getItemFromStorage('admin.resources')) : [],
};
export const defaultTeacher = {
	report: getItemFromStorage('teacher.report') ? JSON.parse(getItemFromStorage('teacher.report')) : [],
	resources: getItemFromStorage('teacher.resources') ? JSON.parse(getItemFromStorage('teacher.resources')) : [],
	resource: getItemFromStorage('teacher.resource') ? JSON.parse(getItemFromStorage('teacher.resource')) : {},
	tags: getItemFromStorage('teacher.tags') ? JSON.parse(getItemFromStorage('teacher.tags')) : {},
	size: getItemFromStorage('teacher.size') ? parseInt(getItemFromStorage('teacher.size')) : 0,
	maxSize: getItemFromStorage('teacher.maxSize') ? parseInt(getItemFromStorage('teacher.maxSize')) : 0,
	upload: null,
	students: null,
};

export const defaultStudent = {
	resources: getItemFromStorage('student.resources') ? JSON.parse(getItemFromStorage('student.resources')) : [],
	resource: getItemFromStorage('student.resource') ? JSON.parse(getItemFromStorage('student.resource')) : {},
	homeworks: getItemFromStorage('student.homeworks') ? JSON.parse(getItemFromStorage('student.homeworks')) : {},
};

export const ContextStore = React.createContext({
	app: defaultApp,
	dom: defaultDom,
	auth: defaultAuth,
	admin: defaultAdmin,
	teacher: defaultTeacher,
	student: defaultStudent,
});
