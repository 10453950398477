// <----- actions
export const ACTION_AUTH_LOGIN = 'action_auth_login';
export const ACTION_AUTH_LOGOUT = 'action_auth_logout';
export const ACTION_TEACHER_LOGIN = 'action_teacher_login';
export const ACTION_STUDENT_LOGIN = 'action_student_login';
export const ACTION_TEACHER_LOGOUT = 'action_teacher_logout';
export const ACTION_STUDENT_LOGOUT = 'action_student_logout';

export const E_INVALID_JWT_TOKEN = 'INVALID_TOKEN';
export const DISABLED_USER_TOKEN = 'ACCOUNT_DISABLED';
