import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Layout } from 'antd';

import {
  Z_INDEX_MAIN,
} from '../../constants/dom.constant';
import { ContextStore } from '../../store/ContextStore';
import TopNav from './TopNav'

import {
  ROUTE_HOME,
  ROUTE_TEACHER,
  ROUTE_TEACHER_REPORT,
  ROUTE_TEACHER_UPLOAD,
  ROUTE_TEACHER_RESOURCES,
  ROUTE_TEACHER_RESOURCE,
  APP_PLATFORM,
  SYSTEM_PLATFORM_CENTRE,
} from '../../constants/app.constant';
import MainFooter from '../Common/MainFooter';
import TeacherSider from './TeacherSider';
import ReportView from './Report';
import ResourcesView from './Resources';
import ResourceView from './Resources/Resource';
import UploadView from './Upload';
import { scrollToTop } from '../../actions/dom.action';
import { getStudents } from '../../actions/teacher.action';

const { Content } = Layout;

function Teacher(props) {
  const { dispatch, auth, teacher } = React.useContext(ContextStore);

  async function getAllStudents() {
    dispatch(await getStudents());
  }

  useEffect(() => {
    if (auth.role !== "teacher") {
      props.history.push("/" + auth.role);
    }
    const unlisten = props.history.listen((location, action) => {
      scrollToTop()
    });
    
    if(auth.role==='teacher' 
      && !teacher.students 
      && APP_PLATFORM===SYSTEM_PLATFORM_CENTRE
      ){
      getAllStudents();
    }

    return () => {
      unlisten()
    }
  }, [auth.role, props.history]);

  return (
    <Layout style={{ minHeight: `100vh` }}>
      <TopNav history={props.history} />

      {/* Handle empty resource uuid */}
      <Route exact path={ROUTE_TEACHER_RESOURCES+"/"}>
        <Redirect to={ROUTE_TEACHER_RESOURCES} />
      </Route>

      {/* Teacher routes */}
      {
        props.history.location.pathname.includes('/resources/')
          ? <Layout style={{ marginTop: `64px`, backgroundColor: `#FAFAFA`, zIndex: Z_INDEX_MAIN }}>
            <Content>
              <div className="height-container">
                <div className="detail-container">
                  <Route exact path={ROUTE_TEACHER_RESOURCE} component={ResourceView} />
                </div>
              </div>
            </Content>
            <TeacherSider history={props.history} />
          </Layout>

          : <Layout>
            <Content style={{ marginTop: `64px`, padding: `24px 32px`, zIndex: Z_INDEX_MAIN }}>
              <div className="list-container">
                <Route exact path={ROUTE_HOME} component={ResourcesView} />
                <Route exact path={ROUTE_TEACHER} component={ResourcesView} />
                <Route exact path={ROUTE_TEACHER_REPORT} component={ReportView} />
                <Route exact path={ROUTE_TEACHER_RESOURCES} component={ResourcesView} />
              </div>
              {/* Remark: separate upload to other container since the max width is different */}
              <Route exact path={ROUTE_TEACHER_UPLOAD} component={UploadView} />
            </Content>
            <MainFooter />
          </Layout>
      }
    </Layout>
  );
}
export default Teacher;
