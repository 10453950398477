import React from 'react';
import { Row, Col, Typography } from 'antd';

import { studentLang } from '../../../../languages/student.language';
import { teacherLang } from '../../../../languages/teacher.language';
import { ReactComponent as VideoSvg } from '../../../../assets/video-color.svg';
import { ReactComponent as ClockSvg } from '../../../../assets/Clock.svg';
import { ReactComponent as CheckSvg } from '../../../../assets/circle-check.svg';
import { text, colors } from '../../../styles';
import { formatDuration } from '../../../../actions/dom.action';

const { Paragraph } = Typography;

function Item(props) {
  const styles = {
    item: {
      padding: `0 24px 32px`,
      cursor: `pointer`,
    },
    itemContainer: {
      overflow: `hidden`
    },
    icon: {
      width: 48,
      height: 48,
      padding: 4,
      marginRight: 16,
      verticalAlign: 'top',
      background: "rgba(255, 79, 84, 0.08)",
      borderRadius: 6,
    },
    title: {
      ...text.body1bold,
      color: colors.gray1,
      marginBottom: 4,
    },
    description: {
      ...text.body2tight,
      color: colors.gray2,
      paddingRight: 24,
      marginBottom: 0,
    },
    caption: {
      ...text.caption,
      color: colors.gray3,
    },
    duration: {
      ...text.body2bold,
      display: `flex`,
      alignItems: "center",
      color: colors.gray3,
    },
    complete: {
      ...text.body2bold,
      display: `flex`,
      color: colors.success2,
    },
    subject: {
      paddingRight: 16,
    },
  }

  const { unit, viewResource } = props
  return (
    // hide duration related ui 13 4 4 3
    <Row key={unit.uuid} style={styles.item} className="resource-list-item">
      <Col xs={24} sm={15} style={{ display: `flex`, paddingRight: 16 }} onClick={() => viewResource(unit)}>
        <div>
          <VideoSvg style={styles.icon} />
        </div>
        <div style={styles.itemContainer}>
          {/* <div style={styles.title} className="resource-list-item-title">{unit.title}</div> */}
          {/* <Paragraph style={styles.description} ellipsis>{decode(unit.description.replace(/(<([^>]+)>)/ig, ''))}</Paragraph> */}
          <Paragraph style={styles.title} className="resource-list-item-title" ellipsis>{unit.title}</Paragraph>
          <div style={styles.caption}>{teacherLang.formatString(teacherLang.upload_by, unit.created_by)}</div>
          {/* {unit.subject && <Tag>{unit.subject}</Tag>} */}
        </div>
      </Col>

      <Col xs={0} sm={6}>
        <Paragraph ellipsis style={styles.subject}>{unit.subject}</Paragraph>
      </Col>

      {/* <Col xs={0} sm={4}>
        {
          unit.duration &&
          <div style={styles.duration}>
            <ClockSvg style={{ marginRight: `4px` , width:14, height:14}} />
            {formatDuration(unit.duration)}
          </div>
        }
      </Col> */}

      <Col xs={0} sm={3}> 
        {
          unit.is_read &&
          <div style={styles.complete}>
            <CheckSvg style={{ marginRight: `4px` }} />
            {studentLang.complete}
          </div>
        }
      </Col>
    </Row>
  );
}
export default Item;