import axios from 'axios';
import {
	PATH_API_BASE_URL,
	PATH_APP_URL,
} from '../constants/app.constant';
import {
	APPLICATION_JSON,
} from '../constants/common.constant';
import { getItemFromStorage } from '../actions/dom.action';

let publicApi = null;
export const getPublicApi = (useCache = true) => {
	return publicApi && useCache
		? publicApi
		: publicApi = axios.create({
			baseURL: PATH_API_BASE_URL,
			headers: {
				get: {
					"Content-Type": APPLICATION_JSON,
				},
				post: {
					"Content-Type": APPLICATION_JSON,
				},
			},
		});
}

let authorizedApi = null;
export const getApi = (useCache = true) => {
	return authorizedApi && useCache
		? authorizedApi
		: authorizedApi = axios.create({
			baseURL: PATH_API_BASE_URL,
			headers: {
				get: {
					"Content-Type": APPLICATION_JSON,
					'Authorization': 'Bearer ' + getItemFromStorage('auth.token'),
				},
				post: {
					"Content-Type": APPLICATION_JSON,
					'Authorization': 'Bearer ' + getItemFromStorage('auth.token'),
				},
				put: {
					"Content-Type": APPLICATION_JSON,
					'Authorization': 'Bearer ' + getItemFromStorage('auth.token'),
				},
				delete: {
					"Content-Type": APPLICATION_JSON,
					'Authorization': 'Bearer ' + getItemFromStorage('auth.token'),
				},
			},
		});
}


let studentApi = null;
export const getStudentApi = (useCache = true) => {
	return studentApi && useCache
		? studentApi
		: studentApi = axios.create({
			baseURL: PATH_API_BASE_URL,
			headers: {
				get: {
					"Content-Type": APPLICATION_JSON,
					'Authorization': 'Bearer ' + getItemFromStorage('auth.studentToken'),
				},
				post: {
					"Content-Type": APPLICATION_JSON,
					'Authorization': 'Bearer ' + getItemFromStorage('auth.studentToken'),
				},
				put: {
					"Content-Type": APPLICATION_JSON,
					'Authorization': 'Bearer ' + getItemFromStorage('auth.studentToken'),
				},
				delete: {
					"Content-Type": APPLICATION_JSON,
					'Authorization': 'Bearer ' + getItemFromStorage('auth.studentToken'),
				},
			},
		});
}


let downloadApi = null;
export const getDownloadApi = (useCache = true) => {
	return downloadApi && useCache
		? downloadApi
		: downloadApi = axios.create({
			responseType: 'blob'
		});
}

// <----- tmp
let localApi = null;
export const getLocalApi = (useCache = true) => {
	return localApi && useCache
		? localApi
		: localApi = axios.create({
			baseURL: PATH_APP_URL,
			headers: {
				get: {
					"Content-Type": APPLICATION_JSON,
				},
				post: {
					"Content-Type": APPLICATION_JSON,
				},
			},
		});
}
