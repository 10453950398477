import React from 'react';
import { Icon } from 'antd';

import { APP_PLATFORM, SYSTEM_PLATFORM_CENTRE } from '../../../../constants/app.constant';
import { teacherLang } from '../../../../languages/teacher.language';
import { commonLang } from '../../../../languages/common.language';
import { text, colors,shadows } from '../../../styles';
import { ReactComponent as ShareSvg } from '../../../../assets/share.svg';
import { ReactComponent as EditSvg } from '../../../../assets/edit.svg';


function ViewMode(props) {
	const styles = {
		content: {
			backgroundColor: `#fff`,
			padding: `32px 48px`,
			boxShadow: shadows.elevation5,
			borderBottomLeftRadius: 6,
			borderBottomRightRadius: 6,
		},
		title: {
			...text.h6,
			color: colors.gray1,
			paddingBottom: 16,
		},
		description: {
			...text.body1,
			color: colors.gray1,
		},
		rightContainer: {
			float: `right`,
			display: `flex`,
		},
		shareContainer: {
			marginLeft: `20px`,
			cursor: `pointer`,
			display: `flex`,
		},
		shareIcon: {
			color: colors.gray3,
			marginRight: 13,
			width: 20,
			alignItems: `center`,
			display: `flex`,
		},
		shareLabel: {
			...text.body2bold,
			color: colors.gray2,
			alignItems: `center`,
			display: `flex`,
		}
	}

	return (
		<div style={styles.content}>
			<div style={styles.title}>
				{props.title}
				<div style={styles.rightContainer}>
					<div style={styles.shareContainer} onClick={() => props.changeValue('mode', 'edit')}>
						<EditSvg style={styles.shareIcon} />
						<span style={styles.shareLabel}>{commonLang.edit}</span>
					</div>
					{
						APP_PLATFORM === SYSTEM_PLATFORM_CENTRE &&
						<div style={styles.shareContainer} onClick={() => props.changeValue('mode', 'assign')}>
							<Icon style={{ color: colors.gray3, marginRight: 8 }} type="team" />
							<span style={styles.shareLabel}>{teacherLang.assignee}</span>
						</div>
					}
					<div style={styles.shareContainer} onClick={() => props.shareResource()}>
						<ShareSvg style={styles.shareIcon} />
						<span style={styles.shareLabel}>{teacherLang.share}</span>
					</div>
				</div>
			</div>
			<div style={styles.description} dangerouslySetInnerHTML={{ __html: `${props.description}` }} />
		</div>
	);
}
export default ViewMode;

