import LocalizedStrings from 'react-localization';
// TODO: group string and sync naming style
export const teacherLang = new LocalizedStrings({
	'zh-hk': {

		// common in teacher
		"upload": "上載影片",
		"resource": "資源",
		"select_attachment": "選擇附件",
		"files": "檔案",
		"class": "可瀏覽班別",
		"remarks": "備註",
		"remarks_hints": "（只有老師可見）",
		"active": "公開",
		"inactive": "不公開",
		"learing_date": "學習日期",
		"subject": "科目",
		"select_subject": "選擇科目",
		"used_storage": "已使用 {0}（共 {1}）",
		"delete_resource_title": "你確定要刪除此資源嗎？",
		"delete_resource_content": "資源一經刪除將無法還原。",
		"upload_by": "由{0}上載",

		// upload
		"report": "Teacher - report",
		"upload_hint": "拖放影片到此處",
		"upload_size_hint": "或點擊上載最大 {0} MB 的影片檔",
		"select_video": "選擇影片",
		"file_size_too_large": "檔案太大，請上傳不大於 {0} MB 的檔案",
		"file_type_invalid": "檔案格式錯誤，請選擇影片檔案格式（mp4, webm, ogg）",
		"title": "標題",
		"desc": "描述",
		"expiry_hint": "資源將於該日的30天後到期",
		"classHint": "（輸入班別的英文大寫，例如 1A）",
		"upload_btn": "上載",
		"upload_progress": "{0}已上載 {1}%",
		"upload_success_title": "成功上載影片 :)",
		"upload_success_desc": "已登錄於{0}的學習日誌",
		"upload_success_hint": "請記得於發佈日前將開發狀態設為「公開」",
		"upload_success_back": "返回學習日誌",
		"attachment_upload_failed": "部份附件上傳失敗，請重新上傳。",
		"go_to": "前往",

		// resource list
		"status": "狀態",
		"expired": "已過期",
		"read_no": "已瀏覽",
		"set_active": "設為公開",
		"set_inactive": "設為非公開",

		//resource
		"viewed_student": "已瀏覽學生（{0}）",
		"student": "學生",
		"attachment_count": "附件（{0}）",
		"student_table_empty": "暫時未有紀錄",
		"read_at": "瀏覽",
		"last_read_at": "最後瀏覽：{0}",
		"homework": "功課",
		"homework_student": "已交功課學生",
		"upload_by_date": "{0}由{1}上載",

		"active_status": "公開狀態",
		"upload_date": "上載日期",
		"expiry_date": "有效期限",
		"expiry_at": "將於 {0} 天後過期",
		"file_size": "檔案大小",
		"students": "{0} 位學生",
		"view_student_btn": "查看",
		"delete_resource": "刪除資源",
		"student_homework_btn": "完成",
		"delete_attachment_hint": "你確定要刪除附件嗎？",
		"empty_attachment": "沒有附件",
		"share": "分享",



		// share modal
		"share_resource_title": "跨境「{0}」，停課不停學",
		"copied_to_clipboard": "已複製到剪貼簿！",
		"share_resource": "分享資源",


		// <----- learning center
		"assignee": "可瀏覽學生",
		"not_assigned": "未加入",
		"assigned": "已加入",
		"search_student": "搜尋學生",
		"student_number": "位學生",
		"no_student": "沒有學生",

	},
});

export default { teacherLang };
